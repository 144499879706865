import React from "react";
import Chart from "./Chart";
import Header from "./Header";

const Chart2 = () => {
  return (
    <div className="bg-[#fff] gap-5 rounded-xl p-5 drop-shadow">
      <Header />

      {/* line chart implementation */}
      <Chart />
    </div>
  );
};

export default Chart2;
