import React from "react";

const Header = () => {
  return (
    <div className="flex justify-between w-full">
      <div>
        <h1 className="text-[20px] font-bold">🔥 Trending Items</h1>
        <p className="text-[12px] text-medium-grey font-gotham-light">
          Lorem ipsum dolor sit amet, consectetur
        </p>
      </div>

      <div className="flex gap-3 items-center text-sm">
        <select className="bg-white px-4 py-2 rounded">
          <option>Semanal</option>
          <option>Semanal</option>
          <option>Semanal</option>
          <option>Semanal</option>
        </select>
      </div>
    </div>
  );
};

export default Header;
