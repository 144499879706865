import React from "react";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const TablePagination = ({ rowsPerPage, page, handleChangePage, data }) => {
  return (
    <div className="flex justify-end pb-3 pr-3">
      <Stack spacing={2} mt={2}>
        <Pagination
          count={Math.ceil(data?.length / rowsPerPage)}
          shape="rounded"
          page={page + 1}
          onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
        />
      </Stack>
    </div>
  );
};

export default TablePagination;
