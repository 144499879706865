import React from "react";
import Header from "./Header";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { chartConfig, data2 } from "../../../../assets/data/line_chart_data";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const OrderChart = () => {
  return (
    <div className="bg-[#fff] rounded-xl p-5 drop-shadow">
      <Header />

      <div className="flex gap-10 my-5">
        <div>
          <div className="flex items-end gap-3">
            <img
              className="mb-1"
              src={process.env.PUBLIC_URL + "/svgs/chart2-ingresos.svg"}
              alt="chart"
            />

            <p className="font-bold text-2xl">257k</p>
          </div>
          <p className="text-[10px] text-medium-grey font-gotham-light">
            Total de ventas
          </p>
        </div>

        <div>
          <div className="flex items-end gap-3">
            <img
              className="mb-1"
              src={process.env.PUBLIC_URL + "/svgs/chart2-ingresos.svg"}
              alt="chart"
            />

            <p className="font-bold text-2xl">1,245</p>
          </div>
          <p className="text-[10px] text-medium-grey font-gotham-light">
            Estimado por día
          </p>
        </div>
      </div>

      <Line options={chartConfig} data={data2} />
    </div>
  );
};

export default OrderChart;
