import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import ReviewCarousel from "../../components/admin/comments/ReviewCarousel";
import ReviewList from "../../components/admin/comments/ReviewList";
import axios from "axios";
import { API_URL } from "../../api_url";
import { useSelector } from "react-redux";

const Comments = () => {
  // rename the states according to real values
  const [selected, setSelected] = useState(null);
  const token = useSelector((state) => state.user.token)

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  async function getComments() {
    try {
      const res = await axios.get(`${API_URL}/admin/comment`, { headers: { Authorization: "Bearer " + token } })
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getComments()
  }, [])

  return (
    <div className="font-poppins">
      <div className="flex justify-between mb-5">
        <div>
          <h1 className="text-xl font-bold">Comentarios</h1>
          <p className="my-3">
            <Link to="/orders" className="text-basic-red">
              Dashboard /
            </Link>{" "}
            <span className="text-medium-grey font-gotham-light">
              comentarios de clientes
            </span>
          </p>
        </div>

        <div className="flex gap-5">
          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2 text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/calendar.svg"}
                  alt="check"
                />
                Filtro por periodo
              </div>
            </InputLabel>
            <Select
              value={selected}
              onChange={handleChange}
              className="w-56 bg-light-blue"
            >
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
              <MenuItem value={2}>Option 3</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>
        </div>
      </div>

      <ReviewCarousel />

      <div className="bg-[white] drop-shadow mx-auto w-[1000px] mt-5 p-5 rounded-xl">
        <div className="flex justify-between mb-5">
          <div>
            <h1 className="text-xl font-bold">Others review</h1>
            <p className="my-3 text-medium-grey font-gotham-light">
              Here is customer review about your restaurant
            </p>
          </div>

          <div className="flex gap-5">
            <FormControl>
              <InputLabel>
                <div className="flex items-center gap-2 text-[#000]">
                  Latest
                </div>
              </InputLabel>
              <Select
                value={selected}
                onChange={handleChange}
                className="w-56 bg-light-blue"
              >
                <MenuItem value={0}>Option 1</MenuItem>
                <MenuItem value={1}>Option 2</MenuItem>
                <MenuItem value={2}>Option 3</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </FormControl>
          </div>
        </div>

        <ReviewList />
      </div>
    </div>
  );
};

export default Comments;
