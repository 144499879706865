import React from "react";
import { Link, useParams } from "react-router-dom";

import BranchInfo from "../../components/admin/branch/BranchInfo";
import OrderSteps from "../../components/admin/branch/OrderSteps";
import ArticlesList from "../../components/admin/branch/ArticlesList";
import Tracking from "../../components/admin/branch/Tracking";
import TotalOrders from "../../components/admin/branch/TotalOrders";
import OrderChart from "../../components/admin/branch/OrderChart";

const Branch = () => {

  const params = useParams()

  return (
    <div className="font-gotham">
      <div className="flex justify-between mb-5">
        <div>
          <h1 className="text-xl font-bold">Sucursales ID #{params.id}</h1>
          <p className="my-3">
            <Link to="/orders" className="text-basic-red">
              Sucursal /
            </Link>{" "}
            <span className="text-medium-grey font-gotham-light">
              Detalles del pedido
            </span>
          </p>
        </div>
      </div>

      <div className="flex gap-5 w-full">
        <div className="">
          <BranchInfo />
          <OrderSteps />
        </div>

        <div className="flex-1">
          <ArticlesList />
          <Tracking />
        </div>
      </div>

      <div className="my-5 grid grid-cols-2 gap-5">
        <TotalOrders />
        <OrderChart />
      </div>
    </div>
  );
};

export default Branch;
