import React, { useState } from "react";

const Header = () => {
  return (
    <div className="flex justify-between w-full">
      <div>
        <h1 className="text-[20px] font-bold">Total de pedidos</h1>
        <p className="text-[12px] text-medium-grey">
          Lorem ipsum dolor sit amet, consectetur
        </p>
      </div>

      <Menu />
    </div>
  );
};

export default Header;

const Menu = () => {
  const [selected, setSelected] = useState(2);

  const options = ["Mensual", "Semanal", "Hoy"];

  const handleSelect = (index) => {
    setSelected(index);

    // perform the actions of option selection here
  };

  return (
    <div className="bg-white grid grid-cols-3 gap-2 p-2 rounded">
      {options.map((x, i) => (
        <button
          key={i}
          onClick={() => handleSelect(i)}
          className={`${
            selected === i ? "bg-[#fff]" : "text-dark-grey"
          } p-2 text-[14px] rounded`}
        >
          {x}
        </button>
      ))}
    </div>
  );
};
