import React from "react";

import Chart from "./Chart";
import Header from "./Header";

const Chart3 = () => {
  return (
    <div className="bg-[#fff] rounded-xl p-5 drop-shadow w-2/3">
      <Header />

      {/* bar chart implementation */}
      <Chart />
    </div>
  );
};

export default Chart3;
