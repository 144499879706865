import React from "react";

import KPIs from "./KPIs";
import Chart from "./Chart";
import Header from "./Header";

const TotalOrders = () => {
  return (
    <div className="bg-[#fff] rounded-xl p-5 drop-shadow">
      <Header />

      {/* this is the list of kpis that shows different count (again you can rename the component a/c to its purpose) */}
      <KPIs />

      {/* donut chart and progress bars implementation */}
      <Chart />
    </div>
  );
};

export default TotalOrders;
