import React from "react";
import { FiPhone } from "react-icons/fi";

const Tracking = () => {
  return (
    <div className="w-full p-5 mt-5 bg-[#fff] rounded-xl drop-shadow">
      <img
        src={process.env.PUBLIC_URL + "/pngs/branch-map.png"}
        alt="tracking"
        className="my-5"
      />

      <p>Enviado por</p>

      <div className="mt-5 flex">
        <div className="flex-1 flex items-center gap-5">
          <img
            alt="client"
            src="https://randomuser.me/api/portraits/men/72.jpg"
            className="w-10 h-10 rounded-full object-cover"
          />
          <div>
            <p>Juan Miguel</p>
            <p className="text-xs font-gotham-light">ID 412455</p>
          </div>
        </div>

        <div className="flex-1 flex items-center gap-5 mr-10">
          <button className="p-3 text-2xl hover:drop-shadow-lg bg-basic-red text-white rounded-full">
            <FiPhone />
          </button>
          <div>
            <p className="font-gotham-light text-sm">Telefono</p>
            <p className="text-sm">809 000 0000</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tracking;
