import React, { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const CancelOrder = ({ handleClose }) => {
  const options = [
    "No llego a tiempo",
    "Se agoto el producto",
    "No toman las llamadas",
    "No encontre el lugar",
  ];

  const [selected, setSelected] = useState(0);

  return (
    <div className="fixed h-screen w-screen bg-black/30 top-0 left-0 z-20 flex items-center justify-center">
      <div className="relative w-[500px] max-h-[70vh]  bg-[white] relative rounded-lg p-5">
        <img
          className="absolute top-0 left-0 z-0"
          src={process.env.PUBLIC_URL + "/svgs/cancel-popup.svg"}
          alt="backgound"
        />
        <button onClick={handleClose} className="absolute top-3 right-3">
          <MdOutlineCancel className="text-2xl text-basic-red" />
        </button>
        <div className="font-gotham-medium relative z-10">
          <h1 className="text-basic-red flex items-center gap-2 font-bold text-xl">
            <FaRegTrashAlt /> Cancelar pedido
          </h1>

          <p className="my-5 text-basic-red text-lg font-bold">Motivo</p>

          <div className="ml-5">
            {options.map((x, i) => (
              <div
                key={i}
                className="flex justify-between items-center mb-2 font-gotham-light"
              >
                <label htmlFor={i}>{x}</label>
                <input
                  id={i}
                  type="radio"
                  checked={selected === i}
                  onChange={(e) => setSelected(i)}
                />
              </div>
            ))}
          </div>

          <textarea
            placeholder="Escribe la razon"
            className="font-gotham-light my-5 w-full h-32 rounded p-3 resize-none border-2 border-white"
          ></textarea>

          <Link to="/order-cancelled">
            <button className="bg-basic-red w-full text-white py-3 rounded-full">
              Cancelar Pedido
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CancelOrder;
