import React from "react";

const ClientInfo = () => {
  return (
    <div className="w-[300px] bg-[#fff] rounded-xl drop-shadow flex flex-col gap-3 items-center py-5">
      <img
        alt="client"
        src="https://randomuser.me/api/portraits/men/75.jpg"
        className="w-44 h-44 rounded-full object-cover"
      />
      <h1 className="text-dark-grey text-xl">James Witwitcky</h1>
      <p className="font-gotham-light text-basic-red">Cliente</p>

      <div className="my-3 flex w-full bg-medium-grey text-[#fff] items-center rounded-2xl p-3">
        <img
          alt="bicycle"
          src={process.env.PUBLIC_URL + "/svgs/bicycle.svg"}
          className="mb-[-20px]"
        />

        <p>Santo Domingo Norte, Calle #56, esquina...</p>
      </div>

      <p className="flex text-sm text-basic-red">
        <img alt="timer" src={process.env.PUBLIC_URL + "/svgs/timer.svg"} />
        Miercoles, 16 Septiembre - 6:28 PM
      </p>
    </div>
  );
};

export default ClientInfo;
