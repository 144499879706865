import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// admin routes
import Login from "../pages/admin/Login";
import ForgotPassword from "../pages/admin/ForgotPassword";
import Dashboard from "../pages/admin/Dashboard";
import Orders from "../pages/admin/Orders";
import Order from "../pages/admin/Order";
import Clients from "../pages/admin/Clients";
import Analytics from "../pages/admin/Analytics";
import Comments from "../pages/admin/Comments";
import Branches from "../pages/admin/Branches";
import Branch from "../pages/admin/Branch";
import OrderCancelled from "../pages/admin/OrderCancelled";
import AddBanner from "../pages/admin/AddBanner";
import Banners from "../pages/admin/Banners";
import AddProduct from "../pages/admin/AddProduct";
import Products from "../pages/admin/Products";
import Category from "../pages/admin/Category";
import Categories from "../pages/admin/Category/Categories";

// vendor routes
import VendorDashboard from "../pages/vendor/Dashboard";
import VendorOrders from "../pages/vendor/Orders";
import VendorDealers from "../pages/vendor/Dealers";

// client routes
import ClientDashboard from "../pages/client/Dashboard";
import ClientBranches from "../pages/client/Branches";
import ClientBranch from "../pages/client/Branch";
import ClientDealers from "../pages/client/Dealers";
import VendorOrder from "../pages/vendor/Order";
import ProductHistory from "../pages/admin/ProductHistory";
import Product from "../pages/admin/Product";
import EditProduct from "../pages/admin/EditProducts";
import AddDistributor from "../pages/admin/AddDistributor";
import Distributors from "../pages/admin/Distributors";
import Distributor from "../pages/admin/Distributor";
import DeliveryPersons from "../pages/admin/Riders";
import AddDeliveryPerson from "../pages/admin/AddDeliveryPerson";
import EditDeliveryPerson from "../pages/admin/EditDeliveryPerson";
import { useSelector } from "react-redux";
import ViewBanner from "../pages/admin/banner";
import AdminProfile from "../pages/admin/adminProfile";
import OrderHistory from "../pages/admin/OrderHistory";
import VendorRiders from "../pages/vendor/Dealers";
import AddBranch from "../pages/vendor/add-branch";
import Signup from "../pages/signup";
import AdminOrder from "../pages/admin/Order";
import AddRider from "../pages/admin/Rider/AddRider";

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
    </Routes>
  );
};

export const AdminRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/order/:id" element={<AdminOrder />} />
      <Route path="/order-history/:id" element={<OrderHistory />} />
      <Route path="/order-cancelled" element={<OrderCancelled />} />
      <Route path="/clients" element={<Clients />} />
      <Route path="/analytics" element={<Analytics />} />
      <Route path="/comments" element={<Comments />} />
      <Route path="/branches" element={<Branches />} />
      <Route path="/branch/:id" element={<Branch />} />
      <Route path="/banners" element={<Banners />} />
      <Route path="/banner/:id" element={<ViewBanner />} />
      <Route path="/banner/add" element={<AddBanner />} />
      <Route path="/products" element={<Products />} />
      <Route path="/product/:id" element={<Product />} />
      <Route path="/product/add" element={<AddProduct />} />
      <Route path="/product-edit/:id" element={<EditProduct />} />
      <Route path="/product-history" element={<ProductHistory />} />
      <Route path="/distributors" element={<Distributors />} />
      <Route path="/add-distributor" element={<AddDistributor />} />
      <Route path="/view-distributor/:id" element={<Distributor />} />
      <Route path="/dealers" element={<DeliveryPersons />} />
      <Route path="/add-delivery-person" element={<AddDeliveryPerson />} />
      <Route
        path="/edit-delivery-person/:id"
        element={<EditDeliveryPerson />}
      />
      <Route path="/admin-profile" element={<AdminProfile />} />
      <Route path="/add-branch" element={<AddBranch />} />
      <Route path="/category" element={<Category />} />
      <Route path="/categories" element={<Categories />} />
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/add-rider" element={<AddRider />} />
    </Routes>
  );
};

export const VendorRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<VendorOrders />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/order/:id" element={<VendorOrder />} />
      <Route path="/order-history/:id" element={<OrderHistory />} />
      <Route path="/order-cancelled" element={<OrderCancelled />} />
      <Route path="/dealers" element={<VendorRiders />} />
      <Route path="/product/:id" element={<Product />} />
      {/* <Route path="/orders" element={<VendorDashboard />} /> */}
      <Route path="/add-branch" element={<AddBranch />} />
      {/* <Route path="/banner/add" element={<AddBanner />} /> */}
      {/* <Route path="/branches" element={<Branches />} /> */}
      {/* <Route path="/branch/:id" element={<Branch />} /> */}
      {/* <Route path="/product/add" element={<AddProduct />} /> */}
      <Route path="/products" element={<Products />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const ClientRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ClientDashboard />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/order/:id" element={<Order />} />
      <Route path="/order-history/:id" element={<OrderHistory />} />
      <Route path="/order-cancelled" element={<OrderCancelled />} />
      <Route path="/dealers" element={<ClientDealers />} />
      <Route path="/branches" element={<ClientBranches />} />
      <Route path="/branch/:id" element={<ClientBranch />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
