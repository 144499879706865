import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHeader from "../../../components/admin/layout/table/TableHead";

import moment from "moment";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";

import { Link, useNavigate } from "react-router-dom";
import { getComparator, stableSort } from "../../../utils/tableSort";
import TablePagination from "../../../components/admin/layout/table/TablePagination";
import axios from "axios";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { API_URL } from "../../../api_url";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineCancel } from "react-icons/md";
import {
  setCategoryToEdit,
  deleteFromCategoriesById,
} from "../../../store/category";

const headCells = [
  {
    id: "id",
    label: "Producto ID",
  },
  {
    id: "date",
    label: "Fetcha creado",
  },
  {
    id: "name",
    label: "Nombre del producto",
  },
  {
    id: "image",
    label: "Imagen",
  },
  {
    id: "action",
    label: "",
  },
];

const DeleteProductModal = ({ handleClose, handleDeleteProduct }) => {
  return (
    <div className="fixed h-screen w-screen bg-black/30 top-0 left-0 z-20 flex pt-52 justify-center">
      <div className="relative w-[500px] h-[200px] bg-[white] text-center rounded-xl p-10">
        <button onClick={handleClose} className="absolute top-3 right-3">
          <MdOutlineCancel className="text-2xl text-basic-red" />
        </button>

        <p>
          <span>Desea eliminar permanentemente el banner</span>
          <br />
          <span className="text-basic-red font-semibold">
            NOMBRE DEL CATEGORY
          </span>
          ?
        </p>

        <div className="mt-10 flex gap-5 justify-center">
          <button
            onClick={handleClose}
            className="drop-shadow-lg px-8 py-2 bg-[white] rounded"
          >
            Cancelar
          </button>
          <button
            onClick={handleDeleteProduct}
            className="drop-shadow-lg px-8 py-2 bg-basic-red rounded text-[white]"
          >
            Eliminar
          </button>
        </div>
      </div>
    </div>
  );
};

export default function CategoryTable({ categories, setLoading = () => {} }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [visibleRows, setVisibleRows] = useState([]);
  const [productId, setProductId] = useState(null);
  const [deleteProduct, setDeleteProduct] = useState(false);
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();

  const rowsPerPage = 5;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categories?.length) : 0;

  useEffect(() => {
    const sortedRows = stableSort(categories, getComparator(order, orderBy));
    const slicedRows = sortedRows?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    setVisibleRows(slicedRows);
  }, [categories, order, orderBy, page, rowsPerPage]);

  const handleDeleteProduct = async () => {
    try {
      const res = await axios.delete(
        `${API_URL}/admin/category/delete/${productId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 200) {
        setLoading(false);
        setDeleteProduct(false);
        enqueueSnackbar("Category Deleted successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        dispatch(deleteFromCategoriesById(productId));
      }
    } catch (error) {
      setLoading(false);
      setDeleteProduct(false);
      console.log(error);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <SnackbarProvider />
      {deleteProduct && (
        <DeleteProductModal
          handleClose={() => setDeleteProduct(false)}
          handleDeleteProduct={handleDeleteProduct}
        />
      )}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer sx={{ borderRadius: 2 }} className="font-gotham-light">
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHeader
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={categories?.length}
            />
            <TableBody>
              {visibleRows?.map((row, i) => {
                return (
                  <TableRow
                    key={i}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      cursor: "pointer",
                    }}
                    className="hover:bg-basic-red hover:font-semibold"
                  >
                    <TableCell scope="row">{row.id}</TableCell>
                    <TableCell className="underline">
                      <Link to={"/order/" + row.id}>
                        {moment(row.date).format("DD MMMM YYYY, h:mm A")}
                      </Link>
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      <div className="flex items-center justify-center">
                        <img width={70} src={row.image_url} alt="product" />
                      </div>
                    </TableCell>
                    <TableCell>
                      <LongMenu
                        row={row}
                        setProductId={setProductId}
                        setDeleteProduct={setDeleteProduct}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          data={categories}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
        />
      </Paper>
    </Box>
  );
}

const ITEM_HEIGHT = 48;

function LongMenu({ row, setProductId, setDeleteProduct }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEditCategory = () => {
    dispatch(setCategoryToEdit(row));
    navigate("/category");
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={handleEditCategory}>
          <button className="font-gotham-medium text-sm">Editar</button>
        </MenuItem>
        <MenuItem
          onClick={() => {
            console.log(row);
            setProductId(row.id);
            setDeleteProduct(true);
          }}
        >
          <p className="font-gotham-medium text-sm">Eliminar</p>
        </MenuItem>
      </Menu>
    </div>
  );
}
