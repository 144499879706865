import React from "react";
import DonutChart from "react-donut-chart";
import { data } from "../../../../assets/data/donut_chart_data";

const Chart = () => {
  return (
    <div className="flex justify-between gap-5 items-center my-5">
      <DonutChart
        height={100}
        width={100}
        legend={false}
        strokeColor="#fff"
        data={data.data}
        colors={data.colors}
        outerRadius={1}
        innerRadius={1 / 2}
      />

      <div className="flex-1">
        {data.data.map((x, i) => (
          <ProgressBar key={i} data={x} index={i} colors={data.colors} />
        ))}
      </div>
    </div>
  );
};

export default Chart;

const ProgressBar = ({ data: { label, value }, index, colors }) => {
  return (
    <div className="flex gap-5 items-center my-2">
      <p className="w-28">{label}</p>
      <div className="flex-1 bg-[#ccc] h-2 rounded-full">
        <div
          style={{
            background: colors[index], // shows the same color as in donut
            width: `${value}%`, //the inner div show the progress by width
          }}
          className={`h-full w-full rounded-full`}
        ></div>
      </div>
      <p className="w-10 text-end">{value}</p>
    </div>
  );
};
