import React, { Fragment } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { chartConfig, data } from "../../../../assets/data/line_chart_data";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Chart = () => {
  return (
    <Fragment>
      <div className="flex justify-between items-end mb-5">
        <div className="flex gap-5">
          <div className="flex items-center gap-3 text-sm">
            <div className="h-3 w-3 rounded bg-basic-red" />
            <p>Ingresos</p>
          </div>

          <div className="flex items-center gap-3 text-sm">
            <div className="h-3 w-3 rounded bg-medium-grey" />
            <p>Gastos</p>
          </div>
        </div>

        <div className="flex gap-5">
          <div className="flex items-end gap-5">
            <img
              className="mb-1"
              src={process.env.PUBLIC_URL + "/svgs/chart2-ingresos.svg"}
              alt="chart"
            />

            <div>
              <p className="text-[10px] text-medium-grey">Ingresos</p>
              <p className="font-bold">$126,000</p>
            </div>
          </div>

          <div className="flex items-end gap-5">
            <img
              className="mb-1"
              src={process.env.PUBLIC_URL + "/svgs/chart2-gastos.svg"}
              alt="chart"
            />

            <div>
              <p className="text-[10px] text-medium-grey">Gastos</p>
              <p className="font-bold">$126,000</p>
            </div>
          </div>
        </div>
      </div>

      <Line options={chartConfig} data={data} />
    </Fragment>
  );
};

export default Chart;
