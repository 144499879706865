export const data = [
  {
    id: 1,
    count: 1,
    name: "Nombre de Sucursal",
    location: "456 Oak Avenue, Town",
    price: "83.44",
    status: "83.44",
  },
  {
    id: 2,
    count: 1,
    name: "Nombre de Sucursal",
    location: "123 Main Street, City",
    price: "14.97",
    status: "14.97",
  },
  {
    id: 3,
    count: 1,
    name: "Nombre de Sucursal",
    location: "456 Oak Avenue, Town",
    price: "70.84",
    status: "70.84",
  },
  {
    id: 4,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "12.99",
    status: "12.99",
  },
  {
    id: 5,
    count: 1,
    name: "Nombre de Sucursal",
    location: "123 Main Street, City",
    price: "26.96",
    status: "26.96",
  },
  {
    id: 6,
    count: 1,
    name: "Nombre de Sucursal",
    location: "123 Main Street, City",
    price: "54.96",
    status: "54.96",
  },
  {
    id: 7,
    count: 1,
    name: "Nombre de Sucursal",
    location: "456 Oak Avenue, Town",
    price: "24.12",
    status: "24.12",
  },
  {
    id: 8,
    count: 1,
    name: "Nombre de Sucursal",
    location: "123 Main Street, City",
    price: "25.23",
    status: "25.23",
  },
  {
    id: 9,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "72.12",
    status: "72.12",
  },
  {
    id: 10,
    count: 1,
    name: "Nombre de Sucursal",
    location: "456 Oak Avenue, Town",
    price: "19.87",
    status: "19.87",
  },
  {
    id: 11,
    count: 1,
    name: "Nombre de Sucursal",
    location: "456 Oak Avenue, Town",
    price: "86.35",
    status: "86.35",
  },
  {
    id: 12,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "56.86",
    status: "56.86",
  },
  {
    id: 13,
    count: 1,
    name: "Nombre de Sucursal",
    location: "456 Oak Avenue, Town",
    price: "99.99",
    status: "99.99",
  },
  {
    id: 14,
    count: 1,
    name: "Nombre de Sucursal",
    location: "456 Oak Avenue, Town",
    price: "74.39",
    status: "74.39",
  },
  {
    id: 15,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "83.78",
    status: "83.78",
  },
  {
    id: 16,
    count: 1,
    name: "Nombre de Sucursal",
    location: "123 Main Street, City",
    price: "79.35",
    status: "79.35",
  },
  {
    id: 17,
    count: 1,
    name: "Nombre de Sucursal",
    location: "456 Oak Avenue, Town",
    price: "90.36",
    status: "90.36",
  },
  {
    id: 18,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "33.14",
    status: "33.14",
  },
  {
    id: 19,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "23.03",
    status: "23.03",
  },
  {
    id: 20,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "12.00",
    status: "12.00",
  },
  {
    id: 21,
    count: 1,
    name: "Nombre de Sucursal",
    location: "123 Main Street, City",
    price: "94.25",
    status: "94.25",
  },
  {
    id: 22,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "38.17",
    status: "38.17",
  },
  {
    id: 23,
    count: 1,
    name: "Nombre de Sucursal",
    location: "123 Main Street, City",
    price: "10.12",
    status: "10.12",
  },
  {
    id: 24,
    count: 1,
    name: "Nombre de Sucursal",
    location: "123 Main Street, City",
    price: "55.63",
    status: "55.63",
  },
  {
    id: 25,
    count: 1,
    name: "Nombre de Sucursal",
    location: "456 Oak Avenue, Town",
    price: "13.09",
    status: "13.09",
  },
  {
    id: 26,
    count: 1,
    name: "Nombre de Sucursal",
    location: "123 Main Street, City",
    price: "1.65",
    status: "1.65",
  },
  {
    id: 27,
    count: 1,
    name: "Nombre de Sucursal",
    location: "123 Main Street, City",
    price: "16.59",
    status: "16.59",
  },
  {
    id: 28,
    count: 1,
    name: "Nombre de Sucursal",
    location: "456 Oak Avenue, Town",
    price: "61.54",
    status: "61.54",
  },
  {
    id: 29,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "55.71",
    status: "55.71",
  },
  {
    id: 30,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "16.84",
    status: "16.84",
  },
  {
    id: 31,
    count: 1,
    name: "Nombre de Sucursal",
    location: "456 Oak Avenue, Town",
    price: "14.50",
    status: "14.50",
  },
  {
    id: 32,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "14.60",
    status: "14.60",
  },
  {
    id: 33,
    count: 1,
    name: "Nombre de Sucursal",
    location: "123 Main Street, City",
    price: "87.20",
    status: "87.20",
  },
  {
    id: 34,
    count: 1,
    name: "Nombre de Sucursal",
    location: "456 Oak Avenue, Town",
    price: "23.31",
    status: "23.31",
  },
  {
    id: 35,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "82.51",
    status: "82.51",
  },
  {
    id: 36,
    count: 1,
    name: "Nombre de Sucursal",
    location: "123 Main Street, City",
    price: "65.60",
    status: "65.60",
  },
  {
    id: 37,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "90.40",
    status: "90.40",
  },
  {
    id: 38,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "61.69",
    status: "61.69",
  },
  {
    id: 39,
    count: 1,
    name: "Nombre de Sucursal",
    location: "456 Oak Avenue, Town",
    price: "26.39",
    status: "26.39",
  },
  {
    id: 40,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "1.27",
    status: "1.27",
  },
  {
    id: 41,
    count: 1,
    name: "Nombre de Sucursal",
    location: "123 Main Street, City",
    price: "52.86",
    status: "52.86",
  },
  {
    id: 42,
    count: 1,
    name: "Nombre de Sucursal",
    location: "456 Oak Avenue, Town",
    price: "3.28",
    status: "3.28",
  },
  {
    id: 43,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "25.46",
    status: "25.46",
  },
  {
    id: 44,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "37.13",
    status: "37.13",
  },
  {
    id: 45,
    count: 1,
    name: "Nombre de Sucursal",
    location: "123 Main Street, City",
    price: "80.11",
    status: "80.11",
  },
  {
    id: 46,
    count: 1,
    name: "Nombre de Sucursal",
    location: "456 Oak Avenue, Town",
    price: "94.36",
    status: "94.36",
  },
  {
    id: 47,
    count: 1,
    name: "Nombre de Sucursal",
    location: "123 Main Street, City",
    price: "74.01",
    status: "74.01",
  },
  {
    id: 48,
    count: 1,
    name: "Nombre de Sucursal",
    location: "123 Main Street, City",
    price: "44.34",
    status: "44.34",
  },
  {
    id: 49,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "92.27",
    status: "92.27",
  },
  {
    id: 50,
    count: 1,
    name: "Nombre de Sucursal",
    location: "789 Pine Road, Village",
    price: "55.57",
    status: "55.57",
  },
];
