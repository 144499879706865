import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
  name: "product",
  initialState: {
    activeProduct: null,
  },
  reducers: {
    setActiveProduct: (state, action) => {
      return {
        ...state,
        activeProduct: action.payload,
      };
    },
  },
});

export const { setActiveProduct } = productSlice.actions;
export default productSlice.reducer;
