import { createSlice } from "@reduxjs/toolkit";

const branchSlice = createSlice({
  name: "branch",
  initialState: {
    branchToEdit: null,
  },
  reducers: {
    setBranchToEdit: (state, action) => {
      return {
        ...state,
        branchToEdit: action.payload,
      };
    },
  },
});

export const { setBranchToEdit } = branchSlice.actions;
export default branchSlice.reducer;
