import React from "react";

const KPIs = () => {
  const data = [
    {
      count: 25,
      title: "En entrega",
    },
    {
      count: 60,
      title: "Enviados",
    },
    {
      count: 7,
      title: "Cancelados",
    },
  ];
  return (
    <div className="grid grid-cols-3 gap-5">
      {data.map((x, i) => (
        <div key={i} className="border-2 border-white rounded-lg p-3">
          <h1 className="text-2xl font-bold">{x.count}</h1>
          <p className="text-medium-grey">{x.title}</p>
        </div>
      ))}
    </div>
  );
};

export default KPIs;
