import React from "react";
import Header from "./Header";
import { FaRegHeart, FaStar } from "react-icons/fa6";

const FavoriteArticles = () => {
  return (
    <div className="bg-[#fff] rounded-xl p-5 drop-shadow mt-5">
      <Header />

      <div className="mt-5 grid grid-cols-3 gap-5">
        {[1, 1, 1, 1, 1, 1].map((x, i) => (
          <div key={i}>
            <img
              alt="placeholder"
              className="rounded-lg h-[150px] object-cover"
              src={process.env.PUBLIC_URL + "/pngs/placeholder.png"}
            />

            <p className="mt-3 font-gotham-medium">Nombre del producto</p>

            <div className="mt-4 text-xs">
              <div className="flex mb-1">
                <FaStar className="text-basic-red" />
                <FaStar className="text-basic-red" />
                <FaStar className="text-basic-red" />
                <FaStar className="text-basic-red" />
                <FaStar className="text-light-grey" />
              </div>
              <p className="text-medium-grey font-gotham-light ml-1 ">
                (454 Comentarios)
              </p>
            </div>

            <button className="bg-light-blue flex text-basic-red gap-3 items-center mt-3 px-4 py-2 rounded-full text-sm">
              <FaRegHeart /> 256k{" "}
              <span className="font-gotham-light">Like it</span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FavoriteArticles;
