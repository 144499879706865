import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    categoryToEdit: null,
    categories: [],
    loaders: {
      fetchingCategories: false,
    },
  },
  reducers: {
    setCategories: (state, action) => {
      return {
        ...state,
        categories: action.payload,
      };
    },
    setLoader: (state, action) => {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.payload.loader]: action.payload.value,
        },
      };
    },
    setCategoryToEdit: (state, action) => {
      return {
        ...state,
        categoryToEdit: action.payload,
      };
    },
    deleteFromCategoriesById: (state, action) => {
      const { payload: id } = action;
      console.log({ id });
      const categoriesCopy = [...state.categories];

      const index = categoriesCopy.findIndex((item) => item.id === id);
      console.log({ action });
      if (~index) categoriesCopy.splice(index, 1);
      state.categories = categoriesCopy;
    },
  },
});

export const {
  setCategories,
  setLoader,
  setCategoryToEdit,
  deleteFromCategoriesById,
} = categorySlice.actions;
export default categorySlice.reducer;
