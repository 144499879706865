import React, { useState } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import BranchTable from "../../components/client/branches/BranchTable";

const ClientBranches = () => {
  // rename the states according to real values
  const [selected, setSelected] = useState(null);

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  return (
    <div>
      <div className="flex justify-between font-gotham">
        <div>
          <h1 className="text-xl font-bold">Sucurales</h1>
          <p className="text-medium-grey my-3 font-gotham-light">
            Aquí están los datos generales de su lista de Sucursales.
          </p>
        </div>

        <div className="flex gap-5">
          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2 text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/filter.svg"}
                  alt="check"
                />
                Filtro
              </div>
            </InputLabel>
            <Select
              value={selected}
              onChange={handleChange}
              className="w-56 bg-light-blue"
            >
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>
        </div>
      </div>
      <BranchTable />
    </div>
  );
};

export default ClientBranches;
