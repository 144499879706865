import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setToken, setUser } from "../store/auth";
import { DotLoader, DotLoaderOverlay } from 'react-spinner-overlay'
import { API_URL } from '../api_url'
import axios from "axios";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

const Signup = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [fields, setFields] = React.useState({
        email: "",
        password: "",
        name: "",
        last_name: "",
        email: "",
        ballot: "abc",
        telephone: "",
        address: "",
        password: "",
        password_confirmation: "",
        role: "",
        device_token: "82409feind"
    });
    const dispatch = useDispatch();

    const handleTogglePassword = () => setShowPassword(!showPassword);

    const handleSignup = async () => {
        setLoading(true)
        try {
            const res = await axios.post(`${API_URL}/admin/register`, fields)
            if (res.status === 200) {
                setLoading(false)
                enqueueSnackbar("Register successfully", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                })
                setTimeout(() => {
                    navigate("/")
                }, 2000);
                console.log("register response ===>", res);
            }
        } catch (error) {
            setLoading(false)
            enqueueSnackbar(error?.response?.data?.message, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            console.log("register error ===>", error);
        }
    }

    return (
        <div className="relative font-gotham-light w-screen flex justify-center">
            <DotLoaderOverlay loading={loading} color="red" />
            <SnackbarProvider />
            <div
                className="flex gap-5 flex-col items-center"
            >
                <img
                    alt="logo"
                    className="w-32"
                    src={process.env.PUBLIC_URL + "/svgs/logo.svg"}
                />
                <h1 className="text-basic-red my-1 font-gotham text-[24px]">REGISTERATE</h1>
                <div className='d-flex justify-content-center flex-column align-items-center'>
                    <div style={{ position: "relative", margin: "20px 0" }} >
                        <div >
                            <input
                                value={fields.name}
                                onChange={(e) => {
                                    setFields((prevFields) => {
                                        return {
                                            ...prevFields,
                                            name: e.target.value
                                        }
                                    })
                                }}
                                type='text'
                                placeholder='Nombre*'
                                className="bg-light-blue px-14 py-3 rounded-full w-full "
                            />
                        </div>
                    </div>
                    <div style={{ position: "relative", margin: "20px 0" }} >
                        <div >
                            <input
                                value={fields.last_name}
                                onChange={(e) => {
                                    setFields((prevFields) => {
                                        return {
                                            ...prevFields,
                                            last_name: e.target.value
                                        }
                                    })
                                }}
                                type='text'
                                placeholder='Apellido*'
                                className="bg-light-blue px-14 py-3 rounded-full w-full "
                            />
                        </div>
                    </div>
                    <div style={{ position: "relative", margin: "20px 0" }} >
                        <div >
                            <input
                                className="bg-light-blue px-14 py-3 rounded-full w-full "
                                value={fields.email}
                                onChange={(e) => {
                                    setFields((prevFields) => {
                                        return {
                                            ...prevFields,
                                            email: e.target.value
                                        }
                                    })
                                }}
                                type='text'
                                placeholder='correo electrónico'
                            />
                        </div>
                    </div>
                    <div style={{ position: "relative", margin: "20px 0" }} >
                        <div >
                            <input
                                value={fields.identification_card}
                                onChange={(e) => {
                                    setFields((prevFields) => {
                                        return {
                                            ...prevFields,
                                            identification_card: e.target.value
                                        }
                                    })
                                }}
                                type='text'
                                placeholder='Cédula*'
                                className="bg-light-blue px-14 py-3 rounded-full w-full "
                            />
                        </div>
                    </div>
                    <div style={{ position: "relative", margin: "20px 0" }} >
                        <div >
                            <input
                                value={fields.telephone}
                                onChange={(e) => {
                                    setFields((prevFields) => {
                                        return {
                                            ...prevFields,
                                            telephone: e.target.value
                                        }
                                    })
                                }}
                                type='text' placeholder='Teléfono*' className="bg-light-blue px-14 py-3 rounded-full w-full "
                            />
                        </div>
                    </div>
                    <div style={{ position: "relative", margin: "20px 0" }} >
                        <div >
                            <input
                                value={fields.address}
                                onChange={(e) => {
                                    setFields((prevFields) => {
                                        return {
                                            ...prevFields,
                                            address: e.target.value
                                        }
                                    })
                                }}
                                type='text' placeholder='Dirección*' className="bg-light-blue px-14 py-3 rounded-full w-full "
                            />
                        </div>
                    </div>
                    <div style={{ position: "relative", margin: "20px 0" }} >
                        <div >
                            <input
                                value={fields.password}
                                onChange={(e) => {
                                    setFields((prevFields) => {
                                        return {
                                            ...prevFields,
                                            password: e.target.value
                                        }
                                    })
                                }}
                                type='text' placeholder='Contraseña*' className="bg-light-blue px-14 py-3 rounded-full w-full "
                            />
                        </div>
                        <div style={{ position: "absolute", top: "12px", right: "17px" }}><img width={18} height={14} /></div>
                    </div>
                    <div style={{ position: "relative", margin: "20px 0" }} >
                        <div >
                            <input
                                value={fields.password_confirmation}
                                onChange={(e) => {
                                    setFields((prevFields) => {
                                        return {
                                            ...prevFields,
                                            password_confirmation: e.target.value
                                        }
                                    })
                                }}
                                type='text' placeholder='Repetir contraseña*' className="bg-light-blue px-14 py-3 rounded-full w-full "
                            />
                        </div>
                        <div style={{ position: "absolute", top: "12px", right: "17px" }}><img width={18} height={14} /></div>

                    </div>
                    <div style={{ position: "relative", margin: "20px 0" }} >
                        <div >
                            <input
                                value={fields.role}
                                onChange={(e) => {
                                    setFields((prevFields) => {
                                        return {
                                            ...prevFields,
                                            role: e.target.value
                                        }
                                    })
                                }}
                                type='text' placeholder='Role' className="bg-light-blue px-14 py-3 rounded-full w-full "
                            />
                        </div>
                        <div style={{ position: "absolute", top: "12px", right: "17px" }}><img width={18} height={14} /></div>

                    </div>
                    <button className="font-gotham text-[#fff] bg-basic-red w-full py-3 rounded-full"
                        onClick={handleSignup}>Registrarse</button>
                </div>
            </div>
        </div>
    );
};

export default Signup;
