// import React, { useState } from "react";

// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import DealerTable from "../../components/vendor/dealers/DealerTable";

// const VendorDealers = () => {
//   // rename the states according to real values
//   const [selected1, setSelected1] = useState(null);

//   const handleChange1 = (e) => {
//     setSelected1(e.target.value);
//   };

//   return (
//     <div>
//       <div className="flex justify-between font-gotham">
//         <div>
//           <h1 className="text-xl font-bold">Repartidores</h1>
//           <p className="text-medium-grey font-gotham-light my-3">
//             Aquí están los datos generales de su lista de repartidores
//           </p>
//         </div>

//         <div className="flex gap-5">
//           <FormControl>
//             <InputLabel>
//               <div className="flex items-center gap-2 text-[#000]">
//                 <img
//                   className="h-5 w-5"
//                   src={process.env.PUBLIC_URL + "/svgs/filter.svg"}
//                   alt="check"
//                 />
//                 Filtro
//               </div>
//             </InputLabel>
//             <Select
//               value={selected1}
//               onChange={handleChange1}
//               className="w-56 bg-light-blue"
//             >
//               <MenuItem value={0}>Option 1</MenuItem>
//               <MenuItem value={1}>Option 2</MenuItem>
//               {/* Add more options as needed */}
//             </Select>
//           </FormControl>
//         </div>
//       </div>
//       <DealerTable />
//     </div>
//   );
// };

// export default VendorDealers;

import React, { useEffect, useState } from "react";
import OrderTable from "../../components/admin/orders/OrderTable";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DeliveryPerson from "../../components/admin/delivery-man/DeliveryPerson";
import axios from "axios";
import { API_URL } from "../../api_url";
import { useSelector } from "react-redux";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { DotLoaderOverlay } from "react-spinner-overlay";

const VendorRiders = () => {

  const [loading, setLoading] = useState(null);
  const [riders, setRiders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selected1, setSelected1] = useState(null);
  const [selected2, setSelected2] = useState(null);
  const token = useSelector((state) => state.user.token)

  const handleChange1 = (e) => {
    setSelected1(e.target.value);
  };

  const handleChange2 = (e) => {
    setSelected2(e.target.value);
  };

  const getRiders = async () => {
    setLoading(true)
    try {
      const res = await axios.get(`${API_URL}/rider-list`, {
        headers: {
          Authorization: "Bearer " + token
        }
      })
      if (res.status === 200) {
        setLoading(false)
        enqueueSnackbar("Riders fetch successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        })
        setRiders(res.data)
        console.log(res);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }

  const config = {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    }
  };

  const fetchOrders = async () => {
    try {
      const res = await axios.get(`${API_URL}/admin/orders`, config)
      if (res.status === 200) {
        setOrders(res.data)
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getRiders()
    fetchOrders()
  }, [])

  console.log(riders);

  return (
    <div>
      <SnackbarProvider />
      <DotLoaderOverlay loading={loading} color="red" />
      <div className="flex justify-between font-gotham">
        <div>
          <h1 className="text-xl font-bold">Repartidores</h1>
          <p className="text-medium-grey my-3">Lista de repartidores</p>
        </div>

        <div className="flex gap-5">
          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2 text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/thunder.svg"}
                  alt="check"
                />
                Todos los estados
              </div>
            </InputLabel>
            <Select
              value={selected1}
              onChange={handleChange1}
              className="w-56 bg-light-blue"
            >
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2  text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/hoy.svg"}
                  alt="check"
                />
                Hoy
              </div>
            </InputLabel>
            <Select
              value={selected2}
              onChange={handleChange2}
              className="w-56 bg-light-blue"
            >
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>
        </div>
      </div>
      <DeliveryPerson riders={riders} setLoading={setLoading} orders={orders} />
    </div>
  );
};

export default VendorRiders;
