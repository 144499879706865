import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../api_url";
import { useSelector } from "react-redux";
import { DotLoaderOverlay } from "react-spinner-overlay";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

const AddProduct = () => {
  const categories = useSelector((state) => state.category.categories);
  const token = useSelector((state) => state.user.token);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [extraProducts, setExtraProducts] = useState([]);
  const [productForm, setProductForm] = useState({
    name: "",
    image: null,
    description: "",
    discounted_price: null,
    retail_price: null,
    category_id: "",
    addtional_product: 0,
  });
  const config = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  };

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const checkIsFormInvalid = () => {
    const { addtional_product, ...remainingFields } = productForm;
    const isFormInvalid = Object.keys(remainingFields).some(
      (key) =>
        remainingFields[key] === null ||
        remainingFields[key] === "" ||
        remainingFields[key] === 0
    );
    console.log({ isFormInvalid });
    return isFormInvalid;
  };

  const handleSubmit = async () => {
    if (checkIsFormInvalid()) {
      enqueueSnackbar("Please fill in all the fields", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    const formData = new FormData();
    formData.append("name", productForm.name);
    formData.append("image", productForm.image);
    formData.append("description", productForm.description);
    formData.append("discounted_price", productForm.discounted_price);
    formData.append("retail_price", productForm.retail_price);
    formData.append("category_id", `[${productForm.category_id}]`);
    formData.append("addtional_product", 0);
    setLoading(true);
    try {
      const res = await axios.post(
        `${API_URL}/admin/product/store`,
        formData,
        config
      );
      if (res.status === 200) {
        setLoading(false);
        enqueueSnackbar("Product added successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  console.log(productForm);

  return (
    <div className="font-gotham">
      <SnackbarProvider />
      <DotLoaderOverlay loading={loading} color="red" />
      <div className="flex justify-between mb-5">
        <div>
          <h1 className="text-xl font-bold">Dashboard</h1>
          <p className="my-3">
            <Link to="/banners" className="text-basic-red">
              Productos /
            </Link>{" "}
            <span className="text-medium-grey font-gotham-light">
              Agregar productos
            </span>
          </p>
        </div>

        <div className="flex gap-5">
          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2 text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/calendar.svg"}
                  alt="check"
                />
                Filtro por periodo
              </div>
            </InputLabel>
            <Select
              value={selected}
              onChange={handleChange}
              className="w-56 bg-light-blue"
            >
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
              <MenuItem value={2}>Option 3</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="flex gap-5">
        <div className="bg-[#fff] flex items-center gap-5 rounded-xl p-5 drop-shadow">
          <div className="bg-white flex justify-center p-4 w-16 h-16 rounded-full">
            <img
              src={process.env.PUBLIC_URL + "/svgs/album.svg"}
              alt="total_banners"
            />
          </div>
          <div>
            <h1 className="text-3xl font-bold">93</h1>
            <p className="my-2 text-sm text-medium-grey">Total productos</p>
          </div>
        </div>
      </div>

      <div className="font-gotham-light">
        <div className="bg-light-blue my-5 rounded-lg p-5">
          <label className="flex items-center gap-5 p-3 border-dashed border-2 border-light-grey rounded-lg">
            <input type="file" className="hidden" />
            <img
              width={60}
              src={process.env.PUBLIC_URL + "/svgs/file-cloud.svg"}
              alt="upload"
            />
            <div className="flex-1">
              <p>Selecciona una imagen</p>
              <p>JPG, XLSX or PDF, file size no more than 10MB</p>
            </div>
            <input
              onChange={(e) =>
                setProductForm({ ...productForm, image: e.target.files[0] })
              }
              id="upload-btn"
              type="file"
              hidden
            />
            <label
              for="upload-btn"
              className="bg-basic-red text-[#fff] px-4 rounded-full cursor-pointer flex justify-center items-center"
              style={{ height: "50px" }}
            >
              Selecciona
            </label>
          </label>

          <p className="my-5">CONFIGURACIÓN</p>

          <div className="bg-[#fff] p-3 rounded-lg">
            <p>Nombre del producto</p>
            <input type="text" className="w-full" placeholder="Nombre" />
          </div>

          <div className="flex justify-end mt-5">
            <button
              onClick={() =>
                setExtraProducts((prevExtra) => [
                  ...prevExtra,
                  { name: "", tipo: "", desc: "" },
                ])
              }
              className="bg-basic-red text-[#fff] px-8 py-2 rounded-full "
            >
              agregar producto adicional
            </button>
          </div>

          {extraProducts?.map((item, index) => {
            return (
              <div className="font-gotham-light">
                <div className="flex justify-end mt-5">
                  <button
                    onClick={() => {
                      setExtraProducts((prevExtra) => {
                        return prevExtra.filter((prod, ind) => index !== ind);
                      });
                    }}
                    className="bg-basic-red text-[#fff] px-8 py-2 rounded-full "
                  >
                    eliminar
                  </button>
                </div>
                <div className="bg-light-blue my-5 rounded-lg p-5">
                  <div className="bg-[#fff] p-3 rounded-lg">
                    <p>Nombre del producto</p>
                    <input
                      type="text"
                      className="w-full"
                      placeholder="Nombre"
                    />
                  </div>
                  <div className="bg-[#fff] p-3 rounded-lg mt-5">
                    <p>Tipo de producto</p>
                    <input type="text" className="w-full" placeholder="Tipo" />
                  </div>
                  <div className="bg-[#fff] p-3 rounded-lg mt-5">
                    <p>Descripcion del producto</p>
                    <input
                      type="text"
                      className="w-full"
                      placeholder="Descripcion"
                    />
                  </div>
                </div>
              </div>
            );
          })}

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Tipo de producto</p>
            <input
              value={productForm.name}
              onChange={(e) =>
                setProductForm({ ...productForm, name: e.target.value })
              }
              type="text"
              className="w-full"
              placeholder="Tipo"
            />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Descripcion del producto</p>
            <input
              value={productForm.description}
              onChange={(e) =>
                setProductForm({ ...productForm, description: e.target.value })
              }
              type="text"
              className="w-full"
              placeholder="Descripcion"
            />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>precio al por menor</p>
            <input
              value={productForm.retail_price}
              onChange={(e) =>
                setProductForm({
                  ...productForm,
                  retail_price: Number(e.target.value),
                })
              }
              type="text"
              className="w-full"
              placeholder="Precio"
            />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Precio descontado</p>
            <input
              value={productForm.discounted_price}
              onChange={(e) =>
                setProductForm({
                  ...productForm,
                  discounted_price: Number(e.target.value),
                })
              }
              type="text"
              className="w-full"
              placeholder="Precio"
            />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Fecha</p>
            <input type="date" />
          </div>

          <div className="pt-2 mt-5 bg-[#fff] rounded-md">
            <p className="ml-3">Category</p>
            <Select
              value={selected}
              onChange={(e) => {
                handleChange(e);
                setProductForm({
                  ...productForm,
                  category_id: e.target.value,
                });
              }}
              className="w-full bg-white rouned-md"
              sx={{
                backgroundColor: "white !important",
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
            >
              {categories.map((item) => {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              })}
            </Select>
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Notificacion</p>
            <div className="flex gap-2">
              <input type="checkbox" />
              <label>Papas</label>
              <input type="checkbox" />
              <label>Refresco</label>
              <input type="checkbox" />
              <label>Arepitas</label>
            </div>
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Sucursal ID#</p>
            <input type="text" className="w-full" placeholder="ID" />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Status</p>
            <div className="flex gap-2 items-center">
              <Switch />
              <label>Visible</label>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-5">
        <button className="bg-dark-grey text-[#fff] px-8 py-2 rounded-full ">
          Cancelar
        </button>
        <button
          onClick={handleSubmit}
          className="bg-basic-red text-[#fff] px-8 py-2 rounded-full "
        >
          Aceptar
        </button>
      </div>
    </div>
  );
};

export default AddProduct;
