import React from "react";

import { GoDotFill } from "react-icons/go";
import { FaChevronRight } from "react-icons/fa6";

const Bar = () => {
  return (
    <div className="flex items-center my-5 bg-white p-3 gap-3 rounded-lg">
      <p className="bg-basic-red text-[#fff] px-6 py-2 rounded-lg text-">25</p>
      <p className="font-bold">Nuevas ordenes</p>
      <GoDotFill className="text-basic-red text-2xl" />
      <div className="flex-1" />
      <button className="flex items-center text-basic-red font-semibold">
        Manejar ordenes <FaChevronRight />
      </button>
    </div>
  );
};

export default Bar;
