import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../api_url";
import { useSelector, useDispatch } from "react-redux";
import { DotLoaderOverlay } from "react-spinner-overlay";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import MapComp from "../../components/Map";
import { setBranchToEdit } from "../../store/branch";

const AddBranch = () => {
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const branchToEdit = useSelector((state) => state.branch.branchToEdit);
  const [loading, setLoading] = useState(false);

  const [locationName, setLocationName] = useState(branchToEdit?.name ?? "");
  const [selectedLocation, setSelectedLocation] = useState(
    branchToEdit?.longitude || branchToEdit?.latitude
      ? { longitude: branchToEdit?.longitude, latitude: branchToEdit?.latitude }
      : null
  );

  const [address, setAddress] = useState(branchToEdit?.address ?? "");

  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  };

  const handleEditAddress = async () => {
    const formData = new FormData();
    formData.append("name", locationName);
    formData.append("address", address);
    formData.append(
      "latitude",
      parseFloat(selectedLocation?.latitude ?? selectedLocation?.lat)
    );
    formData.append(
      "longitude",
      parseFloat(selectedLocation?.longitude ?? selectedLocation?.lng)
    );
    formData.append("id", branchToEdit?.id);

    try {
      setLoading(true);
      const res = await axios.post(
        `${API_URL}/admin/branch/update`,
        formData,
        config
      );
      if (res.status === 200) {
        setLoading(false);
        enqueueSnackbar("Branch updated successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        dispatch(setBranchToEdit(null));

        setTimeout(() => {
          navigate("/branches");
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    if (branchToEdit) {
      handleEditAddress();
      return;
    }
    const formData = new FormData();
    formData.append("name", locationName);
    formData.append("address", address);
    formData.append("latitude", selectedLocation?.lat);
    formData.append("longitude", selectedLocation?.lng);
    try {
      setLoading(true);
      const res = await axios.post(
        `${API_URL}/admin/branch/store`,
        formData,
        config
      );
      if (res.status === 200) {
        setLoading(false);
        enqueueSnackbar("Branch created successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setTimeout(() => {
          navigate("/branches");
        }, 2000);
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="font-gotham">
      <SnackbarProvider />
      <DotLoaderOverlay loading={loading} color="red" />
      <div className="flex justify-between mb-5">
        <div>
          <h1 className="text-xl font-bold">Dashboard</h1>
          <p className="my-3">
            <Link to="/banners" className="text-basic-red">
              Sucursal /
            </Link>{" "}
            <span className="text-medium-grey font-gotham-light">
              Agregar sucursales
            </span>
          </p>
        </div>
      </div>

      <div className="font-gotham-light">
        <div className="bg-light-blue my-5 rounded-lg p-5">
          <div className="bg-[#fff] p-3 rounded-lg">
            <p>Nombre del sucursal</p>
            <input
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
              type="text"
              className="w-full"
              placeholder="Nombre"
            />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Dirección de sucursal</p>
            <MapComp
              address={address}
              setAddress={setAddress}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-5">
        <button className="bg-dark-grey text-[#fff] px-8 py-2 rounded-full ">
          Cancelar
        </button>
        <button
          onClick={handleSubmit}
          className="bg-basic-red text-[#fff] px-8 py-2 rounded-full "
        >
          Aceptar
        </button>
      </div>
    </div>
  );
};

export default AddBranch;
