import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import ClientInfo from "../../components/vendor/order/ClientInfo";
import OrderSteps from "../../components/vendor/order/OrderSteps";
import ArticlesList from "../../components/vendor/order/ArticlesList";
import Tracking from "../../components/vendor/order/Tracking";
import CancelOrder from "../../components/vendor/order/CancelOrder";
import Chat from "../../components/vendor/order/Chat";
import axios from "axios";
import { API_URL } from "../../api_url";
import { DotLoaderOverlay } from "react-spinner-overlay";
import { useSelector } from "react-redux";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

const AdminOrder = () => {
  const [changeStatus, setChangeStatus] = useState("");
  const [selected, setSelected] = useState(null);
  const [showCancel, setShowCancel] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [orderDetail, setOrderDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);
  const config = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  };

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${API_URL}/admin/orders`, config);
      if (res.status === 200) {
        setLoading(false);
        setOrderDetail(
          res.data.find((order) => order.id === Number(params.id))
        );
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [params.id]);

  const handleChangeOrderStatus = async (status) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${API_URL}/update-order-status`,
        {
          order_id: params.id,
          status,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 200) {
        setLoading(false);
        enqueueSnackbar("Order Status Change Successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        fetchOrders();
        if (status === "out-for-delivery") {
          setTimeout(() => {
            navigate("/dealers");
          }, 1000);
        }
        // fetchOrders()
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  console.log({ orderDetail });

  return (
    <>
      <SnackbarProvider />
      <DotLoaderOverlay loading={loading} color="red" />
      <div className="font-gotham">
        <div className="flex justify-between items-center mb-5">
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h1 className="text-xl font-bold">Pedido ID #{params.id}</h1>
              <p
                style={{ width: 180, marginLeft: 10 }}
                className={`text-center py-2 rounded-lg font-gotham-medium 
                ${
                  orderDetail?.status === "cancelled"
                    ? "bg-white text-basic-red"
                    : orderDetail?.status === "in-progress"
                    ? "bg-[#2BC155]/10 text-[#2BC155]"
                    : orderDetail?.status === "out-for-delivery"
                    ? "bg-[#2BC155]/10 text-[#2BC155]"
                    : orderDetail?.status === "delivered"
                    ? "bg-[#2BC155]/10 text-[#2BC155]"
                    : orderDetail?.status === "preparing"
                    ? "bg-light-grey text-dark-grey"
                    : ""
                }`}
              >
                {orderDetail?.status}
              </p>
            </div>
            <p className="my-3">
              <Link to="/orders" className="text-basic-red">
                Pedidos /
              </Link>{" "}
              <span className="text-medium-grey font-gotham-light">
                Detalles del pedido
              </span>
            </p>
          </div>

          <div className="flex gap-5">
            <FormControl>
              <InputLabel>
                <div className="flex items-center gap-2 text-[#000]">
                  <img
                    className="h-5 w-5"
                    src={process.env.PUBLIC_URL + "/svgs/thunder.svg"}
                    alt="check"
                  />
                  EN PROCESO
                </div>
              </InputLabel>
              <Select
                onChange={(e) => handleChangeOrderStatus(e.target.value)}
                value={changeStatus ? changeStatus : orderDetail?.status}
                className="w-56 bg-light-blue"
              >
                <MenuItem value="out-for-delivery">Out-for-delivery</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="flex gap-5 w-full">
          <div className="">
            <ClientInfo user={orderDetail?.user ?? null} />
            <OrderSteps orderDetails={orderDetail ?? null} />
          </div>

          <div className="flex-1">
            <ArticlesList />
            <Tracking
              orderId={params.id}
              chat={() => setShowChat(true)}
              cancelOrder={() => setShowCancel(true)}
              orderDetails={orderDetail}
            />
          </div>
        </div>
      </div>

      {showCancel && <CancelOrder handleClose={() => setShowCancel(false)} />}
      {showChat && <Chat handleClose={() => setShowChat(false)} />}
    </>
  );
};

export default AdminOrder;
