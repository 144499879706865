import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import ClientInfo from "../../components/admin/order/ClientInfo";
import OrderSteps from "../../components/admin/order/OrderSteps";
import ArticlesList from "../../components/admin/order/ArticlesList";
import Tracking from "../../components/admin/order/Tracking";
import CancelOrder from "../../components/admin/order/CancelOrder";
import Chat from "../../components/admin/order/Chat";
import axios from "axios";
import { API_URL } from "../../api_url";
import { useSelector } from "react-redux";

const OrderHistory = () => {

  const token = useSelector((state) => state.user.token)
  const [selected, setSelected] = useState(null);
  const [showCancel, setShowCancel] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const location = useLocation();

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  console.log(location.state);

  async function getRiderLocation() {
    try {
      const res = await axios.post(`${API_URL}/admin/rider-location`, {
        rider_id: location.state.driver_id,
        order_id: location.state.id
      }, {
        headers: {
          Authorization: "Bearer " + token
        }
      })
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getRiderLocation()
  }, [])

  return (
    <>
      <div className="font-gotham">
        <div className="flex justify-between mb-5">
          <div>
            <h1 className="text-xl font-bold">Pedido ID #{location.state?.id}</h1>
            <p className="my-3">
              <Link to="/orders" className="text-basic-red">
                Pedidos /
              </Link>{" "}
              <span className="text-medium-grey font-gotham-light">
                Detalles del pedido
              </span>
            </p>
          </div>

          <div className="flex gap-5">
            <FormControl>
              <InputLabel>
                <div className="flex items-center gap-2 text-[#000]">
                  <img
                    className="h-5 w-5"
                    src={process.env.PUBLIC_URL + "/svgs/thunder.svg"}
                    alt="check"
                  />
                  EN ENTREGA
                </div>
              </InputLabel>
              <Select
                value={selected}
                onChange={handleChange}
                className="w-56 bg-light-blue"
              >
                <MenuItem value={0}>Option 1</MenuItem>
                <MenuItem value={1}>Option 2</MenuItem>
                <MenuItem value={2}>Option 3</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="flex gap-5 w-full">
          <div className="">
            <ClientInfo />
            <OrderSteps />
          </div>

          <div className="flex-1">
            <ArticlesList />
            <Tracking
              chat={() => setShowChat(true)}
              cancelOrder={() => setShowCancel(true)}
            />
          </div>
        </div>
      </div>

      {showCancel && <CancelOrder handleClose={() => setShowCancel(false)} />}
      {showChat && <Chat handleClose={() => setShowChat(false)} />}
    </>
  );
};

export default OrderHistory;
