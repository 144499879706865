import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { API_URL } from "../../api_url";
import { DotLoaderOverlay } from "react-spinner-overlay";
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import axios from "axios";

const EditDeliveryPerson = () => {

  const params = useParams()
  const location = useLocation()
  const token = useSelector((state) => state.user.token)
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState([]);
  const [riderForm, setRiderForm] = useState({
    name: location.state.name,
    identification_card: location.state.identification_card,
    phone: location.state.phone,
    model_vehicle: location.state.model_vehicle,
    start_time_of_work: location.state.start_time_of_work,
    departure_time: location.state.departure_time,
    start_date: location.state.start_date,
    branch: location.state.branch,
    id: params.id,
  })
  const config = {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    }
  };

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const res = await axios.post(`${API_URL}/admin/rider/update`, riderForm, config)
      if (res.status === 200) {
        setLoading(false)
        enqueueSnackbar("Rider update successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        })
        console.log(res);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }

  const getBranches = async () => {
    try {
      const res = await axios.get(`${API_URL}/admin/branch`, config)
      if (res.status === 200) {
        console.log(res);
        setBranches(res.data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getBranches()
  }, [])

  console.log(riderForm);

  return (
    <div className="font-gotham">
      <SnackbarProvider />
      <DotLoaderOverlay loading={loading} color="red" />
      <div className="flex justify-between mb-5">
        <div>
          <h1 className="text-xl font-bold">Dashboard</h1>
          <p className="my-3">
            <Link to="/banners" className="text-basic-red">
              Repartidores /
            </Link>{" "}
            <span className="text-medium-grey font-gotham-light">
              Agregar repartidores
            </span>
          </p>
        </div>

        <div className="flex gap-5">
          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2 text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/calendar.svg"}
                  alt="check"
                />
                Filtro por periodo
              </div>
            </InputLabel>
            <Select
              value={selected}
              onChange={handleChange}
              className="w-56 bg-light-blue"
            >
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
              <MenuItem value={2}>Option 3</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="flex gap-5">
        <div className="bg-[#fff] flex items-center gap-5 rounded-xl p-5 drop-shadow">
          <div className="bg-white flex justify-center p-4 w-16 h-16 rounded-full">
            <img
              src={process.env.PUBLIC_URL + "/svgs/album.svg"}
              alt="total_banners"
            />
          </div>
          <div>
            <h1 className="text-3xl font-bold">93</h1>
            <p className="my-2 text-sm text-medium-grey">Total repartidores</p>
          </div>
        </div>
      </div>

      <div className="font-gotham-light">
        <div className="bg-light-blue my-5 rounded-lg p-5">

          <p className="my-5">Información del ciclista</p>

          <div className="bg-[#fff] p-3 rounded-lg">
            <p>Nombre</p>
            <input value={riderForm.name} onChange={(e) => setRiderForm({ ...riderForm, name: e.target.value })} type="text" className="w-full" placeholder="Nombre" />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Tarjeta de identificación</p>
            <input value={riderForm.identification_card} onChange={(e) => setRiderForm({ ...riderForm, identification_card: e.target.value })} type="text" className="w-full" placeholder="Nombre" />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Teléfono</p>
            <input value={riderForm.phone} onChange={(e) => setRiderForm({ ...riderForm, phone: e.target.value })} type="text" className="w-full" placeholder="Tipo" />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Vehículo modal</p>
            <input value={riderForm.model_vehicle} onChange={(e) => setRiderForm({ ...riderForm, model_vehicle: e.target.value })} type="text" className="w-full" placeholder="Tipo" />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Hora de inicio del trabajo</p>
            <input type="time" value={riderForm.start_time_of_work} onChange={(e) => setRiderForm({ ...riderForm, start_time_of_work: e.target.value })} />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Hora de salida</p>
            <input type="time" value={riderForm.departure_time} onChange={(e) => setRiderForm({ ...riderForm, departure_time: e.target.value })} />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Fecha de inicio</p>
            <input type="date" value={riderForm.start_date} onChange={(e) => setRiderForm({ ...riderForm, start_date: e.target.value })} />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Sucursales</p>
            <Select
              value={riderForm.branch}
              onChange={(e) => setRiderForm({ ...riderForm, branch: e.target.value })}
              className="w-56 bg-light-blue"
            >
              {branches?.map((opt) => <MenuItem value={opt.id}>{opt.name}</MenuItem>)}
            </Select>
          </div>

        </div>
      </div>

      <div className="flex justify-end gap-5">
        <button className="bg-dark-grey text-[#fff] px-8 py-2 rounded-full ">
          Cancelar
        </button>
        <button onClick={handleSubmit} className="bg-basic-red text-[#fff] px-8 py-2 rounded-full ">
          Aceptar
        </button>
      </div>
    </div>
  );
};

export default EditDeliveryPerson;
