export const chartConfig = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false, // removes verticle lines
      },
    },
    y: {
      min: 0,
      max: 60,
      ticks: {
        stepSize: 20,
      },
    },
  },
  barThickness: 10,
};

// labels showing on x axis
const labels = Array(27)
  .fill()
  .map((_, i) => i + 4);

// generate random value for every label on x-axis
// if you want to add another dataset just add another record in datasets array
export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => Math.floor(Math.random() * (60 - 1))),
      backgroundColor: "#E52823",
    },
  ],
};
