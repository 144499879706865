export const data = [
    {
      id: 1,
      date: "2023-08-05",
      name: "Charlie Brown",
      image: "https://media.licdn.com/dms/image/D5612AQHpA-gJnj8WIA/article-cover_image-shrink_720_1280/0/1694695499395?e=2147483647&v=beta&t=0wPxvIWjxlZ-PNw0IcKkTWC6MCaG0LOlPSKGWOa3QW0"
    },
    {
      id: 2,
      date: "2023-11-28",
      name: "Charlie Brown",
      image: "https://media.licdn.com/dms/image/D5612AQHpA-gJnj8WIA/article-cover_image-shrink_720_1280/0/1694695499395?e=2147483647&v=beta&t=0wPxvIWjxlZ-PNw0IcKkTWC6MCaG0LOlPSKGWOa3QW0"

    },
    {
      id: 3,
      date: "2023-11-09",
      name: "Alice Williams",
      image: "https://media.licdn.com/dms/image/D5612AQHpA-gJnj8WIA/article-cover_image-shrink_720_1280/0/1694695499395?e=2147483647&v=beta&t=0wPxvIWjxlZ-PNw0IcKkTWC6MCaG0LOlPSKGWOa3QW0"

    },
    {
      id: 4,
      date: "2023-10-08",
      name: "Bob Johnson",
      image: "https://media.licdn.com/dms/image/D5612AQHpA-gJnj8WIA/article-cover_image-shrink_720_1280/0/1694695499395?e=2147483647&v=beta&t=0wPxvIWjxlZ-PNw0IcKkTWC6MCaG0LOlPSKGWOa3QW0"

    },
    {
      id: 5,
      date: "2023-12-06",
      name: "John Doe",
      image: "https://media.licdn.com/dms/image/D5612AQHpA-gJnj8WIA/article-cover_image-shrink_720_1280/0/1694695499395?e=2147483647&v=beta&t=0wPxvIWjxlZ-PNw0IcKkTWC6MCaG0LOlPSKGWOa3QW0"

    },
    {
      id: 6,
      date: "2023-08-28",
      name: "Alice Williams",
      image: "https://media.licdn.com/dms/image/D5612AQHpA-gJnj8WIA/article-cover_image-shrink_720_1280/0/1694695499395?e=2147483647&v=beta&t=0wPxvIWjxlZ-PNw0IcKkTWC6MCaG0LOlPSKGWOa3QW0"

    },
    {
      id: 7,
      date: "2023-06-15",
      name: "Charlie Brown",
      image: "https://media.licdn.com/dms/image/D5612AQHpA-gJnj8WIA/article-cover_image-shrink_720_1280/0/1694695499395?e=2147483647&v=beta&t=0wPxvIWjxlZ-PNw0IcKkTWC6MCaG0LOlPSKGWOa3QW0"

    },
    {
      id: 8,
      date: "2023-07-28",
      name: "Jane Smith",
      image: "https://media.licdn.com/dms/image/D5612AQHpA-gJnj8WIA/article-cover_image-shrink_720_1280/0/1694695499395?e=2147483647&v=beta&t=0wPxvIWjxlZ-PNw0IcKkTWC6MCaG0LOlPSKGWOa3QW0"

    },
    {
      id: 9,
      date: "2023-09-07",
      name: "John Doe",
      image: "https://media.licdn.com/dms/image/D5612AQHpA-gJnj8WIA/article-cover_image-shrink_720_1280/0/1694695499395?e=2147483647&v=beta&t=0wPxvIWjxlZ-PNw0IcKkTWC6MCaG0LOlPSKGWOa3QW0"

    },
  ];
  