import { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, } from '@mui/material/styles';
import Location from "../../src/assets/images/location.png"
import { useNavigate } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Geocode from 'react-geocode';

function MapComp(props) {

    const { address, setAddress, selectedLocation ,setSelectedLocation } = props

    const theme = useTheme();
    const [width, setWidth] = useState()
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [mapZoom, setMapZoom] = useState(8);
    const [map, setMap] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const textStyle = {
        color: ' #EB2830',
        fontFamily: 'var(--font-gotham-black)',
        fontSize: ' 22px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '22px',
        margin: width < 768 && "0"
    }
    const smallText = {
        color: ' var(--Dark_Grey, #32343E)',
        fontFamily: 'var(--font-gotham)',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '900',
        margin: "0 0 0 10px",
        lineHeight: "normal"
    }
    const btnStyle = {
        width: '353px',
        height: '57px',
        borderRadius: '100px',
        background: '#EB252D',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        // marginBotton:"px"
    }
    const btnStyle2 = {
        width: '100%',
        fontFamily: 'var(--font-gotham)',
        borderRadius: '100px',
        background: '#EB252D',
        outline: "none",
        border: "none",
        color: "white",
        fontWeight: "900",
        padding: "20px 0"
    }
    const navigate = useNavigate()

    // const restaurants = [
    //     {
    //         description: 'Avenida Winston Churchill, Santo Domingo',
    //         id: 1,
    //         latitude: 18.4722344,
    //         longitude: -69.9419302,
    //         name: 'Av. Winston Churchill. Multicentro Churchill, La Sirena',
    //     },
    //     {
    //         description:
    //             'Av. Tiradentes, Gasolinera Sunix , casi esquina Roberto Pastoriza',
    //         id: 2,
    //         latitude: 18.4819735,
    //         longitude: -69.9288782,
    //         name: 'Av. Tiradentes, Gasolinera Sunix',
    //     },
    //     {
    //         description: 'Av. Luperón. Estación Shell',
    //         id: 3,
    //         latitude: 18.4653429,
    //         longitude: -69.9685964,
    //         name: 'Av. Luperón. Estación Shell',
    //     },
    //     {
    //         description: 'Jumbo Av. Luperón Food Court',
    //         id: 4,
    //         latitude: 18.4653429,
    //         longitude: -69.9685964,
    //         name: 'Jumbo Av. Luperón Food Court',
    //     },
    //     {
    //         // this one
    //         description: 'Av. España, Estación Texaco La Marina',
    //         id: 5,
    //         latitude: 37.78825,
    //         longitude: -122.4324,
    //         name: 'Av. España, Estación Texaco La Marina',
    //     },
    //     {
    //         description: 'Carretera Mella Esq. San Vicente de Paul',
    //         id: 6,
    //         latitude: 18.5059946,
    //         longitude: -69.85655899999999,
    //         name: 'Megacentro',
    //     },
    //     {
    //         description: 'Santo Domingo, Dominican Republic',
    //         id: 7,
    //         latitude: 18.4498822,
    //         longitude: -69.96861369999999,
    //         name: '27 de Febrero con Caonabo',
    //     },
    //     {
    //         description: 'Av. Winston Churchill con Roberto Pastoriza',
    //         id: 8,
    //         latitude: 18.4660662,
    //         longitude: -69.93816269999999,
    //         name: 'Av. Winston Churchill con Roberto Pastoriza',
    //     },

    //     {

    //         description:
    //             'Restaurante Pollos Victorina , Local 4 PLAZA CUADRA, San Isidro. Av. Rafael Tomas Fernandez Dominguez, Santo Domingo este.(Después de la planta de Hielo Alaska y del Nuevo Supermercado Jumbo)',
    //         id: 9,
    //         latitude: 18.487301,
    //         longitude: -69.8280386,
    //         name: 'POLLOS VICTORINA SAN ISIDRO',
    //     },
    //     {
    //         description:
    //             'Nueva sucursal en la estación de combustible sunix en la 27 de febrero, entre la Abraham Lincoln y la Tiradentes',
    //         id: 10,
    //         latitude: 18.4712378,
    //         longitude: -69.925242,
    //         name: 'Bomba Sunix. Av 27 de Febrero',
    //     },
    //     {
    //         description:
    //             'Avn. John F. Kennedy esquina Tiradentes, estación de combustible Sunix',
    //         id: 11,
    //         latitude: 40.6446245,
    //         longitude: -73.7797035,
    //         name: 'POLLOS VICTORINA JFK',
    //     },
    //     {
    //         description: 'Av. Jonh F. Kennedy, Plaza Galería 360, Santo Domingo',
    //         id: 12,
    //         latitude: 18.4850603,
    //         longitude: -69.9360289,
    //         name: 'Galería 360',
    //     },
    //     {
    //         description:
    //             'Visita nuestra nueva sucursal en Satiago AV. 27 de Febrero Esq. Calle Maimón',
    //         id: 13,
    //         latitude: 19.4603448,
    //         longitude: -70.6870114,
    //         name: 'Pollos Victorina Santiago',
    //     },

    //     {
    //         description: 'Aeropuerto Internacional Cibao',
    //         id: 14,
    //         latitude: 19.4021334,
    //         longitude: -70.6019104,
    //         name: 'Pollos Victorina',
    //     },
    //     {
    //         description:
    //             'Estación de Combustible United Petroleum, Boulevard Turístico del Este.',
    //         id: 15,
    //         latitude: 18.5600761,
    //         longitude: -68.372535,
    //         name: 'Punta Cana',
    //     },
    //     {
    //         description: 'Plaza San Juan Shopping Center, Av Barcelo, Punta Cana',
    //         id: 16,
    //         latitude: 18.6853385,
    //         longitude: -68.4501446,
    //         name: 'Bávaro',
    //     },
    //     {
    //         description:
    //             'Plaza Pueblo Bavaro, Entrada a Pueblo Bavaro, Av. Barcelo, entre Bavaro y Veron',
    //         id: 17,
    //         latitude: 18.6076879,
    //         longitude: -68.4114743,
    //         name: 'POLLOS VICTORINA PUEBLO BAVARO',
    //     },
    //     {
    //         description: 'Calle José A. Santana, Multiplaza, La Sirena, Higuey',
    //         id: 18,
    //         latitude: 18.6127833,
    //         longitude: -68.71544999999999,
    //         name: 'Higuey',
    //     },
    //     {
    //         description: 'Avenida Liberta esquina Dr. Gonzalvo #3',
    //         id: 19,
    //         latitude: 18.4187107,
    //         longitude: -68.9660873,
    //         name: 'Jumbo, La Romana',
    //     },
    //     {
    //         description:
    //             'Av. Circunvalación, Esq. Padre Abreu, Multiplaza , La Sirena, La Romana',
    //         id: 20,
    //         latitude: 18.4338645,
    //         longitude: -68.9658817,
    //         name: 'La Romana',
    //     },
    // ];

    // const LATITUDE = 29.9417666;
    // const LONGITUDE = -95.3991524;
    // const LATITUDE_DELTA = 0.0922;
    // const LONGITUDE_DELTA = 0.0421;
    // const SPACE = 0.01;

    // const mapRef = useRef();
    // const [mapCenter, setMapCenter] = useState({ lat: 18.7357, lng: -70.1627 });
    // const [region, setRegion] = useState({
    //     latitude: 18.4338645,
    //     longitude: -68.9658817,
    //     latitudeDelta: LATITUDE_DELTA,
    //     longitudeDelta: LATITUDE_DELTA,
    // });

    // useEffect(() => {
    //     getLocationLL(address?.name);
    // }, [address]);

    // const getLocationLL = async (address) => {
    //     try {
    //         const response = await Geocode.fromAddress(address);
    //         const { results, status } = response;
    //         if (status === 'OK') {
    //             const { lat, lng } = results[0].geometry.location;
    //             const newRegion = {
    //                 latitude: lat,
    //                 longitude: lng,
    //                 latitudeDelta: LATITUDE_DELTA,
    //                 longitudeDelta: LATITUDE_DELTA,
    //             };
    //             setMapCenter({ lat, lng });
    //             setAddress({ lat, lng })
    //             setRegion(newRegion);
    //             mapRef.current.panTo({ lat, lng });
    //             mapRef.current.setZoom(13);
    //         } else {
    //             console.warn('Geocode API request failed with status:', status);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching location:', error);
    //     }
    // };


    useEffect(() => {
        // Set Santo Domingo coordinates as the initial map center
        setMapCenter({ lat: 18.4861, lng: -69.9312 });
        setMapZoom(13); // Set an appropriate zoom level
    }, []);

    const handleMapLoad = (map) => {
        setMap(map);
    };

    const handleMapClick = (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setSelectedLocation({ lat, lng });
        setMapCenter({ lat, lng });
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    setAddress(results[0].formatted_address);
                } else {
                    setAddress('Address not found');
                }
            } else {
                setAddress('Geocoder failed due to: ' + status);
            }
        });
    };

    return (
        <>

            <div style={{ padding: width < 768 && "30px" }}>
                <LoadScript googleMapsApiKey="AIzaSyABy0de4oAU34qkNVvF4xiiVmvS9zdeiMY">
                    {/* <GoogleMap
                        ref={mapRef}
                        mapContainerStyle={{ height: '400px', width: '100%' }}
                        center={mapCenter}
                        zoom={13}
                        region={region}
                        initialRegion={{
                            latitude: 18.4338645,
                            longitude: -68.9658817,
                            latitudeDelta: 0.0922,
                            longitudeDelta: 0.0421,
                        }}
                    >
                        {restaurants.map((restaurant) => (
                            <Marker
                                icon={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAh1BMVEX////zjY/sP0f5wsP0mpz1n6H/+vruVFnqHSvrKjTrIzD6z9D97+/2pKfuUFXuTVP3tbfxcHXxdnr6zc/719f+9fXsOULyfoL/+Pj96+vuWF7qGij95ub729384OHvXmTzkpbyhYj3sbPwZWrsQ0v5x8fwbXHsNT72qavwZ2/4vrn3tLf0j5UFPw9iAAABQUlEQVR4AZXOVWLDMBAE0JFBDkhmklEyu8n9z9eYyvg+F2YXf0M03TDxNWqdtPPlii8wTmznfLlYuuv5AfFCvIj8OE5SKq7ZKTdoQcoqK7BLuWPXcg2wnQgL1TBs/LjVWh2LoMaKE8fFoqy72Irl0tJZ1kdYlRnFgxwcy6sTABWrhtbFZlifSmKny1QbrLc0He+aw6iknHpeiYrkNXaRxdZYkmLRt91wtreTYRH4WFQ9UgRJAVYEebYNFma956tUkZkIALSvjtQBG/2Rx7EpUqyqGTt3wCEkdJ2vQ+yob+IwqWgJMPCicJrXwUEAaPFKtIodXTJyl7t4IyL2RLerQ2tOId67Dg6PwFRsiieGj9Imc4gzXwFB8VmqmzrFP1GjpJSVELfp1oiPifJ+N9yEl/fZMAQ+CIqyDEqRluW1Z/iDZ03hGHJ5J3VuAAAAAElFTkSuQmCC"}
                                onPress={() => {
                                    setAddress(restaurant);
                                }}
                                // coordinate={{ latitude: restaurant?.latitude, longitude: restaurant?.longitude }}
                                title={restaurant?.name}
                                description={restaurant?.description}
                                key={restaurant.id}
                                position={{ lat: restaurant.latitude, lng: restaurant.longitude }}
                                onClick={() => setAddress(restaurant)}
                            />
                        ))}
                    </GoogleMap> */}
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '300px' }}
                        zoom={mapZoom}
                        center={mapCenter}
                        onLoad={handleMapLoad}
                        onClick={handleMapClick}
                    >
                        {selectedLocation && (
                            <Marker position={selectedLocation} />
                        )}
                    </GoogleMap>
                </LoadScript>
                <div style={{ padding: "0 30px" }}>
                    <div style={textStyle}>
                        Para retirar
                    </div>
                    {address}
                    {/* {restaurants.map((restaurant) => {
                        return (
                            <div className='d-flex mb-3 mt-3'>
                                <div><img src={Location} /></div>
                                <div style={{ ...smallText, color: address?.id === restaurant?.id ? "red" : "grey" }}>{restaurant.description}</div>
                            </div>
                        )
                    })} */}
                </div>
            </div>
        </>
    );
}

export default MapComp;