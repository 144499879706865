import React from "react";

const StatItem = ({ stat: { icon, title, count, bottomText } }) => {
  return (
    <div className="bg-[#fff] flex items-center gap-5 rounded-xl p-5 drop-shadow">
      <div className="bg-light-grey flex justify-center p-4 w-16 h-16 rounded-full">
        <img src={icon} alt={title} />
      </div>
      <div>
        <h1 className="text-3xl font-bold">{count}</h1>
        <p className="my-2 font-semibold text-sm">{title}</p>
        <p className="text-medium-grey text-xs flex gap-1">
          <img src={process.env.PUBLIC_URL + "/svgs/graph.svg"} alt="graph" />
          {bottomText}
        </p>
      </div>
    </div>
  );
};

export default StatItem;
