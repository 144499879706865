import React from "react";

const BranchInfo = () => {
  return (
    <div className="w-[300px] bg-[#fff] rounded-xl drop-shadow flex flex-col gap-3 items-center py-5">
      <img
        alt="client"
        src={process.env.PUBLIC_URL + "/pngs/placeholder.png"}
        className="w-44 h-44 rounded-full object-cover"
      />
      <h1 className="text-dark-grey text-xl">Nombre sucursal</h1>

      <div className="my-3 flex w-full bg-medium-grey text-[#fff] items-center rounded-2xl p-3">
        <img
          alt="bicycle"
          src={process.env.PUBLIC_URL + "/svgs/bicycle.svg"}
          className="mb-[-20px]"
        />

        <p>Radio de 55 KM</p>
      </div>
    </div>
  );
};

export default BranchInfo;
