export const data = [
  {
    id: 1,
    date: "2023-08-05",
    name: "Charlie Brown",
    location: "456 Oak Avenue, Town",
    price: "83.44",
    status: "83.44",
  },
  {
    id: 2,
    date: "2023-11-28",
    name: "Charlie Brown",
    location: "123 Main Street, City",
    price: "14.97",
    status: "14.97",
  },
  {
    id: 3,
    date: "2023-11-09",
    name: "Alice Williams",
    location: "456 Oak Avenue, Town",
    price: "70.84",
    status: "70.84",
  },
  {
    id: 4,
    date: "2023-10-08",
    name: "Bob Johnson",
    location: "789 Pine Road, Village",
    price: "12.99",
    status: "12.99",
  },
  {
    id: 5,
    date: "2023-12-06",
    name: "John Doe",
    location: "123 Main Street, City",
    price: "26.96",
    status: "26.96",
  },
  {
    id: 6,
    date: "2023-08-28",
    name: "Alice Williams",
    location: "123 Main Street, City",
    price: "54.96",
    status: "54.96",
  },
  {
    id: 7,
    date: "2023-06-15",
    name: "Charlie Brown",
    location: "456 Oak Avenue, Town",
    price: "24.12",
    status: "24.12",
  },
  {
    id: 8,
    date: "2023-07-28",
    name: "Jane Smith",
    location: "123 Main Street, City",
    price: "25.23",
    status: "25.23",
  },
  {
    id: 9,
    date: "2023-09-07",
    name: "John Doe",
    location: "789 Pine Road, Village",
    price: "72.12",
    status: "72.12",
  },
  {
    id: 10,
    date: "2023-01-12",
    name: "Jane Smith",
    location: "456 Oak Avenue, Town",
    price: "19.87",
    status: "19.87",
  },
  {
    id: 11,
    date: "2023-05-27",
    name: "John Doe",
    location: "456 Oak Avenue, Town",
    price: "86.35",
    status: "86.35",
  },
  {
    id: 12,
    date: "2023-01-14",
    name: "John Doe",
    location: "789 Pine Road, Village",
    price: "56.86",
    status: "56.86",
  },
  {
    id: 13,
    date: "2023-07-27",
    name: "Alice Williams",
    location: "456 Oak Avenue, Town",
    price: "99.99",
    status: "99.99",
  },
  {
    id: 14,
    date: "2023-12-10",
    name: "Bob Johnson",
    location: "456 Oak Avenue, Town",
    price: "74.39",
    status: "74.39",
  },
  {
    id: 15,
    date: "2023-02-16",
    name: "Alice Williams",
    location: "789 Pine Road, Village",
    price: "83.78",
    status: "83.78",
  },
  {
    id: 16,
    date: "2023-11-10",
    name: "Alice Williams",
    location: "123 Main Street, City",
    price: "79.35",
    status: "79.35",
  },
  {
    id: 17,
    date: "2023-04-13",
    name: "Charlie Brown",
    location: "456 Oak Avenue, Town",
    price: "90.36",
    status: "90.36",
  },
  {
    id: 18,
    date: "2023-11-27",
    name: "Bob Johnson",
    location: "789 Pine Road, Village",
    price: "33.14",
    status: "33.14",
  },
  {
    id: 19,
    date: "2023-12-09",
    name: "Jane Smith",
    location: "789 Pine Road, Village",
    price: "23.03",
    status: "23.03",
  },
  {
    id: 20,
    date: "2023-01-08",
    name: "Bob Johnson",
    location: "789 Pine Road, Village",
    price: "12.00",
    status: "12.00",
  },
  {
    id: 21,
    date: "2023-01-18",
    name: "Alice Williams",
    location: "123 Main Street, City",
    price: "94.25",
    status: "94.25",
  },
  {
    id: 22,
    date: "2023-06-02",
    name: "John Doe",
    location: "789 Pine Road, Village",
    price: "38.17",
    status: "38.17",
  },
  {
    id: 23,
    date: "2023-03-14",
    name: "John Doe",
    location: "123 Main Street, City",
    price: "10.12",
    status: "10.12",
  },
  {
    id: 24,
    date: "2023-07-27",
    name: "John Doe",
    location: "123 Main Street, City",
    price: "55.63",
    status: "55.63",
  },
  {
    id: 25,
    date: "2023-05-11",
    name: "Alice Williams",
    location: "456 Oak Avenue, Town",
    price: "13.09",
    status: "13.09",
  },
  {
    id: 26,
    date: "2023-11-10",
    name: "Jane Smith",
    location: "123 Main Street, City",
    price: "1.65",
    status: "1.65",
  },
  {
    id: 27,
    date: "2023-02-02",
    name: "Bob Johnson",
    location: "123 Main Street, City",
    price: "16.59",
    status: "16.59",
  },
  {
    id: 28,
    date: "2023-12-03",
    name: "Charlie Brown",
    location: "456 Oak Avenue, Town",
    price: "61.54",
    status: "61.54",
  },
  {
    id: 29,
    date: "2023-05-04",
    name: "Bob Johnson",
    location: "789 Pine Road, Village",
    price: "55.71",
    status: "55.71",
  },
  {
    id: 30,
    date: "2023-04-17",
    name: "Bob Johnson",
    location: "789 Pine Road, Village",
    price: "16.84",
    status: "16.84",
  },
  {
    id: 31,
    date: "2023-01-18",
    name: "Charlie Brown",
    location: "456 Oak Avenue, Town",
    price: "14.50",
    status: "14.50",
  },
  {
    id: 32,
    date: "2023-12-26",
    name: "Alice Williams",
    location: "789 Pine Road, Village",
    price: "14.60",
    status: "14.60",
  },
  {
    id: 33,
    date: "2023-03-24",
    name: "John Doe",
    location: "123 Main Street, City",
    price: "87.20",
    status: "87.20",
  },
  {
    id: 34,
    date: "2023-02-20",
    name: "Bob Johnson",
    location: "456 Oak Avenue, Town",
    price: "23.31",
    status: "23.31",
  },
  {
    id: 35,
    date: "2023-04-07",
    name: "Bob Johnson",
    location: "789 Pine Road, Village",
    price: "82.51",
    status: "82.51",
  },
  {
    id: 36,
    date: "2023-08-19",
    name: "Jane Smith",
    location: "123 Main Street, City",
    price: "65.60",
    status: "65.60",
  },
  {
    id: 37,
    date: "2023-08-18",
    name: "Bob Johnson",
    location: "789 Pine Road, Village",
    price: "90.40",
    status: "90.40",
  },
  {
    id: 38,
    date: "2023-01-18",
    name: "Alice Williams",
    location: "789 Pine Road, Village",
    price: "61.69",
    status: "61.69",
  },
  {
    id: 39,
    date: "2023-08-27",
    name: "Alice Williams",
    location: "456 Oak Avenue, Town",
    price: "26.39",
    status: "26.39",
  },
  {
    id: 40,
    date: "2023-08-23",
    name: "John Doe",
    location: "789 Pine Road, Village",
    price: "1.27",
    status: "1.27",
  },
  {
    id: 41,
    date: "2023-07-26",
    name: "Alice Williams",
    location: "123 Main Street, City",
    price: "52.86",
    status: "52.86",
  },
  {
    id: 42,
    date: "2023-05-21",
    name: "John Doe",
    location: "456 Oak Avenue, Town",
    price: "3.28",
    status: "3.28",
  },
  {
    id: 43,
    date: "2023-12-19",
    name: "Alice Williams",
    location: "789 Pine Road, Village",
    price: "25.46",
    status: "25.46",
  },
  {
    id: 44,
    date: "2023-12-19",
    name: "Charlie Brown",
    location: "789 Pine Road, Village",
    price: "37.13",
    status: "37.13",
  },
  {
    id: 45,
    date: "2023-08-02",
    name: "Bob Johnson",
    location: "123 Main Street, City",
    price: "80.11",
    status: "80.11",
  },
  {
    id: 46,
    date: "2023-05-19",
    name: "John Doe",
    location: "456 Oak Avenue, Town",
    price: "94.36",
    status: "94.36",
  },
  {
    id: 47,
    date: "2023-01-05",
    name: "Alice Williams",
    location: "123 Main Street, City",
    price: "74.01",
    status: "74.01",
  },
  {
    id: 48,
    date: "2023-04-27",
    name: "Charlie Brown",
    location: "123 Main Street, City",
    price: "44.34",
    status: "44.34",
  },
  {
    id: 49,
    date: "2023-12-26",
    name: "Charlie Brown",
    location: "789 Pine Road, Village",
    price: "92.27",
    status: "92.27",
  },
  {
    id: 50,
    date: "2023-01-27",
    name: "Jane Smith",
    location: "789 Pine Road, Village",
    price: "55.57",
    status: "55.57",
  },
];
