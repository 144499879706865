import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Switch,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../api_url";
import axios from "axios";
import { DotLoaderOverlay } from "react-spinner-overlay";
import { useSelector } from "react-redux";
import { SnackbarProvider, enqueueSnackbar } from 'notistack'

const AdminProfile = () => {

    const token = useSelector((state) => state.user.token)
    const user = useSelector((state) => state.user.user)
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(null);
    const [profile, setProfile] = useState({
        name: user?.name,
        last_name: user?.last_name,
        email: user?.email,
    })
    const config = {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
        }
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('name', profile.name);
        formData.append('last_name', profile.last_name);
        formData.append('email', profile.email);
        formData.append('id', user.id);
        setLoading(true)
        try {
            const res = await axios.post(`${API_URL}/admin/profile`, formData, config)
            if (res.status === 200) {
                setLoading(false)
                enqueueSnackbar("Profile update successfully", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                })
                console.log(res);
            }
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    console.log(user);

    return (
        <div className="font-gotham">
            <SnackbarProvider />
            <DotLoaderOverlay loading={loading} color="red" />
            <div className="flex justify-between mb-5">
                <div>
                    <h1 className="text-xl font-bold">Profile</h1>
                </div>
            </div>

            <div className="font-gotham-light">
                <div className="bg-light-blue my-5 rounded-lg p-5">

                    <div className="bg-[#fff] p-3 rounded-lg">
                        <p>Nombre</p>
                        <input value={profile.name} onChange={(e) => setProfile({ ...profile, name: e.target.value })} type="text" className="w-full" placeholder="Nombre" />
                    </div>

                    <div className="bg-[#fff] p-3 rounded-lg mt-5">
                        <p>Tipo de banner</p>
                        <input value={profile.last_name} onChange={(e) => setProfile({ ...profile, last_name: e.target.value })} type="text" className="w-full" placeholder="Tipo" />
                    </div>

                    <div className="bg-[#fff] p-3 rounded-lg mt-5">
                        <p>Descripcion del banner</p>
                        <input value={profile.email} onChange={(e) => setProfile({ ...profile, email: e.target.value })} type="text" className="w-full" placeholder="Descripcion" />
                    </div>

                </div>
            </div>

            <div className="flex justify-end gap-5">
                <button className="bg-dark-grey text-[#fff] px-8 py-2 rounded-full ">
                    Cancelar
                </button>
                <button onClick={handleSubmit} className="bg-basic-red text-[#fff] px-8 py-2 rounded-full ">
                    Actualizar
                </button>
            </div>
        </div>
    );
};

export default AdminProfile;
