import React from "react";
import { AdminSidebar, ClientSidebar, VendorSidebar } from "./Sidebar";
import Navbar from "./Navbar";

export const AuthLayout = ({ children }) => {
  return (
    <div className="relative h-screen w-screen flex" style={{ overflowX: "hidden" }}>
      <img
        src={process.env.PUBLIC_URL + "/pngs/background.png"}
        alt="background"
        className="absolute w-screen h-screen z-[-1]"
      />

      {children}
    </div>
  );
};

export const AdminLayout = ({ children }) => {
  const user = "admin";
  return (
    <div className="relative h-screen w-screen flex overflow-hidden">
      <img
        src={process.env.PUBLIC_URL + "/pngs/background.png"}
        alt="background"
        className="absolute w-screen h-screen z-[-1]"
      />

      <AdminSidebar />
      <div className="w-full">
        <Navbar user={user} />
        <div className="p-10 overflow-auto h-screen pb-32">{children}</div>
      </div>
    </div>
  );
};

export const VendorLayout = ({ children }) => {
  const user = "vendor";
  return (
    <div className="relative h-screen w-screen flex overflow-hidden">
      <img
        src={process.env.PUBLIC_URL + "/pngs/background.png"}
        alt="background"
        className="absolute w-screen h-screen z-[-1]"
      />

      <VendorSidebar />
      <div className="w-full">
        <Navbar user={user} />
        <div className="p-10 overflow-auto h-screen pb-32">{children}</div>
      </div>
    </div>
  );
};

export const ClientLayout = ({ children }) => {
  const user = "client";
  return (
    <div className="relative h-screen w-screen flex overflow-hidden">
      <img
        src={process.env.PUBLIC_URL + "/pngs/background.png"}
        alt="background"
        className="absolute w-screen h-screen z-[-1]"
      />

      <ClientSidebar />
      <div className="w-full">
        <Navbar user={user} />
        <div className="p-10 overflow-auto h-screen pb-32">{children}</div>
      </div>
    </div>
  );
};
