import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TableHeader from "../layout/table/TableHead";
import { data } from "../../../assets/data/order_data";

import moment from "moment";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { Link, useNavigate } from "react-router-dom";
import { getComparator, stableSort } from "../../../utils/tableSort";
import TablePagination from "../layout/table/TablePagination";
import { MdOutlineCancel } from "react-icons/md";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_URL } from "../../../api_url";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { FormControl, InputLabel, Select } from "@mui/material";

const headCells = [
  {
    id: "id",
    label: "Repartidor ID",
  },
  {
    id: "name",
    label: "Nombre",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "location",
    label: "Ubicación",
  },
  {
    id: "phone",
    label: "Teléfono",
  },
  {
    id: "role",
    label: "Role",
  },
  {
    id: "",
    label: "",
  },
  {
    id: "",
    label: "",
  },
];

const DeleteRiderModal = ({ handleClose, handleDeleteRider }) => {
  return (
    <div className="fixed h-screen w-screen bg-black/30 top-0 left-0 z-20 flex pt-52 justify-center">
      <div className="relative w-[500px] h-[200px] bg-[white] text-center rounded-xl p-10">
        <button onClick={handleClose} className="absolute top-3 right-3">
          <MdOutlineCancel className="text-2xl text-basic-red" />
        </button>

        <p>
          <span>Desea eliminar permanentemente el banner</span>
          <br />
          <span className="text-basic-red font-semibold">
            NOMBRE DEL PRODUCTOS
          </span>
          ?
        </p>

        <div className="mt-10 flex gap-5 justify-center">
          <button
            onClick={handleClose}
            className="drop-shadow-lg px-8 py-2 bg-[white] rounded"
          >
            Cancelar
          </button>
          <button
            onClick={handleDeleteRider}
            className="drop-shadow-lg px-8 py-2 bg-basic-red rounded text-[white]"
          >
            Eliminar
          </button>
        </div>
      </div>
    </div>
  );
};

export default function DeliveryPerson({ riders, setLoading, orders }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [visibleRows, setVisibleRows] = useState([]);
  const [riderId, setRiderId] = useState(null);
  const [selectOrder, setSelectOrder] = useState(null);
  const [deleteRider, setDeleteRider] = useState(false);
  const [orderID, setOrderID] = useState(null);
  const token = useSelector((state) => state.user.token);

  const rowsPerPage = 5;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - riders?.length) : 0;

  useEffect(() => {
    const sortedRows = riders?.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });
    const slicedRows = sortedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    setVisibleRows(slicedRows);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riders, order, orderBy, page, rowsPerPage]);

  const handleDeleteRider = async () => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${API_URL}/admin/rider/delete/${riderId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 200) {
        setLoading(false);
        setDeleteRider(false);
        enqueueSnackbar("Rider Deleted successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      setDeleteRider(false);
      console.log(error);
    }
  };

  // const handleChangeOrderStatus = async () => {
  //   setLoading(true)
  //   try {
  //     const res = await axios.post(`${API_URL}/update-order-status`, {
  //       order_id: orderID,
  //       status: "out-for-delivery"
  //     }, {
  //       headers: {
  //         Authorization: "Bearer " + token
  //       }
  //     })
  //     if (res.status === 200) {
  //       setLoading(false)
  //       enqueueSnackbar("Order Status Change Successfully", {
  //         variant: "success",
  //         anchorOrigin: {
  //           vertical: 'top',
  //           horizontal: 'right'
  //         }
  //       })
  //       console.log(res);
  //     }
  //   } catch (error) {
  //     setLoading(false)
  //     console.log(error);
  //   }
  // }

  const navigate = useNavigate();

  const handleAssignOrder = async (orderId, driver_id) => {
    console.log(driver_id);
    setLoading(true);
    try {
      const res = await axios.post(
        `${API_URL}/admin/assign-order`,
        {
          order_id: orderId.id,
          driver_id: driver_id,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 200) {
        setLoading(false);
        enqueueSnackbar("Order Assigned successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setTimeout(() => {
          navigate("/");
        }, 1000);
        // handleChangeOrderStatus()
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  console.log(selectOrder);

  return (
    <Box sx={{ width: "100%" }}>
      <SnackbarProvider />
      {deleteRider && (
        <DeleteRiderModal
          handleClose={() => setDeleteRider(false)}
          handleDeleteRider={handleDeleteRider}
        />
      )}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer sx={{ borderRadius: 2 }} className="font-gotham-light">
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHeader
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={riders?.length}
            />
            <TableBody>
              {visibleRows.map((row, i) => {
                console.log("ROW HERE ===> ", { row });
                // Sort orders by date in descending order
                const sortedOrders = orders
                  .filter((order) => order.status === "out-for-delivery")
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  );
                // Get the newest order
                const newestOrder = sortedOrders[0];
                return (
                  <TableRow
                    key={i}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      cursor: "pointer",
                    }}
                    className="hover:bg-basic-red hover:font-semibold"
                  >
                    <TableCell scope="row">{row.id}</TableCell>
                    {/* <TableCell className="underline">
                      <Link to={"/order/" + row.id}>
                        {moment(row.start_date).format("DD MMMM YYYY, h:mm A")}
                      </Link>
                    </TableCell> */}
                    <TableCell>{row.name ?? ""}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.telephone}</TableCell>
                    <TableCell>{row.role}</TableCell>
                    <TableCell>
                      <LongMenu
                        row={row}
                        setRiderId={setRiderId}
                        setDeleteRider={setDeleteRider}
                      />
                    </TableCell>
                    {/* <TableCell>
                      <Select onChange={(e) => {
                        handleAssignOrder(e.target.value, row.id)
                        setOrderID(row.id)
                      }} style={{ width: 100 }} className="bg-light-blue">
                        {orders?.map((opt) => <MenuItem key={opt.id} value={opt}>Order Number #{opt.id}</MenuItem>)}
                      </Select>
                    </TableCell> */}
                    <TableCell>
                      <div
                        onClick={() => {
                          // Assign the newest order to the rider only if there is a newest order to assign
                          if (newestOrder) {
                            handleAssignOrder(newestOrder, row.id);
                          }
                        }}
                        className="flex items-center"
                      >
                        <p className="bg-dark-grey text-[white] px-8 py-3 rounded-full">
                          Asignar orden {newestOrder?.id}
                        </p>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          data={riders}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
        />
      </Paper>
    </Box>
  );
}

const ITEM_HEIGHT = 48;

function LongMenu({ row, setRiderId, setDeleteRider }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {/* <MenuItem onClick={handleClose}>
          <div className="flex items-center gap-2">
            <img
              className="h-5 w-5"
              src={process.env.PUBLIC_URL + "/svgs/check-circle.svg"}
              alt="check"
            />
            <p className="font-gotham-medium text-sm">Aceptar pedido</p>
          </div>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <div className="flex items-center gap-2">
            <img
              className="h-5 w-5"
              src={process.env.PUBLIC_URL + "/svgs/close.svg"}
              alt="check"
            />
            <p className="font-gotham-medium text-sm text-basic-red">
              Cancelar pedido
            </p>
          </div>
        </MenuItem> */}
        <MenuItem
          onClick={() =>
            navigate(`/edit-delivery-person/${row.id}`, { state: row })
          }
        >
          <div className="flex items-center gap-2">
            <p className="font-gotham-medium text-sm">Ver detaile</p>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() =>
            navigate(`/edit-delivery-person/${row.id}`, { state: row })
          }
        >
          <div className="flex items-center gap-2">
            <p className="font-gotham-medium text-sm">Editar</p>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRiderId(row.id);
            setDeleteRider(true);
          }}
        >
          <div className="flex items-center gap-2">
            <p className="font-gotham-medium text-sm">Eliminar</p>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}
