import React, { useState } from "react";

import OrderTable from "../../components/client/orders/OrderTable";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const ClientDashboard = () => {
  const [selected, setSelected] = useState(0);
  const [selected1, setSelected1] = useState(null);
  const [selected2, setSelected2] = useState(null);

  const handleChange1 = (e) => {
    setSelected1(e.target.value);
  };

  const handleChange2 = (e) => {
    setSelected2(e.target.value);
  };

  return (
    <div className="font-poppins">
      <div className="flex justify-between mb-5">
        <div>
          <h1 className="text-xl font-bold">Dashboard</h1>
          <p className="my-3">
            <span className="text-basic-red italic">Dashboard / </span>Pedido en
            curso
          </p>
        </div>
        <div className="flex gap-5">
          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2 text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/calendar.svg"}
                  alt="check"
                />
                Filtro por periodo
              </div>
            </InputLabel>
            <Select className="w-56 bg-light-blue">
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
              <MenuItem value={2}>Option 3</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="flex gap-5 mb-5">
        <button
          onClick={() => setSelected(2)}
          className={`bg-[#fff] flex items-center gap-5 rounded-xl p-5 drop-shadow ${
            selected === 2 ? "border-[1px] border-basic-red" : ""
          }`}
        >
          <div className="bg-white flex justify-center p-4 w-16 h-16 rounded-full">
            <img
              src={process.env.PUBLIC_URL + "/svgs/pedido-red.svg"}
              alt="total_banners"
            />
          </div>
          <div>
            <h1 className="text-3xl text-start text-basic-red font-bold">02</h1>
            <p className="my-2 text-sm text-medium-grey">Pedidos en curso</p>
          </div>
        </button>

        <button
          onClick={() => setSelected(3)}
          className={`bg-[#fff] flex items-center gap-5 rounded-xl p-5 drop-shadow ${
            selected === 3 ? "border-[1px] border-medium-grey" : ""
          }`}
        >
          <div className="bg-white flex justify-center p-4 w-16 h-16 rounded-full">
            <img
              src={process.env.PUBLIC_URL + "/svgs/pedido-grey.svg"}
              alt="total_banners"
            />
          </div>
          <div>
            <h1 className="text-3xl text-start font-bold">03</h1>
            <p className="my-2 text-sm text-medium-grey">Pedidos finalizados</p>
          </div>
        </button>
      </div>

      <>
        <div className="flex justify-between font-gotham">
          <div>
            <h1 className="text-xl font-bold">Pedidos</h1>
            <p className="text-medium-grey font-gotham-light my-3">
              Pedidos estatus
            </p>
          </div>

          <div className="flex gap-5">
            <FormControl>
              <InputLabel>
                <div className="flex items-center gap-2 text-[#000]">
                  <img
                    className="h-5 w-5"
                    src={process.env.PUBLIC_URL + "/svgs/thunder.svg"}
                    alt="check"
                  />
                  Todos los estados
                </div>
              </InputLabel>
              <Select
                value={selected1}
                onChange={handleChange1}
                className="w-56 bg-light-blue"
              >
                <MenuItem value={0}>Option 1</MenuItem>
                <MenuItem value={1}>Option 2</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel>
                <div className="flex items-center gap-2  text-[#000]">
                  <img
                    className="h-5 w-5"
                    src={process.env.PUBLIC_URL + "/svgs/hoy.svg"}
                    alt="check"
                  />
                  Hoy
                </div>
              </InputLabel>
              <Select
                value={selected2}
                onChange={handleChange2}
                className="w-56 bg-light-blue"
              >
                <MenuItem value={0}>Option 1</MenuItem>
                <MenuItem value={1}>Option 2</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </FormControl>
          </div>
        </div>
        <OrderTable />
      </>
    </div>
  );
};

export default ClientDashboard;
