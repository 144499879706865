const labels = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Ingresos",
      data: [
        500000, 600000, 700000, 800000, 750000, 600000, 550000, 700000, 800000,
        700000, 600000, 500000,
      ], // Replace with your static values
      borderColor: "#E52823",
      backgroundColor: "#E52823",
    },
    {
      label: "Gastos",
      data: [
        600000, 550000, 500000, 450000, 550000, 600000, 650000, 500000, 450000,
        500000, 550000, 600000,
      ], // Replace with your static values
      borderColor: "#646982",
      backgroundColor: "#646982",
    },
  ],
};

export const data2 = {
  labels,
  datasets: [
    {
      label: "Pedidos",
      data: labels.map(() => Math.floor(Math.random() * (1000000 - 1))),
      borderColor: "#E52823",
      backgroundColor: "#E52823",
    },
  ],
};

export const chartConfig = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false, // Hide verticle grid lines
      },
    },
    y: {
      min: 0,
      max: 1000000,
      ticks: {
        stepSize: 200000, // set the interval between points on the y-axis
      },
    },
  },
  elements: {
    line: {
      cubicInterpolationMode: "monotone", // makes the lines curvy
      fill: true,
    },
    point: {
      radius: 2.5, // resize point dots
    },
    area: {
      backgroundColor: "rgba(0, 123, 255, 0.4)", // Set the color for the area under the line
    },
  },
};
