import React from "react";
import { TfiTimer } from "react-icons/tfi";

const ClientInfo = ({ user }) => {
  return (
    <div className="w-[300px] bg-[#fff] rounded-xl drop-shadow flex flex-col gap-3 items-center py-5">
      <img
        alt="client"
        src="https://randomuser.me/api/portraits/men/75.jpg"
        className="w-44 h-44 rounded-full object-cover"
      />
      <h1 className="text-dark-grey text-xl">{`${user?.name || ""} ${
        user?.last_name || ""
      }`}</h1>
      <p className="font-gotham-light text-basic-red bg-light-blue px-3 py-1 rounded">
        Cliente
      </p>

      <div className="my-3 flex w-full bg-medium-grey text-[#fff] items-center rounded-2xl p-3">
        <img
          alt="bicycle"
          src={process.env.PUBLIC_URL + "/svgs/bicycle.svg"}
          className="mb-[-20px]"
        />

        <p>{user?.address}</p>
      </div>

      <div className="flex text-basic-red justify-center">
        <TfiTimer />
        <p className="text-basic-red ml-3">25 minutes</p>
      </div>

      {/* <div className="grid grid-row-3 grid-cols-3 gap-3 items-center text-xl">
        <div></div>
        <p className="text-light-grey">25 minutes</p>
        <div></div>

        <div className="justify-self-end text-basic-red">
          <TfiTimer />
        </div>
        <p className="text-basic-red">25</p>
        <div className="text-basic-red">minutes</div>

        <div></div>
        <p className="text-light-grey">07 : 29</p>
        <div className="text-light-grey">AM</div>
      </div> */}
    </div>
  );
};

export default ClientInfo;
