import React from "react";
import OrderChart from "../../components/admin/analytics/OrderChart";
import FavoriteArticles from "../../components/admin/analytics/FavoriteArticles";
import TrendingItems from "../../components/admin/analytics/TrendingItems";
import BestSellingItems from "../../components/admin/analytics/BestSellingItems";

const Analytics = () => {
  return (
    <div className="font-gotham">
      <div>
        <h1 className="text-xl font-bold">Analytics</h1>
        <p className="text-medium-grey my-3 font-gotham-light">
          Here is your restaurant summary with graph view
        </p>
      </div>

      <div className="grid grid-cols-2 gap-5">
        <div>
          <OrderChart />
          <FavoriteArticles />
        </div>
        <div>
          <TrendingItems />
          <BestSellingItems />
        </div>
      </div>
    </div>
  );
};

export default Analytics;
