import React, { useState, useEffect } from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import OrderTracker from "../../riderLiveLocation";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../api_url";
import { useSelector } from "react-redux";

const Tracking = ({ cancelOrder, orderId, orderDetails }) => {
  console.log({ orderDetails });
  const [open, setOpen] = useState(false);
  const [trackOrder, setTrackOrder] = useState(null);
  const [riderId, setRiderId] = useState(null);
  const [width, setWidth] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);

  async function trackMyOrder() {
    try {
      const response = await axios.get(`${API_URL}/orders-in-progress`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.status === 200) {
        console.log("ORDER TRACKER =====> ", response.data);
        setTrackOrder(
          response.data.find((order) => Number(orderId) === order.o_id)
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function trackStatus() {
    try {
      const response = await axios.get(`${API_URL}/my-orders`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.status === 200) {
        console.log(response.data);
        setRiderId(
          response.data.find((order) => Number(orderId) === order.id)?.driver_id
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      trackMyOrder();
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (trackOrder?.status === "out-for-delivery") {
      trackStatus();
    }
  }, [trackOrder]);

  console.log(trackOrder);

  return (
    <>
      <div className="w-full p-5 mt-5 bg-[#fff] rounded-xl drop-shadow">
        {/* <img
          src={process.env.PUBLIC_URL + "/pngs/order-map.png"}
          alt="tracking"
          className="my-5"
        /> */}

        <OrderTracker
          riderId={riderId}
          trackOrder={trackOrder}
          orderId={orderId}
          orderDetails={orderDetails}
        />

        {/* <button className="bg-basic-red px-10 py-2 rounded-full text-[white] font-gotham-light text-sm">
          Asignar delivery
        </button> */}
      </div>
      {/* <div className="mt-3 flex justify-end gap-3">
        <button
          onClick={cancelOrder}
          className="bg-dark-grey flex gap-1 items-center px-10 py-2 rounded-full text-[white] font-gotham-light text-sm"
        >
          <FaRegTrashCan /> Cancelar pedido
        </button>
        <button className="bg-basic-red px-10 py-2 rounded-full text-[white] font-gotham-light text-sm">
          Enviar pedido
        </button>
      </div> */}
    </>
  );
};

export default Tracking;
