import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTheme } from "@mui/system";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { API_URL } from "../../../api_url";
import axios from "axios";
import { useSelector } from "react-redux";

const drawerWidth = 300;

const vendor_data = [
  {
    name: "Dashboard",
    icon: "/dashboard.png",
    count: 0,
    chevron: false,
    route: "/",
  },
  {
    name: "Pedidos",
    icon: "/pedidos.png",
    count: 25,
    chevron: false,
    route: "/orders",
  },
  {
    name: "Productos",
    icon: "/productos.png",
    count: 0,
    chevron: false,
    route: "/products",
  },
  // {
  //   name: "Sucursales",
  //   icon: "/sucursales.png",
  //   count: 0,
  //   chevron: false,
  //   route: "/branches",
  // },
  {
    name: "Repartidores",
    icon: "/clientes.png",
    count: 0,
    chevron: true,
    route: "/dealers",
  },
];

const client_data = [
  {
    name: "Dashboard",
    icon: "/dashboard.png",
    count: 0,
    chevron: false,
    route: "/",
  },
  // {
  //   name: "Sucursales",
  //   icon: "/sucursales.png",
  //   count: 0,
  //   chevron: true,
  //   route: "/branches",
  // },
  {
    name: "Repartidores",
    icon: "/clientes.png",
    count: 0,
    chevron: true,
    route: "/dealers",
  },
];

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      backgroundColor: theme.palette.white,
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      backgroundColor: theme.palette.white,
    },
  }),
}));

export const AdminSidebar = () => {

  const token = useSelector((state) => state.user.token)
  const [orders, setOrders] = useState([])
  const config = {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    }
  };

  const fetchOrders = async () => {
    try {
      const res = await axios.get(`${API_URL}/admin/orders`, config)
      if (res.status === 200) {
        setOrders(res.data)
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchOrders()
  }, [])

  const admin_data = [
    {
      name: "Dashboard",
      icon: "/dashboard.png",
      count: 0,
      chevron: false,
      route: "/",
    },
    {
      name: "Pedidos",
      icon: "/pedidos.png",
      count: orders?.length,
      chevron: false,
      route: "/orders",
    },
    {
      name: "Productos",
      icon: "/productos.png",
      count: 0,
      chevron: false,
      route: "/products",
    },
    {
      name: "Categories",
      icon: "/productos.png",
      count: 0,
      chevron: false,
      route: "/categories",
    },
    // {
    //   name: "Historia del producto",
    //   icon: "/productos.png",
    //   count: 0,
    //   chevron: false,
    //   route: "/product-history",
    // },
    // {
    //   name: "distribuidora",
    //   icon: "/productos.png",
    //   count: 0,
    //   chevron: false,
    //   route: "/distributors",
    // },
    {
      name: "Repartidores",
      icon: "/clientes.png",
      count: 0,
      chevron: false,
      route: "/dealers",
    },
    {
      name: "Banners",
      icon: "/banners.png",
      count: 0,
      chevron: false,
      route: "/banners",
    },
    {
      name: "Clientes",
      icon: "/clientes.png",
      count: 0,
      chevron: true,
      action: () => setExpandClient(!expandClient),
    },

    {
      name: "Sucursales",
      icon: "/sucursales.png",
      count: 0,
      chevron: false,
      route: "/branches",
    },
    {
      name: "Analytics",
      icon: "/analytics.png",
      count: 0,
      chevron: false,
      route: "/analytics",
    },
  ];

  const [open, setOpen] = useState(true);
  const [active, setActive] = useState("Dashboard");
  const theme = useTheme(); // Get the current theme
  const [expandClient, setExpandClient] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Drawer variant="permanent" open={open} className="font-gotham bg-white">
      <DrawerHeader style={{ display: "flex", justifyContent: "space-between" }}>
        {open && (
          <img
            alt="logo"
            src={process.env.PUBLIC_URL + "/pngs/sidebar/logo.png"}
            className="w-[180px]"
          />
        )}
        <IconButton onClick={handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
      </DrawerHeader>
      <List>
        {admin_data.map((item, i) => (
          <>
            {item.chevron ? (
              <>
                <Link onClick={item.action}>
                  <ListItem
                    key={i}
                    disablePadding
                    sx={{
                      display: "block",
                      marginY: 1,
                      borderLeft: "5px solid",
                      backgroundColor:
                        active === item.name
                          ? theme.palette.lightGrey
                          : "transparent",
                      borderLeftColor:
                        active === item.name
                          ? theme.palette.darkGrey
                          : "transparent",
                    }}
                  >
                    <ListItemButton
                      onClick={() => setActive(item.name)}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <Box
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          alt={item.name}
                          src={
                            process.env.PUBLIC_URL +
                            "/pngs/sidebar/" +
                            item.icon
                          }
                        />
                      </Box>

                      {open && (
                        <div className="flex items-center justify-start gap-3 w-full">
                          <ListItemText
                            primary={item.name}
                            sx={{
                              color: theme.palette.darkGrey,
                              flex: "none",
                              fontStyle:
                                active === item.name ? "italic" : "normal",
                            }}
                          />

                          {item.count > 0 && (
                            <p className="bg-dark-grey text-white rounded-lg px-2 py-1 text-sm">
                              {item.count}
                            </p>
                          )}
                        </div>
                      )}
                      {item.chevron && open && (
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            justifyContent: "center",
                            opacity: 0.5,
                          }}
                        >
                          {expandClient ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <ChevronRightIcon />
                          )}
                        </ListItemIcon>
                      )}
                    </ListItemButton>
                  </ListItem>
                </Link>
                {expandClient && (
                  <>
                    <Link to="/clients">
                      <ListItem
                        key={i}
                        disablePadding
                        sx={{
                          display: "block",
                          marginY: 1,
                          borderLeft: "5px solid",
                          backgroundColor: "transparent",
                          borderLeftColor: "transparent",
                        }}
                      >
                        <ListItemButton
                          onClick={() => setActive(item.name)}
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          {open && (
                            <div className="flex items-center justify-start gap-3 w-full">
                              <ListItemText
                                primary={"Orders"}
                                sx={{
                                  color: theme.palette.darkGrey,
                                  flex: "none",
                                  fontStyle: "normal",
                                }}
                              />

                              {item.count > 0 && (
                                <p className="bg-dark-grey text-white rounded-lg px-2 py-1 text-sm">
                                  {item.count}
                                </p>
                              )}
                            </div>
                          )}
                        </ListItemButton>
                      </ListItem>
                    </Link>
                    <Link to="/comments">
                      <ListItem
                        key={i}
                        disablePadding
                        sx={{
                          display: "block",
                          marginY: 1,
                          borderLeft: "5px solid",
                          backgroundColor: "transparent",
                          borderLeftColor: "transparent",
                        }}
                      >
                        <ListItemButton
                          onClick={() => setActive(item.name)}
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          {open && (
                            <div className="flex items-center justify-start gap-3 w-full">
                              <ListItemText
                                primary={"Order comments"}
                                sx={{
                                  color: theme.palette.darkGrey,
                                  flex: "none",
                                  fontStyle: "normal",
                                }}
                              />

                              {item.count > 0 && (
                                <p className="bg-dark-grey text-white rounded-lg px-2 py-1 text-sm">
                                  {item.count}
                                </p>
                              )}
                            </div>
                          )}
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  </>
                )}
              </>
            ) : (
              <Link to={item.route}>
                <ListItem
                  key={i}
                  disablePadding
                  sx={{
                    display: "block",
                    marginY: 1,
                    borderLeft: "5px solid",
                    backgroundColor:
                      active === item.name
                        ? theme.palette.lightGrey
                        : "transparent",
                    borderLeftColor:
                      active === item.name
                        ? theme.palette.darkGrey
                        : "transparent",
                  }}
                >
                  <ListItemButton
                    onClick={() => setActive(item.name)}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        alt={item.name}
                        src={
                          process.env.PUBLIC_URL + "/pngs/sidebar/" + item.icon
                        }
                      />
                    </Box>

                    {open && (
                      <div className="flex items-center justify-start gap-3 w-full">
                        <ListItemText
                          primary={item.name}
                          sx={{
                            color: theme.palette.darkGrey,
                            flex: "none",
                            fontStyle:
                              active === item.name ? "italic" : "normal",
                          }}
                        />

                        {item.count > 0 && (
                          <p className="bg-dark-grey text-white rounded-lg px-2 py-1 text-sm">
                            {item.count}
                          </p>
                        )}
                      </div>
                    )}
                    {item.chevron && open && (
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          justifyContent: "center",
                          opacity: 0.5,
                        }}
                      >
                        <ChevronRightIcon />
                      </ListItemIcon>
                    )}
                  </ListItemButton>
                </ListItem>
              </Link>
            )}
          </>
        ))}
      </List>
      {open && (
        <>
          <Button
            sx={{
              textTransform: "capitalize",
            }}
          >
            <Link
              className="flex items-center bg-dark-grey m-1 py-2 w-full justify-center rounded-full text-white"
              to="/product/add"
            >
              <img
                className="mr-2"
                alt="logo"
                src={process.env.PUBLIC_URL + "/pngs/add-circle.png"}
              />
              Agregar Producto
            </Link>
          </Button>
          <Button
            sx={{
              textTransform: "capitalize",
            }}
          >
            <Link
              className="flex items-center bg-basic-red m-1 py-2 w-full justify-center rounded-full text-white"
              to="/banner/add"
            >
              <img
                className="mr-2"
                alt="logo"
                src={process.env.PUBLIC_URL + "/pngs/add-circle.png"}
              />
              Agregar banner
            </Link>
          </Button>
          <Button
            sx={{
              textTransform: "capitalize",
            }}
          >
            <Link
              className="flex items-center bg-basic-red m-1 py-2 w-full justify-center rounded-full text-white"
              to="/add-branch"
            >
              <img
                className="mr-2"
                alt="logo"
                src={process.env.PUBLIC_URL + "/pngs/add-circle.png"}
              />
              Agregar Sucursales
            </Link>
          </Button>
          <Button
            sx={{
              textTransform: "capitalize",
            }}
          >
            <Link
              className="flex items-center bg-basic-red m-1 py-2 w-full justify-center rounded-full text-white"
              to="/category"
            >
              <img
                className="mr-2"
                alt="logo"
                src={process.env.PUBLIC_URL + "/pngs/add-circle.png"}
              />
              Agregar Category
            </Link>
          </Button>
          <Button
            sx={{
              textTransform: "capitalize",
            }}
          >
            <Link
              className="flex items-center bg-basic-red m-1 py-2 w-full justify-center rounded-full text-white"
              to="/add-rider"
            >
              <img
                className="mr-2"
                alt="logo"
                src={process.env.PUBLIC_URL + "/pngs/add-circle.png"}
              />
              Agregar Rider
            </Link>
          </Button>
          {/* <Button
            sx={{
              textTransform: "capitalize",
            }}
          >
            <Link
              className="flex items-center bg-basic-red m-1 py-2 w-full justify-center rounded-full text-white"
              to="/add-distributor"
            >
              <img
                className="mr-2"
                alt="logo"
                src={process.env.PUBLIC_URL + "/pngs/add-circle.png"}
              />
              Agregar Distribuidora
            </Link>
          </Button>
          <Button
            sx={{
              textTransform: "capitalize",
            }}
          >
            <Link
              className="flex items-center bg-basic-red m-1 py-2 w-full justify-center rounded-full text-white"
              to="/add-delivery-person"
            >
              <img
                className="mr-2"
                alt="logo"
                src={process.env.PUBLIC_URL + "/pngs/add-circle.png"}
              />
              Agregar Repartidores
            </Link>
          </Button> */}

          <p className="text-[10px] text-center font-poppins">
            <span className="font-semibold">
              Pollo Victorina Administrador Dashboard
            </span>{" "}
            <br /> © 2023 Todos los Derechos Reservados
          </p>
        </>
      )}
    </Drawer>
  );
};

export const VendorSidebar = () => {
  const [open, setOpen] = useState(true);
  const [active, setActive] = useState("Dashboard");
  const theme = useTheme(); // Get the current theme

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Drawer variant="permanent" open={open} className="font-gotham bg-white">
      <DrawerHeader style={{ display: "flex", justifyContent: "space-between" }}>
        {open && (
          <img
            alt="logo"
            src={process.env.PUBLIC_URL + "/pngs/sidebar/logo.png"}
            className="w-[180px]"
          />
        )}
        <IconButton onClick={handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
      </DrawerHeader>
      <List>
        {vendor_data.map((item, i) => (
          <Link to={item.route}>
            <ListItem
              key={i}
              disablePadding
              sx={{
                display: "block",
                marginY: 1,
                borderLeft: "5px solid",
                backgroundColor:
                  active === item.name
                    ? theme.palette.lightGrey
                    : "transparent",
                borderLeftColor:
                  active === item.name ? theme.palette.darkGrey : "transparent",
              }}
            >
              <ListItemButton
                onClick={() => setActive(item.name)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <Box
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt={item.name}
                    src={process.env.PUBLIC_URL + "/pngs/sidebar/" + item.icon}
                  />
                </Box>

                {open && (
                  <div className="flex items-center justify-start gap-3 w-full">
                    <ListItemText
                      primary={item.name}
                      sx={{
                        color: theme.palette.darkGrey,
                        flex: "none",
                        fontStyle: active === item.name ? "italic" : "normal",
                      }}
                    />

                    {item.count > 0 && (
                      <p className="bg-dark-grey text-white rounded-lg px-2 py-1 text-sm">
                        {item.count}
                      </p>
                    )}
                  </div>
                )}
                {item.chevron && open && (
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: "center",
                      opacity: 0.5,
                    }}
                  >
                    <ChevronRightIcon />
                  </ListItemIcon>
                )}
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>

      {/* {open && (
        <>
          <Button
            sx={{
              textTransform: "capitalize",
            }}
          >
            <Link
              className="flex items-center bg-dark-grey m-1 py-2 w-full justify-center rounded-full text-white"
              to="/product/add"
            >
              <img
                className="mr-2"
                alt="logo"
                src={process.env.PUBLIC_URL + "/pngs/add-circle.png"}
              />
              Agregar Producto
            </Link>
          </Button>
          <Button
            sx={{
              textTransform: "capitalize",
            }}
          >
            <Link
              className="flex items-center bg-basic-red m-1 py-2 w-full justify-center rounded-full text-white"
              to="/banner/add"
            >
              <img
                className="mr-2"
                alt="logo"
                src={process.env.PUBLIC_URL + "/pngs/add-circle.png"}
              />
              Agregar banner
            </Link>
          </Button>
          
          <Button
            sx={{
              textTransform: "capitalize",
            }}
          >
            <Link
              className="flex items-center bg-basic-red m-1 py-2 w-full justify-center rounded-full text-white"
              to="/add-branch"
            >
              <img
                className="mr-2"
                alt="logo"
                src={process.env.PUBLIC_URL + "/pngs/add-circle.png"}
              />
              Agregar sucursales
            </Link>
          </Button>

          <p className="text-[10px] text-center font-poppins">
            <span className="font-semibold">
              Pollo Victorina Administrador Dashboard
            </span>{" "}
            <br /> © 2023 Todos los Derechos Reservados
          </p>
        </>
      )} */}
    </Drawer>
  );
};

export const ClientSidebar = () => {
  const [open, setOpen] = useState(true);
  const [active, setActive] = useState("Dashboard");
  const theme = useTheme(); // Get the current theme

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Drawer variant="permanent" open={open} className="font-gotham bg-white">
      <DrawerHeader style={{ display: "flex", justifyContent: "space-between" }}>
        {open && (
          <img
            alt="logo"
            src={process.env.PUBLIC_URL + "/pngs/sidebar/logo.png"}
            className="w-[180px]"
          />
        )}
        <IconButton onClick={handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
      </DrawerHeader>
      <List>
        {client_data.map((item, i) => (
          <Link to={item.route}>
            <ListItem
              key={i}
              disablePadding
              sx={{
                display: "block",
                marginY: 1,
                borderLeft: "5px solid",
                backgroundColor:
                  active === item.name
                    ? theme.palette.lightGrey
                    : "transparent",
                borderLeftColor:
                  active === item.name ? theme.palette.darkGrey : "transparent",
              }}
            >
              <ListItemButton
                onClick={() => setActive(item.name)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <Box
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt={item.name}
                    src={process.env.PUBLIC_URL + "/pngs/sidebar/" + item.icon}
                  />
                </Box>

                {open && (
                  <div className="flex items-center justify-start gap-3 w-full">
                    <ListItemText
                      primary={item.name}
                      sx={{
                        color: theme.palette.darkGrey,
                        flex: "none",
                        fontStyle: active === item.name ? "italic" : "normal",
                      }}
                    />

                    {item.count > 0 && (
                      <p className="bg-dark-grey text-white rounded-lg px-2 py-1 text-sm">
                        {item.count}
                      </p>
                    )}
                  </div>
                )}
                {item.chevron && open && (
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: "center",
                      opacity: 0.5,
                    }}
                  >
                    <ChevronRightIcon />
                  </ListItemIcon>
                )}
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>

      {open && (
        <>
          <Button
            sx={{
              textTransform: "capitalize",
            }}
          >
            <Link
              className="flex items-center bg-basic-red m-1 py-2 w-full justify-center rounded-full text-white"
              to="/orders"
            >
              Hacer pedido
            </Link>
          </Button>

          <Button
            sx={{
              textTransform: "capitalize",
            }}
          >
            <Link
              className="flex items-center bg-dark-grey m-1 py-2 w-full justify-center rounded-full text-white"
              to="/dealers"
            >
              Llamar repartidor
            </Link>
          </Button>

          <p className="text-[10px] text-center font-poppins">
            <span className="font-semibold">
              Pollo Victorina Administrador Dashboard
            </span>{" "}
            <br /> © 2023 Todos los Derechos Reservados
          </p>
        </>
      )}
    </Drawer>
  );
};
