import React, { useEffect, useState } from "react";
import OrderTable from "../../components/admin/orders/OrderTable";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DeliveryPerson from "../../components/admin/delivery-man/DeliveryPerson";
import axios from "axios";
import { API_URL } from "../../api_url";
import { useSelector } from "react-redux";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { DotLoaderOverlay } from "react-spinner-overlay";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import theme from "../../utils/theme";
import InputBase from "@mui/material/InputBase";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Search = styled("div")(({ theme }) => ({
  marginBottom: "10px",
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const DeliveryPersons = () => {
  const [loading, setLoading] = useState(null);
  const [riders, setRiders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selected1, setSelected1] = useState(null);
  const [selected2, setSelected2] = useState(null);
  const token = useSelector((state) => state.user.token);
  const [ridersToShow, setRidersToShow] = useState([]);

  const onSearchRiders = (query) => {
    if (!query) return setRidersToShow(riders);

    const lowerCaseQuery = query.toLowerCase();

    const filteredRiders = riders.filter((rider) => {
      return (
        rider.name.toLowerCase().includes(lowerCaseQuery) ||
        rider.last_name.toLowerCase().includes(lowerCaseQuery) ||
        rider.email.toLowerCase().includes(lowerCaseQuery)
      );
    });

    setRidersToShow(filteredRiders);
  };

  const handleChange1 = (e) => {
    setSelected1(e.target.value);
  };

  const handleChange2 = (e) => {
    setSelected2(e.target.value);
  };

  const getRiders = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${API_URL}/rider-list`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (res.status === 200) {
        setLoading(false);
        enqueueSnackbar("Riders fetch successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setRiders(res.data);
        setRidersToShow(res.data);
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const config = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  };

  const fetchOrders = async () => {
    try {
      const res = await axios.get(`${API_URL}/admin/orders`, config);
      if (res.status === 200) {
        setOrders(res.data);
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRiders();
    fetchOrders();
  }, []);

  console.log(riders);

  return (
    <div>
      <SnackbarProvider />
      <DotLoaderOverlay loading={loading} color="red" />
      <div className="flex justify-between font-gotham">
        <div>
          <h1 className="text-xl font-bold">Repartidores</h1>
          <p className="text-medium-grey my-3">Lista de repartidores</p>
        </div>

        <div className="flex gap-5">
          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2 text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/thunder.svg"}
                  alt="check"
                />
                Todos los estados
              </div>
            </InputLabel>
            <Select
              value={selected1}
              onChange={handleChange1}
              className="w-56 bg-light-blue"
            >
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2  text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/hoy.svg"}
                  alt="check"
                />
                Hoy
              </div>
            </InputLabel>
            <Select
              value={selected2}
              onChange={handleChange2}
              className="w-56 bg-light-blue"
            >
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>
        </div>
      </div>

      <Search
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <StyledInputBase
          onChange={(e) => onSearchRiders(e.target.value)}
          placeholder="Buscar"
          inputProps={{ "aria-label": "search" }}
          sx={{
            backgroundColor: theme.palette.lightBlue,
            border: "1px solid",
            borderColor: theme.palette.mediumGrey,
            borderRadius: 50,
            width: "500px",
          }}
        />

        <SearchIcon className="absolute top-2 right-4" />
      </Search>
      <div></div>

      <DeliveryPerson
        riders={ridersToShow}
        setLoading={setLoading}
        orders={orders}
      />
    </div>
  );
};

export default DeliveryPersons;
