import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../api_url";
import axios from "axios";
import { DotLoaderOverlay } from "react-spinner-overlay";
import { useSelector } from "react-redux";
import { SnackbarProvider, enqueueSnackbar } from 'notistack'

const AddBanner = () => {

  const token = useSelector((state) => state.user.token)
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [bannerForm, setBannerForm] = useState({
    title: "",
    web_image: null,
    mob_image: null,
    description: "",
    type: "",
    start_datetime: "",
    end_datetime: "",
    status: null,
  })
  const config = {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    }
  };
  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('title', bannerForm.title);
    formData.append('web_image', bannerForm.web_image);
    formData.append('mob_image', bannerForm.mob_image);
    formData.append('description', bannerForm.description);
    formData.append('type', bannerForm.type);
    formData.append('start_datetime', bannerForm.start_datetime);
    formData.append('end_datetime', bannerForm.end_datetime);
    formData.append('status', bannerForm.status);
    setLoading(true)
    try {
      const res = await axios.post(`${API_URL}/admin/banner/store`, formData, config)
      if (res.status === 200) {
        setLoading(false)
        enqueueSnackbar("Banner added successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        })
        console.log(res);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }

  console.log(bannerForm);

  return (
    <div className="font-gotham">
      <SnackbarProvider />
      <DotLoaderOverlay loading={loading} color="red" />
      <div className="flex justify-between mb-5">
        <div>
          <h1 className="text-xl font-bold">Dashboard</h1>
          <p className="my-3">
            <Link to="/banners" className="text-basic-red">
              Banners /
            </Link>{" "}
            <span className="text-medium-grey font-gotham-light">
              Agregar banners
            </span>
          </p>
        </div>

        <div className="flex gap-5">
          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2 text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/calendar.svg"}
                  alt="check"
                />
                Filtro por periodo
              </div>
            </InputLabel>
            <Select
              value={selected}
              onChange={handleChange}
              className="w-56 bg-light-blue"
            >
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
              <MenuItem value={2}>Option 3</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="flex gap-5">
        <div className="bg-[#fff] flex items-center gap-5 rounded-xl p-5 drop-shadow">
          <div className="bg-white flex justify-center p-4 w-16 h-16 rounded-full">
            <img
              src={process.env.PUBLIC_URL + "/svgs/album.svg"}
              alt="total_banners"
            />
          </div>
          <div>
            <h1 className="text-3xl font-bold">05</h1>
            <p className="my-2 text-sm text-medium-grey">Total banners</p>
          </div>
        </div>

        <div className="bg-[#fff] flex items-center gap-5 rounded-xl p-5 drop-shadow">
          <div className="bg-white flex justify-center p-4 w-16 h-16 rounded-full">
            <img
              src={process.env.PUBLIC_URL + "/svgs/album.svg"}
              alt="total_banners"
            />
          </div>
          <div>
            <h1 className="text-3xl font-bold">02</h1>
            <p className="my-2 text-sm text-medium-grey">Banners activos</p>
          </div>
        </div>

        <div className="bg-[#fff] flex items-center gap-5 rounded-xl p-5 drop-shadow">
          <div className="bg-white flex justify-center p-4 w-16 h-16 rounded-full">
            <img
              src={process.env.PUBLIC_URL + "/svgs/album.svg"}
              alt="total_banners"
            />
          </div>
          <div>
            <h1 className="text-3xl font-bold">03</h1>
            <p className="my-2 text-sm text-medium-grey">Banners inactivos</p>
          </div>
        </div>
      </div>

      <div className="font-gotham-light">
        <div className="bg-light-blue my-5 rounded-lg p-5">
          <label className="flex gap-5 p-3 border-dashed border-2 border-light-grey rounded-lg">
            <input type="file" className="hidden" />
            <img
              src={process.env.PUBLIC_URL + "/svgs/file-cloud.svg"}
              alt="upload"
            />
            <div className="flex-1">
              <p>Selecciona una imagen</p>
              <p>JPG, XLSX or PDF, file size no more than 10MB</p>
            </div>
            <input onChange={(e) => setBannerForm({ ...bannerForm, web_image: e.target.files[0] })} id="upload-btn-1" type="file" hidden />
            <label for="upload-btn-1" className="bg-basic-red text-[#fff] px-4 rounded-full cursor-pointer">
              Selecciona
            </label>
          </label>

          <label className="flex gap-5 p-3 border-dashed border-2 border-light-grey rounded-lg">
            <input type="file" className="hidden" />
            <img
              src={process.env.PUBLIC_URL + "/svgs/file-cloud.svg"}
              alt="upload"
            />
            <div className="flex-1">
              <p>Selecciona una imagen</p>
              <p>JPG, XLSX or PDF, file size no more than 10MB</p>
            </div>
            <input onChange={(e) => setBannerForm({ ...bannerForm, mob_image: e.target.files[0] })} id="upload-btn-2" type="file" hidden />
            <label for="upload-btn-2" className="bg-basic-red text-[#fff] px-4 rounded-full cursor-pointer">
              Selecciona
            </label>
          </label>

          <p className="my-5">CONFIGURACIÓN</p>

          <div className="bg-[#fff] p-3 rounded-lg">
            <p>Nombre del banner</p>
            <input onChange={(e) => setBannerForm({ ...bannerForm, title: e.target.value })} type="text" className="w-full" placeholder="Nombre" />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Tipo de banner</p>
            <input onChange={(e) => setBannerForm({ ...bannerForm, type: e.target.value })} type="text" className="w-full" placeholder="Tipo" />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Descripcion del banner</p>
            <input onChange={(e) => setBannerForm({ ...bannerForm, description: e.target.value })} type="text" className="w-full" placeholder="Descripcion" />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>fecha de inicio</p>
            <input onChange={(e) => setBannerForm({ ...bannerForm, start_datetime: e.target.value })} type="date" />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>fecha final</p>
            <input onChange={(e) => setBannerForm({ ...bannerForm, end_datetime: e.target.value })} type="date" />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>
              hora de entrada
            </p>
            <input type="time" />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>hora de salida

            </p>
            <input type="time" />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Notificacion</p>
            <div className="flex gap-2">
              <input type="checkbox" />
              <label>correo</label>
              <input type="checkbox" />
              <label>Telefono</label>
            </div>
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Status</p>
            <div className="flex gap-2 items-center">
              <Switch onChange={(e) => setBannerForm({ ...bannerForm, status: 1 })} />
              <label>Visible both</label>
              <Switch onChange={(e) => setBannerForm({ ...bannerForm, status: 2 })} />
              <label>Visible web</label>
              <Switch onChange={(e) => setBannerForm({ ...bannerForm, status: 3 })} />
              <label>Visible mobile</label>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-5">
        <button className="bg-dark-grey text-[#fff] px-8 py-2 rounded-full ">
          Cancelar
        </button>
        <button onClick={handleSubmit} className="bg-basic-red text-[#fff] px-8 py-2 rounded-full ">
          Aceptar
        </button>
      </div>
    </div>
  );
};

export default AddBanner;
