import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  DirectionsRenderer,
} from "@react-google-maps/api";
import axios from "axios";
import { useSelector } from "react-redux";
import { API_URL } from "../api_url";
import { Marker } from "@react-google-maps/api";

const MapWithDynamicRoute = ({ orderId, orderDetails }) => {
  console.log({ orderDetails });
  const token = useSelector((state) => state.user.token);

  const mapContainerStyle = {
    height: "400px",
    width: "100%",
  };

  const [origin, setOrigin] = useState({
    lat: parseFloat(orderDetails?.branch_address?.latitude),
    lng: parseFloat(orderDetails?.branch_address?.longitude),
  });

  const destination = {
    lat: parseFloat(orderDetails?.user_address?.latitude),
    lng: parseFloat(orderDetails?.user_address?.longitude),
  };

  const center = {
    lat: (origin.lat + destination.lat) / 2,
    lng: (origin.lng + destination.lng) / 2,
  };

  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyABy0de4oAU34qkNVvF4xiiVmvS9zdeiMY",
  });

  useEffect(() => {
    if (isLoaded) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: { lat: origin.lat, lng: origin.lng },
          destination: { lat: destination.lat, lng: destination.lng },
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirectionsResponse(result);
            setErrorMessage(null);
          } else if (
            status === window.google.maps.DirectionsStatus.ZERO_RESULTS
          ) {
            setErrorMessage(
              "No route could be found between the specified locations."
            );
          } else {
            console.error(`error fetching directions ${result}`);
            setErrorMessage("An error occurred while fetching directions.");
          }
        }
      );
    }
  }, [isLoaded, origin]);

  useEffect(() => {
    const fetchCurrentLocation = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/get-rider-current-location`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
            params: {
              order_id: orderId,
            },
          }
        );
        const { latitude: lat, longitude: lng } = response.data;
        setOrigin({
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        });
      } catch (error) {
        console.error("Error fetching current location:", error);
      }
    };

    fetchCurrentLocation();

    const interval = setInterval(fetchCurrentLocation, 10000); // Update every 10 seconds
    return () => clearInterval(interval);
  }, []);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <div>
      {errorMessage && <div>{errorMessage}</div>}
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={13}
      >
        {directionsResponse && (
          <DirectionsRenderer directions={directionsResponse} />
        )}
        <Marker position={{ lat: origin.lat, lng: origin.lng }} />
        <Marker
          position={{ lat: destination.lat, lng: destination.lng }}
          icon={{
            url: "https:play-lh.googleusercontent.com/N_cYs7RTtDrrUjbhmPHfSAHXXjt66WmmmcQ5x3Pmz-jL-EBMoNBZnaoyJvgLuIjo4w=w240-h480-rw",
            scaledSize: new window.google.maps.Size(40, 40),
          }}
        />
      </GoogleMap>
    </div>
  );
};

export default MapWithDynamicRoute;
