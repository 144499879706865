import React from "react";
import { FaStar } from "react-icons/fa6";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const ReviewCarousel = () => {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 3,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      partialVisibilityGutter: 30,
    },
  };
  return (
    <div>
      <Carousel
        additionalTransfrom={0}
        arrows
        centerMode={false}
        className="w-[1200px] mx-auto"
        containerClass="container"
        draggable
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {[1, 1, 1, 1, 1, 1, 1, 1].map((x, i) => (
          <div className="bg-[white] p-5 rounded-xl drop-shadow mx-3">
            <div className="flex gap-5">
              <img
                className="h-16 w-16 rounded-lg object-cover"
                src={process.env.PUBLIC_URL + "/pngs/placeholder.png"}
                alt="icon"
              />
              <h1 className="font-semibold">nombre del producto</h1>
            </div>
            <p className="py-5">
              Nisi deserunt id consequat duis est. Sint ad est in irure nostrud
              quis aliquip eu velit sunt ipsum.
            </p>
            <div className="bg-medium-grey flex gap-3 items-center rounded-xl p-3 text-white">
              <img
                className="h-10 w-10 rounded-full object-cover"
                src={process.env.PUBLIC_URL + "/pngs/placeholder.png"}
                alt="icon"
              />
              <div className="flex-1">
                <p className="font-bold">Roberto Jr.</p>
                <p className="text-sm text-light-grey">Head Marketing</p>
              </div>
              <FaStar className="text-basic-yellow text-xl" />
              <p className="text-xl">4.5</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ReviewCarousel;
