import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { DotLoaderOverlay } from "react-spinner-overlay";
import { API_URL } from "../../api_url";
import axios from "axios";
import { useSelector } from "react-redux";

const Product = () => {
  const token = useSelector((state) => state.user.token);
  const params = useParams();
  const location = useLocation();
  const { state: product } = location;
  console.log({ product });
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: product.name,
    image: product.image,
    description: product.description,
    discounted_price: product.discounted_price,
    retail_price: product.retail_price,
    // date: product.
  });

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const config = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("name", form?.name);
    formData.append("image", form?.image);
    formData.append("description", form?.description);
    formData.append("discounted_price", form?.discounted_price);
    formData.append("retail_price", form?.retail_price);
    formData.append("id", params?.id);
    setLoading(true);
    try {
      const res = await axios.post(
        `${API_URL}/admin/product/update`,
        formData,
        config
      );
      if (res.status === 200) {
        setLoading(false);
        enqueueSnackbar("Product updated successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  console.log(params);

  return (
    <div className="font-gotham">
      <SnackbarProvider />
      <DotLoaderOverlay loading={loading} color="red" />
      <div className="flex justify-between mb-5">
        <div>
          <h1 className="text-xl font-bold">Dashboard</h1>
          <p className="my-3">
            <Link to="/banners" className="text-basic-red">
              Productos /
            </Link>{" "}
            <span className="text-medium-grey font-gotham-light">
              Agregar productos
            </span>
          </p>
        </div>

        {/* <div className="flex gap-5">
          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2 text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/calendar.svg"}
                  alt="check"
                />
                Filtro por periodo
              </div>
            </InputLabel>
            <Select
              value={selected}
              onChange={handleChange}
              className="w-56 bg-light-blue"
            >
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
              <MenuItem value={2}>Option 3</MenuItem>
            </Select>
          </FormControl>
        </div> */}
      </div>

      {/* <div className="flex gap-5">
        <div className="bg-[#fff] flex items-center gap-5 rounded-xl p-5 drop-shadow">
          <div className="bg-white flex justify-center p-4 w-16 h-16 rounded-full">
            <img
              src={process.env.PUBLIC_URL + "/svgs/album.svg"}
              alt="total_banners"
            />
          </div>
          <div>
            <h1 className="text-3xl font-bold">93</h1>
            <p className="my-2 text-sm text-medium-grey">Total productos</p>
          </div>
        </div>
      </div> */}

      <div className="font-gotham-light">
        <div className="bg-light-blue my-5 rounded-lg p-5">
          <div>
            <img
              width={100}
              src={
                form?.image
                  ? form?.image
                  : process.env.PUBLIC_URL + "/svgs/file-cloud.svg"
              }
              alt="upload"
            />
          </div>

          <p className="my-5">CONFIGURACIÓN</p>

          <div className="bg-[#fff] p-3 rounded-lg">
            <p>Nombre del producto</p>
            <input
              style={{ backgroundColor: "white" }}
              disabled
              type="text"
              className="w-full bg-white"
              value={form?.name}
            />
          </div>
          {/* 
          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Tipo de producto</p>
            <input
              disabled
              value={form?.name}
              onChange={(e) => setForm({ ...form, name: e.target.value })}
              type="text"
              className="w-full"
              placeholder="Tipo"
            />
          </div> */}

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Descripcion del producto</p>
            <input
              style={{ backgroundColor: "white" }}
              disabled
              value={form?.description}
              onChange={(e) =>
                setForm({ ...form, description: e.target.value })
              }
              type="text"
              className="w-full bg-white"
            />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>precio al por menor</p>
            <input
              style={{ backgroundColor: "white" }}
              disabled
              value={form?.retail_price}
              onChange={(e) =>
                setForm({ ...form, retail_price: Number(e.target.value) })
              }
              type="text"
              className="w-full bg-white"
            />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Precio descontado</p>
            <input
              style={{ backgroundColor: "white" }}
              disabled
              value={form?.discounted_price}
              onChange={(e) =>
                setForm({ ...form, discounted_price: Number(e.target.value) })
              }
              type="text"
              className="w-full bg-white"
            />
          </div>

          {/* <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Fecha</p>
            <input disabled type="date" />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Notificacion</p>
            <div className="flex gap-2">
              <input disabled type="checkbox" />
              <label>Papas</label>
              <input disabled type="checkbox" />
              <label>Refresco</label>
              <input disabled type="checkbox" />
              <label>Arepitas</label>
            </div>
          </div> */}

          {/* <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Sucursal ID#</p>
            <input disabled type="text" className="w-full" placeholder="ID" />
          </div> */}

          {/* <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Status</p>
            <div className="flex gap-2 items-center">
              <Switch />
              <label>Visible</label>
            </div>
          </div> */}
        </div>
      </div>

      {/* <div className="flex justify-end gap-5">
        <button className="bg-dark-grey text-[#fff] px-8 py-2 rounded-full ">
          Cancelar
        </button>
        <button
          onClick={handleSubmit}
          className="bg-basic-red text-[#fff] px-8 py-2 rounded-full "
        >
          Aceptar
        </button>
      </div> */}
    </div>
  );
};

export default Product;
