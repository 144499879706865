import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const steps = [
  {
    label: "Pedido completado",
    description: "-",
  },
  {
    label: "Pedido entregado",
    description: "Sat, 23 Jul 2020, 01:24 PM",
  },
  {
    label: "Pedido realizada",
    description: "Fri, 22 Jul 2020, 10:44 AM",
  },
  {
    label: "Pedido aceptada",
    description: "Thu, 21 Jul 2020, 11:49 AM",
  },
];

const OrderSteps = () => {
  return (
    <div className="w-[250px] bg-[#fff] rounded-xl drop-shadow p-5 mt-5">
      <h1 className="text-dark-grey text-xl mb-5">Historial</h1>
      <Box sx={{ maxWidth: 400 }}>
        <Stepper activeStep={0} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                <p>{step.label} </p>
                <p className="text-xs">{step.description}</p>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
};

export default OrderSteps;

function QontoStepIcon(props) {
  const { active } = props;

  return (
    <>
      {active ? (
        <div className="h-6 w-6 bg-white rounded-full"></div>
      ) : (
        <div className="h-6 w-6 bg-basic-red rounded-full"></div>
      )}
    </>
  );
}
