import React from "react";

const OrderCancelled = () => {
  return (
    <div className="relative w-full">
      <img
        className="fixed top-[70px] left-[40px] z-0 object-cover"
        src={process.env.PUBLIC_URL + "/svgs/cancel-bg.svg"}
        alt="backgound"
      />

      <div className="relative w-full mt-20">
        <img
          src={process.env.PUBLIC_URL + "/svgs/cancel-box.svg"}
          alt="backgound"
          className="w-[300px] mx-auto"
        />
        <h1 className="mx-auto text-basic-red text-center text-3xl font-gotham mt-10">
          ¡Se ha cancelado el pedido!
        </h1>
      </div>
    </div>
  );
};

export default OrderCancelled;
