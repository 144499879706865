import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FaRegTrashAlt } from "react-icons/fa";
import { LuImagePlus } from "react-icons/lu";
import { FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import axios from "axios";
import { useSelector } from "react-redux";
import { API_URL } from "../../api_url";
import { DotLoaderOverlay } from "react-spinner-overlay";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

const Banners = () => {

  const navigate = useNavigate()
  const token = useSelector((state) => state.user.token)
  const [loading, setLoading] = useState(false);
  const [bannerId, setBannerId] = useState(null);
  const [selected, setSelected] = useState(null);
  const [hideBanner, setHideBanner] = useState(false);
  const [deleteBanner, setDeleteBanner] = useState(false);
  const [banners, setBanners] = useState([]);

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const handleGetBanners = async () => {
    setLoading(true)
    try {
      const res = await axios.get(`${API_URL}/admin/banner`, {
        headers: {
          Authorization: "Bearer " + token
        }
      })
      if (res.status === 200) {
        setLoading(false)
        enqueueSnackbar("Banners fetch successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        })
        setBanners(res.data)
        console.log(res);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }

  const handleDeleteBanner = async () => {
    setLoading(true)
    try {
      const res = await axios.delete(`${API_URL}/admin/banner/delete/${bannerId}`, {
        headers: {
          Authorization: "Bearer " + token
        }
      })
      if (res.status === 200) {
        setLoading(false)
        setDeleteBanner(false)
        enqueueSnackbar("Banners Deleted successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        })
        handleGetBanners()
        console.log(res);
      }
    } catch (error) {
      setLoading(false)
      setDeleteBanner(false)
      console.log(error);
    }
  }

  useEffect(() => {
    handleGetBanners()
  }, [])

  return (
    <div>
      <SnackbarProvider />
      <DotLoaderOverlay loading={loading} color="red" />
      <div className="flex justify-between mb-5">
        <div>
          <h1 className="text-xl font-bold">Dashboard</h1>
          <p className="my-3">
            <Link to="/banners" className="text-basic-red">
              Banners /
            </Link>{" "}
          </p>
        </div>

        <div className="flex gap-5">
          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2 text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/calendar.svg"}
                  alt="check"
                />
                Filtro por periodo
              </div>
            </InputLabel>
            <Select
              value={selected}
              onChange={handleChange}
              className="w-56 bg-light-blue"
            >
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
              <MenuItem value={2}>Option 3</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="flex gap-5">
        <div className="bg-[#fff] flex items-center gap-5 rounded-xl p-5 drop-shadow">
          <div className="bg-white flex justify-center p-4 w-16 h-16 rounded-full">
            <img
              src={process.env.PUBLIC_URL + "/svgs/album.svg"}
              alt="total_banners"
            />
          </div>
          <div>
            <h1 className="text-3xl font-bold">05</h1>
            <p className="my-2 text-sm text-medium-grey">Total banners</p>
          </div>
        </div>

        <div className="bg-[#fff] flex items-center gap-5 rounded-xl p-5 drop-shadow">
          <div className="bg-white flex justify-center p-4 w-16 h-16 rounded-full">
            <img
              src={process.env.PUBLIC_URL + "/svgs/album.svg"}
              alt="total_banners"
            />
          </div>
          <div>
            <h1 className="text-3xl font-bold">02</h1>
            <p className="my-2 text-sm text-medium-grey">Banners activos</p>
          </div>
        </div>

        <div className="bg-[#fff] flex items-center gap-5 rounded-xl p-5 drop-shadow">
          <div className="bg-white flex justify-center p-4 w-16 h-16 rounded-full">
            <img
              src={process.env.PUBLIC_URL + "/svgs/album.svg"}
              alt="total_banners"
            />
          </div>
          <div>
            <h1 className="text-3xl font-bold">03</h1>
            <p className="my-2 text-sm text-medium-grey">Banners inactivos</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-5 mt-10">
        {banners?.map((banner) => (
          <div key={banner.id}>
            <p>BANNER {banner.title}</p>
            <div className="flex gap-2">
              <img
                onClick={() => navigate(`/banner/${banner.id}`)}
                src={banner.web_image + "/pngs/banner.png"}
                alt="banner"
                className="w-[500px]"
              />
              <div className="flex flex-col gap-2">
                <button
                  onClick={() => {
                    setDeleteBanner(true)
                    setBannerId(banner.id)
                  }}
                  className="p-3 text-xl text-basic-red drop-shadow-lg bg-[white] rounded"
                >
                  <FaRegTrashAlt />
                </button>
                <button className="p-3 text-xl flex-1 drop-shadow-lg bg-[white] rounded">
                  <LuImagePlus />
                </button>
                <button
                  onClick={() => setHideBanner(true)}
                  className="p-3 text-xl drop-shadow-lg bg-[white] rounded"
                >
                  <FaRegEyeSlash />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {deleteBanner && <DeleteBanner handleClose={() => setDeleteBanner(false)} handleDeleteBanner={handleDeleteBanner} />}
      {hideBanner && <HideBanner handleClose={() => setHideBanner(false)} />}
    </div>
  );
};

export default Banners;

const DeleteBanner = ({ handleClose, handleDeleteBanner }) => {
  return (
    <div className="fixed h-screen w-screen bg-black/30 top-0 left-0 z-20 flex pt-52 justify-center">
      <div className="relative w-[500px] h-[200px] bg-[white] text-center rounded-xl p-10">
        <button onClick={handleClose} className="absolute top-3 right-3">
          <MdOutlineCancel className="text-2xl text-basic-red" />
        </button>

        <p>
          <span>Desea eliminar permanentemente el banner</span>
          <br />
          <span className="text-basic-red font-semibold">
            NOMBRE DEL BANNER
          </span>
          ?
        </p>

        <div className="mt-10 flex gap-5 justify-center">
          <button
            onClick={handleClose}
            className="drop-shadow-lg px-8 py-2 bg-[white] rounded"
          >
            Cancelar
          </button>
          <button onClick={handleDeleteBanner} className="drop-shadow-lg px-8 py-2 bg-basic-red rounded text-[white]">
            Eliminar
          </button>
        </div>
      </div>
    </div>
  );
};

const HideBanner = ({ handleClose }) => {
  return (
    <div className="fixed h-screen w-screen bg-black/30 top-0 left-0 z-20 flex pt-52 justify-center">
      <div className="relative w-[500px] h-[200px] bg-[white] text-center rounded-xl p-10">
        <button onClick={handleClose} className="absolute top-3 right-3">
          <MdOutlineCancel className="text-2xl text-basic-red" />
        </button>

        <p>
          <span>Desea ocultar el banner </span>
          <br />
          <span className="text-basic-red font-semibold">
            NOMBRE DEL BANNER
          </span>
          ?
        </p>

        <div className="mt-10 flex gap-5 justify-center">
          <button
            onClick={handleClose}
            className="drop-shadow-lg px-8 py-2 bg-[white] rounded"
          >
            Cancelar
          </button>
          <button className="drop-shadow-lg px-8 py-2 bg-basic-red rounded text-[white]">
            Eliminar
          </button>
        </div>
      </div>
    </div>
  );
};
