import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setToken, setUser } from "../../store/auth";
import { DotLoaderOverlay } from 'react-spinner-overlay'
import { API_URL } from '../../api_url'
import axios from "axios";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

const Login = () => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("tester12345@test.com");
  const [password, setPassword] = useState("test213");
  const dispatch = useDispatch();

  const handleTogglePassword = () => setShowPassword(!showPassword);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      return;
    }
    setLoading(true)
    try {
      const res = await axios.post(`${API_URL}/auth/login`, { email, password })
      if (res.status === 200) {
        setLoading(false)
        dispatch(setToken(res.data.token))
        dispatch(setUser(res.data.user))
        console.log("login response ===>", res);
      }
    } catch (error) {
      setLoading(false)
      enqueueSnackbar(error?.response?.data?.message ? error?.response?.data?.message : error?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      })
      console.log("login error ===>", error);
    }
  };

  return (
    <div className="relative font-gotham-light z-30 h-screen w-screen flex items-center justify-center">
      <SnackbarProvider />
      <DotLoaderOverlay loading={loading} color="red" />
      <form
        onSubmit={handleSubmit}
        className="w-[600px] bg-[#fff] rounded-lg drop-shadow-lg flex gap-5 flex-col items-center p-20"
      >
        <img
          alt="logo"
          className="w-32"
          src={process.env.PUBLIC_URL + "/svgs/logo.svg"}
        />
        <h1 className="text-basic-red my-1 font-gotham text-[24px]">INICIO</h1>

        <div className="relative w-full">
          <img
            src={process.env.PUBLIC_URL + "/svgs/user-circle.svg"}
            alt="user"
            className="absolute top-[13px] left-5"
          />
          <input
            className="bg-light-blue px-14 py-3 rounded-full w-full "
            placeholder="Usuario*"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="relative w-full">
          <button
            type="button"
            onClick={handleTogglePassword}
            className={`absolute top-[15px] right-5 ${showPassword && "brightness-110"
              }`}
          >
            <img src={process.env.PUBLIC_URL + "/svgs/eye.svg"} alt="user" />
          </button>
          <input
            className="bg-light-blue px-7 py-3 rounded-full w-full "
            placeholder="Contraseña*"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <button
          type="submit"
          className="font-gotham text-[#fff] bg-basic-red w-full py-3 rounded-full"
        >
          Ingresar
        </button>

        <Link to="/forgot-password" className="text-medium-grey">
          ¿Has olvidado tu contraseña?
        </Link>
        <Link to="/signup" className="text-medium-grey">
          Crear cuenta nueva
        </Link>
      </form>
    </div>
  );
};

export default Login;
