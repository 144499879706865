import React from "react";
import { FaStar } from "react-icons/fa6";

const ArticlesList = () => {
  return (
    <div>
      <table className="bg-[#fff] drop-shadow w-full rounded-xl">
        <thead>
          <tr className="text-left border-b-2 border-b-white">
            <th className="p-5">Articulos</th>
            <th className="p-5">Cant.</th>
            <th className="p-5">Precio</th>
            <th className="p-5">Precio total</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {[
            "https://www.teenaagnel.com/wp-content/uploads/2019/12/food-photography-in-dubai.jpg",
            "https://images.pexels.com/photos/376464/pexels-photo-376464.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            "https://img.freepik.com/free-photo/macaroni-noodles-with-meat-tomato-sauce-served-plate-table_1220-6904.jpg",
          ].map((x, i) => (
            <tr key={i}>
              <td className="p-5">
                <div className="flex gap-3">
                  <img
                    alt="client"
                    src={x}
                    className="w-14 h-14 rounded-xl object-cover"
                  />

                  <div>
                    <p className="text-sm font-gotham-medium text-basic-red">
                      POLLO FRITO
                    </p>
                    <p className="text-sm font-gotham-medium">
                      Nombre del articulo
                    </p>

                    <div className="flex mt-4 items-center text-sm">
                      <FaStar className="text-basic-red" />
                      <FaStar className="text-basic-red" />
                      <FaStar className="text-basic-red" />
                      <FaStar className="text-basic-red" />
                      <FaStar className="text-light-grey" />

                      <p className="text-medium-grey font-gotham-light ml-3 ">
                        (454 Comentarios)
                      </p>
                    </div>
                  </div>
                </div>
              </td>
              <td className="p-5">3x</td>
              <td className="p-5">$14.99</td>
              <td className="p-5">$44.97</td>
              <td className="p-5">
                <img
                  className="h-5"
                  src={process.env.PUBLIC_URL + "/svgs/close.svg"}
                  alt="user"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ArticlesList;
