import React, { useEffect, useState } from "react";
import StatItem from "./StatItem";
import { useSelector } from "react-redux";
import { API_URL } from "../../../api_url";
import axios from "axios";

const StatList = ({ setProducts, products, setLoading }) => {

  const token = useSelector((state) => state.user.token)

  const config = {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    }
  };

  const fetchProducts = async () => {
    setLoading(true)
    try {
      const res = await axios.get(`${API_URL}/admin/product`, config)
      if (res.status === 200) {
        setLoading(false)
        setProducts(res.data)
        console.log(res);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }

  useEffect(() => {
    fetchProducts()
  }, [])

  const data = [
    {
      icon: process.env.PUBLIC_URL + "/svgs/total_productos.svg",
      count: products?.length,
      title: "Total productos",
      bottomText: "4% (30 dias)",
    },
    {
      icon: process.env.PUBLIC_URL + "/svgs/total_ingresos.svg",
      count: "126k",
      title: "Total Ingresos",
      bottomText: "26% (30 días)",
    },
    {
      icon: process.env.PUBLIC_URL + "/svgs/total_pedidos.svg",
      count: 279,
      title: "Total pedidos",
      bottomText: "4% (30 dias)",
    },
    {
      icon: process.env.PUBLIC_URL + "/svgs/total_clientes.svg",
      count: 65,
      title: "Total clientes",
      bottomText: "4% (30 dias)",
    },
  ];

  return (
    <div className="grid grid-cols-4 gap-5">
      {data.map((x, i) => (
        <StatItem stat={x} key={i} />
      ))}
    </div>
  );
};

export default StatList;
