import React from "react";
import { FaStar } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";

const ReviewList = () => {
  return (
    <>
      {" "}
      {[1, 1, 1, 1].map((x, i) => (
        <div key={i} className="flex gap-5 mt-10">
          <img
            src={process.env.PUBLIC_URL + "/pngs/placeholder.png"}
            alt="user"
            className="w-14 h-14 object-cover rounded-full"
          />
          <div>
            <h1 className="font-semibold">James Kowalski</h1>
            <p className="flex items-center gap-3 text-xs mt-2 text-medium-grey">
              Head Marketing <GoDotFill /> 24 June 2020
            </p>
            <p className="mt-5 text-sm text-medium-grey">
              In tempor dolor do esse et eu dolor ut id proident commodo. Lorem
              eu Lorem ipsum est. Exercitation in sunt fugiat enim aliqua sunt.
              Sint cillum proident sint mollit pariatur proident culpa excepteur
              qui exercitation Lorem eiusmod. Magna ea sunt anim eu consequat do
              consectetur nulla aliquip nisi id reprehenderit excepteur. Sit
              culpa sint reprehenderit cupidatat enim.
            </p>
          </div>
          <div className="ml-5">
            <p className="text-4xl font-bold text-center my-5">3.5</p>
            <span className="flex gap-1">
              <FaStar className="text-basic-red" />
              <FaStar className="text-basic-red" />
              <FaStar className="text-basic-red" />
              <FaStar className="text-basic-red" />
              <FaStar className="text-medium-grey" />
            </span>
          </div>
        </div>
      ))}
    </>
  );
};

export default ReviewList;
