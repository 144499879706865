import React from "react";
import Header from "./Header";

const TrendingItems = () => {
  return (
    <div className="bg-[#fff] rounded-xl p-5 drop-shadow">
      <Header />

      <div className="mt-5">
        {[1, 2, 3, 4, 5].map((x, i) => (
          <div key={i} className="flex items-center gap-3 mt-5">
            <h1 className="text-medium-grey">#{x}</h1>
            <div className="flex-1 flex items-center gap-2">
              <img
                className="h-16 w-16 rounded-lg object-cover"
                src={process.env.PUBLIC_URL + "/pngs/placeholder.png"}
                alt="item"
              />
              <div className="flex flex-col gap-5">
                <p className="font-gotham-medium">Nombre del producto</p>
                <p className="text-xs">
                  $12.56 <span className="text-basic-red">POSTRE</span>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <img
                className="w-10"
                src={process.env.PUBLIC_URL + "/svgs/graph-up.svg"} //use graph-up when sales are increased and graph-down otherwise
                alt="item"
              />
              <div>
                <p>524</p>
                <p className="font-gotham-light text-xs whitespace-nowrap">
                  Sales (12%)
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingItems;
