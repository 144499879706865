import React, { useState } from "react";
import { Link } from "react-router-dom";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import ClientInfo from "../../components/admin/order/ClientInfo";
import OrderSteps from "../../components/admin/order/OrderSteps";
import ArticlesList from "../../components/admin/order/ArticlesList";
import Tracking from "../../components/admin/order/Tracking";
import CancelOrder from "../../components/admin/order/CancelOrder";
import Chat from "../../components/admin/order/Chat";

const Distributor = () => {
    // rename the states according to real values
    const [selected, setSelected] = useState(null);
    const [showCancel, setShowCancel] = useState(false);
    const [showChat, setShowChat] = useState(false);

    const handleChange = (e) => {
        setSelected(e.target.value);
    };

    return (
        <>
            <div className="font-gotham">
                <div className="flex justify-between mb-5">
                    <div>
                        <h1 className="text-xl font-bold">Distributor ID #123</h1>
                        <p className="my-3">
                            <Link to="/orders" className="text-basic-red">
                                Distributor /
                            </Link>{" "}
                            <span className="text-medium-grey font-gotham-light">
                                Detalles del distributor
                            </span>
                        </p>
                    </div>
                    <div className="flex gap-5">
                        <FormControl>
                            <InputLabel>
                                <div className="flex items-center gap-2 text-[#000]">
                                    <img
                                        className="h-5 w-5"
                                        src={process.env.PUBLIC_URL + "/svgs/thunder.svg"}
                                        alt="check"
                                    />
                                    EN ENTREGA
                                </div>
                            </InputLabel>
                            <Select
                                value={selected}
                                onChange={handleChange}
                                className="w-56 bg-light-blue"
                            >
                                <MenuItem value={0}>Option 1</MenuItem>
                                <MenuItem value={1}>Option 2</MenuItem>
                                <MenuItem value={2}>Option 3</MenuItem>
                                {/* Add more options as needed */}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>

            {showCancel && <CancelOrder handleClose={() => setShowCancel(false)} />}
            {showChat && <Chat handleClose={() => setShowChat(false)} />}
        </>
    );
};

export default Distributor;
