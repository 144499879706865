import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../../api_url";
import { useSelector, useDispatch } from "react-redux";
import { DotLoaderOverlay } from "react-spinner-overlay";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { setCategoryToEdit } from "../../../store/category";

const DEFAULT_CATEGORY = {
  name: "",
  image: null,
};

const Category = () => {
  const token = useSelector((state) => state.user.token);
  const navigate = useNavigate();
  const categoryToEdit = useSelector((state) => state.category.categoryToEdit);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [categoryForm, setcategoryForm] = useState(
    categoryToEdit ?? DEFAULT_CATEGORY
  );
  const config = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  };

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const handleEditCategory = async () => {
    const formData = new FormData();
    formData.append("name", categoryForm.name);
    formData.append("id", categoryToEdit.id);

    if (typeof categoryForm.image !== "string")
      formData.append("image", categoryForm.image);

    setLoading(true);
    try {
      const res = await axios.post(
        `${API_URL}/admin/category/update`,
        formData,
        config
      );
      if (res.status === 200) {
        setLoading(false);
        enqueueSnackbar("Category updated successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setcategoryForm(DEFAULT_CATEGORY);
        dispatch(setCategoryToEdit(null));
        navigate("/categories");
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    if (!categoryForm.name || !categoryForm.image) {
      enqueueSnackbar("Please add all the details", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    if (categoryToEdit) handleEditCategory();
    const formData = new FormData();
    formData.append("name", categoryForm.name);
    formData.append("image", categoryForm.image);
    setLoading(true);
    try {
      const res = await axios.post(
        `${API_URL}/admin/category/store`,
        formData,
        config
      );
      if (res.status === 200) {
        setLoading(false);
        enqueueSnackbar("Category added successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setcategoryForm(DEFAULT_CATEGORY);
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      console.log(error);
    }
  };

  return (
    <div className="font-gotham">
      <SnackbarProvider />
      <DotLoaderOverlay loading={loading} color="red" />
      <div className="flex justify-between mb-5">
        <div>
          <h1 className="text-xl font-bold">Dashboard</h1>
          <p className="my-3">
            <Link to="/banners" className="text-basic-red">
              categoryos /
            </Link>{" "}
            <span className="text-medium-grey font-gotham-light">
              Agregar categoryos
            </span>
          </p>
        </div>

        <div className="flex gap-5">
          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2 text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/calendar.svg"}
                  alt="check"
                />
                Filtro por periodo
              </div>
            </InputLabel>
            <Select
              value={selected}
              onChange={handleChange}
              className="w-56 bg-light-blue"
            >
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
              <MenuItem value={2}>Option 3</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="flex gap-5">
        <div className="bg-[#fff] flex items-center gap-5 rounded-xl p-5 drop-shadow">
          <div className="bg-white flex justify-center p-4 w-16 h-16 rounded-full">
            <img
              src={process.env.PUBLIC_URL + "/svgs/album.svg"}
              alt="total_banners"
            />
          </div>
          <div>
            <h1 className="text-3xl font-bold">93</h1>
            <p className="my-2 text-sm text-medium-grey">Total categories</p>
          </div>
        </div>
      </div>

      <div className="font-gotham-light">
        <div className="bg-light-blue my-5 rounded-lg p-5">
          <label className="flex items-center gap-5 p-3 border-dashed border-2 border-light-grey rounded-lg">
            <input type="file" className="hidden" />
            <img
              width={60}
              src={process.env.PUBLIC_URL + "/svgs/file-cloud.svg"}
              alt="upload"
            />
            <div className="flex-1">
              <p>Selecciona una imagen</p>
              <p>JPG, XLSX or PDF, file size no more than 10MB</p>
            </div>
            <input
              onChange={(e) => {
                console.log({ categoryForm });
                setcategoryForm({ ...categoryForm, image: e.target.files[0] });
              }}
              id="upload-btn"
              type="file"
              hidden
            />
            <label
              for="upload-btn"
              className="bg-basic-red text-[#fff] px-4 rounded-full cursor-pointer flex justify-center items-center"
              style={{ height: "50px" }}
            >
              Selecciona
            </label>
          </label>

          <p className="my-5">CONFIGURACIÓN</p>

          <div className="bg-[#fff] p-3 rounded-lg">
            <p>Nombre del category</p>
            <input
              value={categoryForm.name}
              type="text"
              className="w-full"
              placeholder="Nombre"
              onChange={(e) =>
                setcategoryForm({ ...categoryForm, name: e.target.value })
              }
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-5">
        <button className="bg-dark-grey text-[#fff] px-8 py-2 rounded-full ">
          Cancelar
        </button>
        <button
          onClick={handleSubmit}
          className="bg-basic-red text-[#fff] px-8 py-2 rounded-full "
        >
          Aceptar
        </button>
      </div>
    </div>
  );
};

export default Category;
