// import { configureStore } from "@reduxjs/toolkit";
// import authSlice from "./auth";
// import commonSlice from "./common";

// export const store = configureStore({
//     reducer: {
//         common: commonSlice,
//         auth: authSlice,
//     }
// })

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth";
import commonSlice from "./common";
import categorySlice from "./category";
import branchSlice from "./branch";
import storage from "redux-persist/lib/storage";
import productSlice from "./product";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const allreducers = combineReducers({
  common: commonSlice,
  user: authSlice,
  category: categorySlice,
  branch: branchSlice,
  product: productSlice,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["common", "user", "category"],
};

const persistedReducer = persistReducer(persistConfig, allreducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
