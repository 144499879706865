import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TableHeader from "../layout/table/TableHead";
import { data } from "../../../assets/data/order_data";

import moment from "moment";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { Link, useNavigate } from "react-router-dom";
import { getComparator, stableSort } from "../../../utils/tableSort";
import TablePagination from "../layout/table/TablePagination";
import { Select } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { API_URL } from "../../../api_url";
import axios from "axios";
import { useSelector } from "react-redux";
import { MdOutlineCancel } from "react-icons/md";

const headCells = [
  {
    id: "id",
    label: "Pedido ID",
  },
  {
    id: "date",
    label: "Fetcha",
  },
  {
    id: "order_type",
    label: "Tipo de orden",
  },
  {
    id: "payment_type",
    label: "Pago de orden",
  },
  {
    id: "price",
    label: "Precio",
  },
  {
    id: "status",
    label: "Estado",
  },
  {
    id: "",
    label: "Cambiar estado",
  },
];

const DeleteOrderModal = ({ handleClose, handleDeleteOrder }) => {
  return (
    <div className="fixed h-screen w-screen bg-black/30 top-0 left-0 z-20 flex pt-52 justify-center">
      <div className="relative w-[500px] h-[200px] bg-[white] text-center rounded-xl p-10">
        <button onClick={handleClose} className="absolute top-3 right-3">
          <MdOutlineCancel className="text-2xl text-basic-red" />
        </button>

        <p>
          <span>Desea eliminar permanentemente el banner</span>
          <br />
          <span className="text-basic-red font-semibold">
            NOMBRE DEL PRODUCTOS
          </span>
          ?
        </p>

        <div className="mt-10 flex gap-5 justify-center">
          <button
            onClick={handleClose}
            className="drop-shadow-lg px-8 py-2 bg-[white] rounded"
          >
            Cancelar
          </button>
          <button onClick={handleDeleteOrder} className="drop-shadow-lg px-8 py-2 bg-basic-red rounded text-[white]">
            Eliminar
          </button>
        </div>
      </div>
    </div>
  );
};

export default function OrderTable({ orders, setOrders, setLoading, fetchOrders }) {

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [orderId, setOrderId] = useState(null);
  const [deleteOrder, setDeleteOrder] = useState(false);
  const [visibleRows, setVisibleRows] = useState([]);
  const token = useSelector((state) => state.user.token)
  const navigate = useNavigate()

  const config = {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    }
  };

  const rowsPerPage = 5;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders?.length) : 0;

  useEffect(() => {
    const sortedRows = orders?.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });
    // const slicedRows = sortedRows?.slice(
    //   page * rowsPerPage,
    //   page * rowsPerPage + rowsPerPage
    // );
    setVisibleRows(sortedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, order, orderBy, page, rowsPerPage]);

  const handleDeleteOrder = async (oId) => {
    setLoading(true)
    try {
      const res = await axios.delete(`${API_URL}/admin/orders/delete/${oId}`, {
        headers: {
          Authorization: "Bearer " + token
        }
      })
      if (res.status === 200) {
        setLoading(false)
        setDeleteOrder(false)
        enqueueSnackbar("Order Deleted successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        })
        fetchOrders()
        console.log(res);
      }
    } catch (error) {
      setLoading(false)
      setDeleteOrder(false)
      console.log(error);
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      {deleteOrder && <DeleteOrderModal handleClose={() => setDeleteOrder(false)} handleDeleteOrder={handleDeleteOrder} />}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <TableContainer sx={{ borderRadius: 2 }} className="font-gotham-light">
            <Table aria-labelledby="tableTitle">
              <TableHeader
                headCells={headCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={orders?.length}
              />
              <TableBody>
                {visibleRows?.map((row, i) => {
                  return (
                    <TableRow
                      key={i}
                      hover
                      role="checkbox"
                      // tabIndex={-1}
                      sx={{ cursor: "pointer" }}
                      className="hover:bg-basic-red hover:font-semibold"
                    >
                      <TableCell scope="row">{row.id}</TableCell>
                      <TableCell className="underline">
                        <Link to={"/order/" + row.id}>
                          {moment(row.date).format("DD MMMM YYYY, h:mm A")}
                        </Link>
                      </TableCell>
                      <TableCell>{row.order_type}</TableCell>
                      <TableCell>{row.payment_type}</TableCell>
                      <TableCell>$ {row.total}</TableCell>
                      <TableCell>
                        <p
                          className={`w-50 text-center py-3 rounded-lg font-gotham-medium 
                          ${row.status === "cancelled" ? "bg-white text-basic-red" :
                              row.status === "in-progress" ? "bg-[#2BC155]/10 text-[#2BC155]" :
                                row.status === "out-for-delivery" ? "bg-[#2BC155]/10 text-[#2BC155]" :
                                  row.status === "delivered" ? "bg-[#2BC155]/10 text-[#2BC155]" :
                                    row.status === "preparing" ? "bg-light-grey text-dark-grey" : ""
                            }`}
                        >
                          {row.status}
                        </p>
                      </TableCell>
                      {/* <TableCell> */}
                      {/* <Select
                          value={selectedOrder ? selectedOrder : row.status}
                          onChange={(e) => {
                            setOrders((prevOrders) => {
                              return prevOrders?.map((odr, index) => {
                                if (index === i) {
                                  handleChangeOrderStatus(odr, e.target.value)
                                  return {
                                    ...odr,
                                    status: e.target.value
                                  }
                                }
                                else {
                                  return odr
                                }
                              })
                            })
                          }}
                          className="w-40 bg-light-blue"
                        >
                          <MenuItem value="preparing">Preparing</MenuItem>
                          <MenuItem value="in-progress">In-progress</MenuItem>
                          <MenuItem value="out-for-delivery">Out-for-delivery</MenuItem>
                          <MenuItem value="delivered">Delivered</MenuItem>
                        </Select>
                      </TableCell> */}
                      <TableCell>
                        <LongMenu row={row} setOrderId={setOrderId} setDeleteOrder={setDeleteOrder} />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <TablePagination
          data={orders}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
        />
      </Paper>
    </Box>
  );
}

const ITEM_HEIGHT = 48;

function LongMenu({ row, setOrderId, setDeleteOrder }) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate()

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {/* <MenuItem onClick={handleClose}>
          <div className="flex items-center gap-2">
            <img
              className="h-5 w-5"
              src={process.env.PUBLIC_URL + "/svgs/check-circle.svg"}
              alt="check"
            />
            <p className="font-gotham-medium text-sm">Aceptar pedido</p>
          </div>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <div className="flex items-center gap-2">
            <img
              className="h-5 w-5"
              src={process.env.PUBLIC_URL + "/svgs/close.svg"}
              alt="check"
            />
            <p className="font-gotham-medium text-sm text-basic-red">
              Cancelar pedido
            </p>
          </div>
        </MenuItem> */}
        <MenuItem onClick={() => navigate(`/order/${row.id}`, { state: row })}>
          <div className="flex items-center gap-2">
            <p className="font-gotham-medium text-sm">
              Ver detaile
            </p>
          </div>
        </MenuItem>
        <MenuItem onClick={() => {
          setOrderId(row.id)
          setDeleteOrder(true)
          handleClose()
        }}>
          <div className="flex items-center gap-2">
            <p className="font-gotham-medium text-sm">
              Eliminar
            </p>
          </div>
        </MenuItem>
        {/* <MenuItem onClick={() => {
          navigate(`/order-history/${row.id}`)
        }}>
          <div className="flex items-center gap-2">
            <p className="font-gotham-medium text-sm">
              Historia del producto
            </p>
          </div>
        </MenuItem> */}
      </Menu>
    </div>
  );
}
