import React, { useState } from "react";
import StatList from "../../components/admin/dashboard/StatList";
import Chart1 from "../../components/admin/dashboard/Chart1";
import Chart2 from "../../components/admin/dashboard/Chart2";
import Chart3 from "../../components/admin/dashboard/Chart3";
import { DotLoaderOverlay } from "react-spinner-overlay";

const Dashboard = () => {

  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false); 

  return (
    <div className="font-poppins">
      <DotLoaderOverlay loading={loading} color="red" />
      <h1 className="text-xl font-bold">Dashboard</h1>
      <p className="text-medium-grey my-3">Bienvenido!</p>

      <StatList products={products} setProducts={setProducts} setLoading={setLoading} />

      <div className="my-5 grid grid-cols-2 gap-5">
        <Chart1 />
        <Chart2 />
      </div>

      <Chart3 />
    </div>
  );
};

export default Dashboard;
