import React, { useEffect, useState } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import axios from "axios";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { DotLoaderOverlay } from "react-spinner-overlay";
import { useSelector } from "react-redux";
import { API_URL } from "../../../api_url";
import CategoryTable from "./CategoryTable";

const rowsPerPage = 5;

const Categories = () => {
  const [selected1, setSelected1] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.token);
  const categories = useSelector((state) => state.category.categories);
  const categoriesLoader = useSelector(
    (state) => state.category.loaders.fetchingCategories
  );

  const handleChange1 = (e) => {
    setSelected1(e.target.value);
  };

  const config = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  };

  return (
    <div>
      <SnackbarProvider />
      <DotLoaderOverlay loading={loading || categoriesLoader} color="red" />
      <div className="flex justify-between font-gotham">
        <div>
          <h1 className="text-xl font-bold">Categories</h1>
          <p className="font-gotham-light text-medium-grey my-3">
            Aquí están los datos generales de su lista de Categories
          </p>
        </div>
        <div className="flex gap-5">
          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2 text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/filter.svg"}
                  alt="check"
                />
                Filtro
              </div>
            </InputLabel>
            {/* <Select
              value={selected1}
              onChange={handleChange1}
              className="w-56 bg-light-blue"
            >
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
            </Select> */}
          </FormControl>
        </div>
      </div>
      <CategoryTable categories={categories} setLoading={setLoading} />
    </div>
  );
};

export default Categories;
