import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHeader from "../../admin/layout/table/TableHead";
import { data } from "../../../assets/data/order_data";

import moment from "moment";

import { Link } from "react-router-dom";
import { getComparator, stableSort } from "../../../utils/tableSort";
import TablePagination from "../../admin/layout/table/TablePagination";

const headCells = [
  {
    id: "id",
    label: "Repartidor ID",
  },
  {
    id: "date",
    label: "Inicio de labor",
  },
  {
    id: "name",
    label: "Nombre del repartidor",
  },
  {
    id: "price",
    label: "Pedido en curso",
  },
  {
    id: "total",
    label: "Total ordenes",
  },
  {
    id: "status",
    label: "Estado de la orden",
  },
];

export default function DealerTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [visibleRows, setVisibleRows] = useState([]);

  const rowsPerPage = 5;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  useEffect(() => {
    const sortedRows = stableSort(data, getComparator(order, orderBy));
    const slicedRows = sortedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    setVisibleRows(slicedRows);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, order, orderBy, page, rowsPerPage]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer sx={{ borderRadius: 2 }} className="font-gotham-light">
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHeader
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {visibleRows.map((row, i) => {
                return (
                  <TableRow
                    key={i}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      cursor: "pointer",
                    }}
                    className="hover:bg-basic-red hover:font-semibold"
                  >
                    <TableCell scope="row">{row.id}</TableCell>
                    <TableCell className="underline">
                      <Link>
                        {moment(row.date).format("DD MMMM YYYY, h:mm A")}
                      </Link>
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.location}</TableCell>
                    <TableCell>${i + 1}0.00</TableCell>
                    <TableCell>
                      <div className="flex items-center">
                        <p
                          className={`w-28 text-center py-1 rounded-lg font-gotham-medium ${
                            row.status === "En entrega"
                              ? "bg-white text-basic-red"
                              : row.status === "Enviado"
                              ? "bg-[#2BC155]/10 text-[#2BC155]"
                              : row.status === "Nueva orden"
                              ? "bg-light-grey text-dark-grey"
                              : ""
                          }`}
                        >
                          {row.status}
                        </p>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          data={data}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
        />
      </Paper>
    </Box>
  );
}
