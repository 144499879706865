import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { chartConfig, data } from "../../../../assets/data/bar_chart_data";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Chart = () => {
  return (
    <div>
      <Bar options={chartConfig} data={data} height={50} />
    </div>
  );
};

export default Chart;
