export const data = {
  data: [
    {
      label: "En entrega",
      value: 25,
    },
    {
      label: "Enviados",
      value: 60,
    },
    {
      label: "Cancelados",
      value: 15,
    },
  ],
  colors: ["#646982", "#32343E", "#E52823"],
};
