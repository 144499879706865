import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
    name: "common",
    initialState: {
        loading: false,
    },
    reducers: {
        setLoading: (state, action) => {
            return {
                ...state,
                loading: action.payload
            }
        },
    }
})

export const { setLoading } = commonSlice.actions
export default commonSlice.reducer