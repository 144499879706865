import React from "react";
import { SnackbarProvider } from "notistack";
import { DotLoaderOverlay } from "react-spinner-overlay";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { API_URL } from "../../../api_url";
import axios from "axios";
import { Select, MenuItem } from "@mui/material";

const DEFAULT_RIDER = {
  first_name: "",
  last_name: "",
  email: "",
  idCard: "",
  phone: "",
  vehicleDetails: "",
  startTime: "",
  departureTime: "",
  startDate: "",
  branch_id: "",
  category: "",
  email: "",
  password: "",
};

const AddRider = () => {
  const [data, setData] = React.useState(DEFAULT_RIDER);
  const [loading, setLoading] = React.useState(false);
  const [branches, setBranches] = React.useState([]);
  const token = useSelector((state) => state.user.token);

  const config = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  };

  const handleChange = (e, field) => {
    const dataCopy = { ...data };
    dataCopy[field] = e.target.value;
    setData(dataCopy);
  };

  const getBranches = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${API_URL}/admin/branch`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      setBranches(res.data);
      console.log(res.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    console.log({ data });
    try {
      const res = await axios.post(
        `${API_URL}/admin/rider/store`,
        data,
        config
      );
      if (res.status === 200) {
        setLoading(false);
        enqueueSnackbar("Rider added successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  React.useEffect(() => {
    getBranches();
  }, []);

  return (
    <div className="font-gotham">
      <SnackbarProvider />
      <DotLoaderOverlay loading={loading} color="red" />

      <div className="font-gotham-light">
        <div className="bg-light-blue my-5 rounded-lg p-5">
          <div className="bg-[#fff] p-3 rounded-lg">
            <p>First Name</p>
            <input
              type="text"
              className="w-full"
              placeholder="Nombre"
              onChange={(e) => handleChange(e, "first_name")}
            />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Last Name</p>
            <input
              type="text"
              className="w-full"
              placeholder="Nombre"
              onChange={(e) => handleChange(e, "last_name")}
            />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Email</p>
            <input
              type="text"
              className="w-full"
              placeholder="Nombre"
              onChange={(e) => handleChange(e, "email")}
            />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>Password</p>
            <input
              type="text"
              className="w-full"
              placeholder="Nombre"
              onChange={(e) => handleChange(e, "password")}
            />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg mt-5">
            <p>ID card</p>
            <input
              type="text"
              className="w-full"
              placeholder="ID card"
              onChange={(e) => handleChange(e, "idCard")}
            />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg  mt-5">
            <p>Phone</p>
            <input
              type="text"
              className="w-full"
              placeholder="Phone"
              onChange={(e) => handleChange(e, "phone")}
            />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg  mt-5">
            <p>Model and make of the vehicle (engine)</p>
            <input
              type="text"
              className="w-full"
              placeholder="Vehicle Details"
              onChange={(e) => handleChange(e, "vehicleDetails")}
            />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg  mt-5">
            <p>Start time of work</p>
            <input
              type="time"
              className="w-full"
              placeholder="Start Time"
              onChange={(e) => handleChange(e, "startTime")}
            />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg  mt-5">
            <p>Departure time</p>
            <input
              type="time"
              className="w-full"
              placeholder="Departure Time"
              onChange={(e) => handleChange(e, "departureTime")}
            />
          </div>

          <div className="bg-[#fff] p-3 rounded-lg  mt-5">
            <p>Start date</p>
            <input
              type="date"
              className="w-full"
              placeholder="Start Date"
              onChange={(e) => {
                console.log(e.target.value);
                handleChange(e, "startDate");
              }}
            />
          </div>

          <div className="pt-2 mt-5 bg-[#fff] rounded-md">
            <p className="ml-3">Branch</p>
            <Select
              value={data.branch}
              onChange={(e) => {
                handleChange(e, "branch_id");
              }}
              className="w-full bg-white rouned-md"
              sx={{
                backgroundColor: "white !important",
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
            >
              {branches.map((item) => {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              })}
            </Select>
          </div>

          <div className="bg-[#fff] p-3 rounded-lg  mt-5">
            <p>Category</p>
            <input
              type="text"
              className="w-full"
              placeholder="Category"
              onChange={(e) => handleChange(e, "category")}
            />
          </div>
        </div>

        <div className="flex justify-end gap-5">
          <button className="bg-dark-grey text-[#fff] px-8 py-2 rounded-full ">
            Cancelar
          </button>
          <button
            onClick={handleSubmit}
            className="bg-basic-red text-[#fff] px-8 py-2 rounded-full "
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddRider;
