import React from "react";

import OrderTable from "../../components/client/orders/OrderTable";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const ClientBranch = () => {
  return (
    <div className="font-poppins">
      <div className="flex justify-between mb-5">
        <div>
          <h1 className="text-xl font-bold">Nombre de Sucursal</h1>
          <p className="my-3">
            <span className="text-basic-red italic">Sucursales / </span>
            ID#C-005640
          </p>
        </div>
        <div className="flex gap-5">
          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2 text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/calendar.svg"}
                  alt="check"
                />
                Filtro
              </div>
            </InputLabel>
            <Select className="w-56 bg-light-blue">
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
              <MenuItem value={2}>Option 3</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>
        </div>
      </div>

      <OrderTable />
    </div>
  );
};

export default ClientBranch;
