import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/");
  };

  return (
    <div className="relative font-gotham-light z-30 h-screen w-screen flex items-center justify-center">
      <form
        onSubmit={handleSubmit}
        className="w-[600px] bg-[#fff] rounded-lg drop-shadow-lg flex gap-5 flex-col items-center p-20"
      >
        <Link to="/login" className="absolute top-5 right-5">
          <img
            className="h-8"
            src={process.env.PUBLIC_URL + "/svgs/close.svg"}
            alt="user"
          />
        </Link>

        <img
          alt="logo"
          className="w-32"
          src={process.env.PUBLIC_URL + "/svgs/logo.svg"}
        />
        <h1 className="my-1 font-gotham-light text-[20px] text-medium-grey">
          ¿Has olvidado tu contraseña?
        </h1>

        <div className="relative w-full">
          <button className="absolute top-[15px] left-5">
            <img src={process.env.PUBLIC_URL + "/svgs/mail.svg"} alt="user" />
          </button>
          <input
            className="bg-light-blue px-14 py-3 rounded-full w-full"
            placeholder="Correo electronico*"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <button className="flex justify-center items-center gap-1 font-gotham text-[#fff] bg-basic-red w-full py-3 rounded-full">
          <img src={process.env.PUBLIC_URL + "/svgs/lock.svg"} alt="user" />{" "}
          Restablecer contraseña
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;
