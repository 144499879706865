import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { AdminRouter, AuthRoutes, ClientRouter, VendorRouter } from "./router";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loading from "./components/admin/layout/Loading";
import {
  AdminLayout,
  AuthLayout,
  ClientLayout,
  VendorLayout,
} from "./components/admin/layout/index";
import { useSelector, useDispatch } from "react-redux";
import Login from "./pages/admin/Login";
import { setCategories, setLoader } from "./store/category";
import axios from "axios";
import { API_URL } from "./api_url";
import { enqueueSnackbar } from "notistack";

const App = () => {
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  console.log(token);
  console.log(user);
  const config = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  };

  const fetchCategories = async () => {
    dispatch(setLoader({ loader: "fetchingCategories", value: true }));
    try {
      const res = await axios.get(`${API_URL}/admin/category`, config);
      if (res.status === 200) {
        dispatch(setLoader({ loader: "fetchingCategories", value: false }));
        enqueueSnackbar("Categories fetched successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        if (res.data.length) {
          dispatch(setCategories(res.data));
        } else dispatch(setCategories([]));
      }
    } catch (error) {
      dispatch(setLoader({ loader: "fetchingCategories", value: false }));
      console.log(error);
    }
  };

  React.useEffect(() => fetchCategories(), []);

  return (
    <>
      {token === null && (
        <AuthLayout>
          {/* contains all the routes */}
          <AuthRoutes />
          {/* setup your condition for loading, you can also use this component in other pages */}
          <Loading isLoading={false} />
          {/* initialized toast message component here, check the doc for implementation */}
          <ToastContainer
            pauseOnFocusLoss={false}
            autoClose={1500}
            theme="light"
            closeOnClick
          />
        </AuthLayout>
      )}
      {token && user?.role === "admin" ? (
        <AdminLayout>
          {/* contains all the routes */}
          <AdminRouter />
          {/* setup your condition for loading, you can also use this component in other pages */}
          <Loading isLoading={false} />
          {/* initialized toast message component here, check the doc for implementation */}
          <ToastContainer
            pauseOnFocusLoss={false}
            autoClose={1500}
            theme="light"
            closeOnClick
          />
        </AdminLayout>
      ) : null}
      {token && user?.role === "vendor" ? (
        <VendorLayout>
          {/* contains all the routes */}
          <VendorRouter />
          {/* setup your condition for loading, you can also use this component in other pages */}
          <Loading isLoading={false} />
          {/* initialized toast message component here, check the doc for implementation */}
          <ToastContainer
            pauseOnFocusLoss={false}
            autoClose={1500}
            theme="light"
            closeOnClick
          />
        </VendorLayout>
      ) : null}
      {token && user?.role === "client" ? (
        <ClientLayout>
          {/* contains all the routes */}
          <ClientRouter />
          {/* setup your condition for loading, you can also use this component in other pages */}
          <Loading isLoading={false} />
          {/* initialized toast message component here, check the doc for implementation */}
          <ToastContainer
            pauseOnFocusLoss={false}
            autoClose={1500}
            theme="light"
            closeOnClick
          />
        </ClientLayout>
      ) : null}
    </>
  );
};

export default App;
