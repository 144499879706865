import React, { useEffect, useRef } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { BsSend } from "react-icons/bs";

const Chat = ({ handleClose }) => {
  const ref = useRef(null);

  // this will keep scrolling the chat div to the bottom
  // add chat state in dependency array to will keep scrolling as chat continues
  useEffect(() => {
    ref.current.scrollTop = ref.current.scrollHeight;
  }, []);

  return (
    <div className="fixed h-screen w-screen bg-black/30 top-0 left-0 z-20 flex pt-10 justify-center">
      <div className="relative w-[500px] max-h-[80vh]  bg-[white] relative rounded-lg p-5">
        <img
          className="absolute top-0 left-0 z-0"
          src={process.env.PUBLIC_URL + "/svgs/cancel-popup.svg"}
          alt="backgound"
        />
        <button onClick={handleClose} className="absolute top-3 right-3 z-50">
          <MdOutlineCancel className="text-2xl text-basic-red" />
        </button>
        <div className="font-gotham-medium relative z-10">
          <h1 className="text-xl">Juan Miguel</h1>

          <div ref={ref} className="my-8 h-[400px] overflow-auto hide-scroll">
            <MessageReceived />
            <MessageSent />
            <MessageSent />
            <MessageReceived />
            <MessageSent />
          </div>

          <div className="flex gap-3 items-center py-3 px-5 rounded-full border-[1px] border-basic-red bg-light-blue">
            <input
              placeholder="Escribe algo"
              className="bg-transparent flex-1"
            />
            <BsSend className="text-basic-red" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;

const MessageReceived = () => {
  return (
    <div className="flex gap-2 mt-10">
      <img
        className="w-10 h-10 object-cover rounded-full border-[1px] border-basic-red"
        src={process.env.PUBLIC_URL + "/pngs/placeholder.png"}
        alt="user"
      />
      <div className="relative">
        <p className="absolute text-medium-grey mb-1 text-xs top-[-20px] right-0">
          8:10 pm
        </p>
        <p className="bg-basic-red text-white py-2 px-4 text-sm rounded-xl max-w-[300px]">
          Tempor est quis anim id aliquip adipisicing mollit ut exercitation
          laboris ad deserunt.
        </p>
      </div>
    </div>
  );
};

const MessageSent = () => {
  return (
    <div className="flex justify-end gap-2 mt-10">
      <div className="relative">
        <p className="absolute text-medium-grey mb-1 text-xs top-[-20px]">
          8:10 pm
        </p>
        <p className="bg-light-blue py-2 px-4 text-sm rounded-xl max-w-[300px]">
          Tempor est quis anim id aliquip adipisicing mollit ut exercitation
          laboris ad deserunt.
        </p>
      </div>
      <img
        className="w-10 h-10 object-cover rounded-full border-[1px] border-basic-red"
        src={process.env.PUBLIC_URL + "/pngs/placeholder.png"}
        alt="user"
      />
    </div>
  );
};
