import React from "react";
import Header from "./Header";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const BestSellingItems = () => {
  return (
    <div className="bg-[#fff] rounded-xl p-5 drop-shadow mt-5">
      <Header />

      <div className="mt-5">
        {[1, 2, 3, 4, 5].map((x, i) => (
          <div key={i} className="flex items-center gap-3 mt-5">
            <div className="flex-1 flex items-center gap-2">
              <img
                className="h-16 w-16 rounded-lg object-cover"
                src={process.env.PUBLIC_URL + "/pngs/placeholder.png"}
                alt="item"
              />
              <div className="flex flex-col gap-2">
                <p className="font-gotham-medium">Nombre del producto</p>
                <p className="text-xs text-basic-red">POSTRE</p>
                <p className="text-xs text-medium-grey font-gotham-light">
                  Serves for 4 Person
                </p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <p>$12.56</p>
              <LongMenu />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BestSellingItems;

const ITEM_HEIGHT = 48;

function LongMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      ></Menu>
    </div>
  );
}
