import React from "react";

const Header = () => {
  return (
    <div className="flex justify-between w-full mb-5">
      <div>
        <h1 className="text-[20px] font-bold">Ganancias</h1>
        <p className="text-[12px] text-medium-grey">
          Lorem ipsum dolor sit amet, consectetur
        </p>
      </div>

      <div className="flex gap-3 items-center text-sm">
        <select className="bg-white px-4 py-2 rounded">
          <option>Sucursal</option>
          <option>Sucursal</option>
          <option>Sucursal</option>
          <option>Sucursal</option>
        </select>

        <select className="bg-white px-4 py-2 rounded">
          <option>Mensual</option>
          <option>Mensual</option>
          <option>Mensual</option>
          <option>Mensual</option>
        </select>
      </div>
    </div>
  );
};

export default Header;
