import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TableHeader from "../../admin/layout/table/TableHead";
import { data } from "../../../assets/data/branch_data";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { Link } from "react-router-dom";
import { getComparator, stableSort } from "../../../utils/tableSort";
import TablePagination from "../../admin/layout/table/TablePagination";

const headCells = [
  {
    id: "id",
    label: "Sucursal ID",
  },
  {
    id: "date",
    label: "Total pedidos",
  },
  {
    id: "name",
    label: "Nombre del sucursal",
  },
  {
    id: "location",
    label: "Ubicación",
  },
  {
    id: "price",
    label: "Ordenes",
  },
  {
    id: "status",
    label: "Perimetro ",
  },
];

export default function BranchTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [visibleRows, setVisibleRows] = useState([]);

  const rowsPerPage = 5;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  useEffect(() => {
    const sortedRows = stableSort(data, getComparator(order, orderBy));
    const slicedRows = sortedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    setVisibleRows(slicedRows);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, order, orderBy, page, rowsPerPage]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer sx={{ borderRadius: 2 }} className="font-gotham-light">
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHeader
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {visibleRows.map((row, i) => {
                return (
                  <TableRow
                    key={i}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      cursor: "pointer",
                    }}
                    className="hover:bg-basic-red hover:font-semibold"
                  >
                    <TableCell scope="row">
                      <Link to={"/branch/" + row.id}>
                        <p className="min-w-10 text underline">
                          #C-0045{row.id}
                        </p>
                      </Link>
                    </TableCell>
                    <TableCell className="text-center">{row.count}00</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.location}</TableCell>
                    <TableCell>
                      <p className="italic text-basic-red font-bold">
                        Ver ordenes
                      </p>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center">
                        <p className="text-center py-1 px-3 rounded-lg font-gotham-medium bg-light-grey text-dark-grey">
                          42 KM
                        </p>

                        <LongMenu />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          data={data}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
        />
      </Paper>
    </Box>
  );
}

const ITEM_HEIGHT = 48;

function LongMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={handleClose}>
          <button className="font-gotham-medium text-basic-red text-sm">
            Ver detalle
          </button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <button className="font-gotham-medium text-sm">Editar</button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <button className="font-gotham-medium text-sm">Eliminar</button>
        </MenuItem>
      </Menu>
    </div>
  );
}
