import React, { useEffect, useState } from "react";
import OrderTable from "../../components/vendor/orders/OrderTable";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { CiLocationOn } from "react-icons/ci";
import { LiaBicycleSolid } from "react-icons/lia";
import axios from "axios";
import { API_URL } from "../../api_url";
import { useSelector } from "react-redux";
import { DotLoaderOverlay } from "react-spinner-overlay";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import TotalOrders from "../../components/admin/branch/TotalOrders";

const VendorOrders = () => {

  const [selected, setSelected] = useState(1);
  const [selected1, setSelected1] = useState(null);
  const [selected2, setSelected2] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const token = useSelector((state) => state.user.token)

  const handleChange1 = (e) => {
    setSelected1(e.target.value);
  };

  const handleChange2 = (e) => {
    setSelected2(e.target.value);
  };

  const fetchOrders = async () => {
    setLoading(true)
    try {
      const res = await axios.get(`${API_URL}/admin/orders`, {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        }
      })
      setLoading(false)
      setOrders(res.data)
      console.log(res.data);
    }
    catch (error) {
      setLoading(false)
      console.log(error);
    }
  }

  const handleChangeOrderStatus = async (data, status) => {
    console.log({
      ...data,
      status
    });
    setLoading(true)
    try {
      const res = await axios.post(`${API_URL}/update-order-status`, {
        order_id: data.id,
        status
      }, {
        headers: {
          Authorization: "Bearer " + token
        }
      })
      if (res.status === 200) {
        setLoading(false)
        enqueueSnackbar("Order Status Change Successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        })
        fetchOrders()
        console.log(res);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }

  useEffect(() => {
    fetchOrders()
  }, [])

  return (
    <div>
      <SnackbarProvider />
      <DotLoaderOverlay loading={loading} color="red" />
      <div className="flex justify-between mb-5">
        <div>
          <h1 className="text-xl font-bold">Dashboard</h1>
          <p className="text-basic-red my-3">Inicio</p>
        </div>
        <div className="flex gap-5">
          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2 text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/calendar.svg"}
                  alt="check"
                />
                Filtro por periodo
              </div>
            </InputLabel>
            <Select className="w-56 bg-light-blue">
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
              <MenuItem value={2}>Option 3</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="flex gap-5 mb-5">
        <button
          onClick={() => setSelected(1)}
          className={`bg-[#fff] flex items-center text-left gap-5 rounded-xl p-5 drop-shadow ${selected === 1 ? "border-[1px] border-basic-red" : ""
            }`}
        >
          <div className="bg-white flex justify-center p-4 w-16 h-16 rounded-full">
            <img
              src={process.env.PUBLIC_URL + "/svgs/pedido-red.svg"}
              alt="total_banners"
            />
          </div>
          <div>
            <h1 className="text-3xl text-basic-red font-medium">{orders?.filter((f) => f.status === "in-progress")?.length}</h1>
            <p className="my-2 text-sm text-medium-grey">
              Pedidos Por procesar
            </p>
          </div>
        </button>

        <button
          onClick={() => setSelected(2)}
          className={`bg-[#fff] flex items-center text-left gap-5 rounded-xl p-5 drop-shadow ${selected === 2 ? "border-[1px] border-basic-red" : ""
            }`}
        >
          <div className="bg-white flex justify-center p-4 w-16 h-16 rounded-full">
            <img
              src={process.env.PUBLIC_URL + "/svgs/pedido-red.svg"}
              alt="total_banners"
            />
          </div>
          <div>
            <h1 className="text-3xl text-basic-red font-medium">{orders?.filter((f) => f.status === "preparing")?.length}</h1>
            <p className="my-2 text-sm text-medium-grey">Pedidos en curso</p>
          </div>
        </button>

        <button
          onClick={() => setSelected(4)}
          className={`bg-[#fff] flex items-center text-left gap-5 rounded-xl p-5 drop-shadow ${selected === 4 ? "border-[1px] border-basic-red" : ""
            }`}
        >
          <div className="bg-white flex justify-center p-4 w-16 h-16 rounded-full">
            <img
              src={process.env.PUBLIC_URL + "/svgs/pedido-red.svg"}
              alt="total_banners"
            />
          </div>
          <div>
            <h1 className="text-3xl text-basic-red font-medium">{orders?.filter((f) => f.status === "delivered")?.length}</h1>
            Pedidos entregada
          </div>
        </button>

        <button
          onClick={() => setSelected(3)}
          className={`bg-[#fff] flex items-center text-left gap-5 rounded-xl p-5 drop-shadow ${selected === 3 ? "border-[1px] border-medium-grey" : ""
            }`}
        >
          <div className="bg-white flex justify-center p-4 w-16 h-16 rounded-full">
            <img
              src={process.env.PUBLIC_URL + "/svgs/pedido-grey.svg"}
              alt="total_banners"
            />
          </div>
          <div>
            <h1 className="text-3xl font-medium">03</h1>
            <p className="my-2 text-sm text-medium-grey">Pedidos cancelados</p>
          </div>
        </button>

      </div>

      {selected === 0 && (
        <>
          <div className="flex justify-between font-gotham">
            <div>
              <h1 className="text-xl font-bold">Pedidos</h1>
              <p className="text-medium-grey font-gotham-light my-3">
                Pedidos estatus
              </p>
            </div>

            <div className="flex gap-5">
              <FormControl>
                <InputLabel>
                  <div className="flex items-center gap-2 text-[#000]">
                    <img
                      className="h-5 w-5"
                      src={process.env.PUBLIC_URL + "/svgs/thunder.svg"}
                      alt="check"
                    />
                    Todos los estados
                  </div>
                </InputLabel>
                <Select
                  value={selected1}
                  onChange={handleChange1}
                  className="w-56 bg-light-blue"
                >
                  <MenuItem value={0}>Option 1</MenuItem>
                  <MenuItem value={1}>Option 2</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel>
                  <div className="flex items-center gap-2  text-[#000]">
                    <img
                      className="h-5 w-5"
                      src={process.env.PUBLIC_URL + "/svgs/hoy.svg"}
                      alt="check"
                    />
                    Hoy
                  </div>
                </InputLabel>
                <Select
                  value={selected2}
                  onChange={handleChange2}
                  className="w-56 bg-light-blue"
                >
                  <MenuItem value={0}>Option 1</MenuItem>
                  <MenuItem value={1}>Option 2</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </div>
          </div>
          <OrderTable />
        </>
      )}

      {selected === 1 && (
        <>
          <div className="flex justify-between items-center mb-5 font-gotham">
            <h1 className="text-xl font-bold">Pedidos Por procesar</h1>

            <div className="flex gap-5">
              <FormControl>
                <InputLabel>
                  <div className="flex items-center gap-2 text-[#000]">
                    <img
                      className="h-5 w-5"
                      src={process.env.PUBLIC_URL + "/svgs/thunder.svg"}
                      alt="check"
                    />
                    Todos los estados
                  </div>
                </InputLabel>
                <Select
                  value={selected1}
                  onChange={handleChange1}
                  className="w-56 bg-light-blue"
                >
                  <MenuItem value={0}>Option 1</MenuItem>
                  <MenuItem value={1}>Option 2</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel>
                  <div className="flex items-center gap-2  text-[#000]">
                    <img
                      className="h-5 w-5"
                      src={process.env.PUBLIC_URL + "/svgs/hoy.svg"}
                      alt="check"
                    />
                    Hoy
                  </div>
                </InputLabel>
                <Select
                  value={selected2}
                  onChange={handleChange2}
                  className="w-56 bg-light-blue"
                >
                  <MenuItem value={0}>Option 1</MenuItem>
                  <MenuItem value={1}>Option 2</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="w-1/2 font-gotham-light text-medium-grey">
            {orders?.filter((f) => f.status === "in-progress").map((order, index) => {
              return (
                <>
                  <div className="flex w-full drop-shadow bg-[white] p-3">
                    <img
                      src={process.env.PUBLIC_URL + "/svgs/chicken.svg"}
                      alt="food"
                    />
                    <div>
                      <div className="flex justify-between">
                        <p>13 sept - 10:00pm</p>
                        <p className="text-basic-red font-bold">ID #{order?.id}</p>
                      </div>
                      <p className="font-bold">
                        Combo 2 Piezas de Pollo{" "}
                        <span className="text-basic-red">x{order?.items?.length}</span>
                      </p>
                      <p>
                        <span className="text-black font-bold">1x</span> Refresco De
                        Ua
                      </p>
                      <p className="flex items-center">
                        <span>
                          <CiLocationOn className="text-basic-red" />
                        </span>{" "}
                        Av. Winston Churchill, Multicentro La Sirena
                      </p>
                      <p className="text-basic-red flex items-center gap-1">
                        <LiaBicycleSolid /> Delivery
                      </p>
                      <p className="font-bold">${order?.total}</p>
                    </div>
                  </div>
                  <button onClick={() => {
                    handleChangeOrderStatus(order, "preparing")
                    setTimeout(() => {
                      setSelected(2)
                    }, 2000);
                  }} className="w-full my-5 bg-basic-red py-3 text-[white] font-bold rounded-full">
                    Procesar pedido
                  </button>
                </>
              )
            })}
            {/* <div className="flex w-full drop-shadow bg-[white] p-3">
              <img
                src={process.env.PUBLIC_URL + "/svgs/chicken.svg"}
                alt="food"
              />
              <div>
                <div className="flex justify-between">
                  <p>13 sept - 10:00pm</p>
                  <p className="text-basic-red font-bold">ID #5679</p>
                </div>
                <p className="font-bold">
                  Combo 2 Piezas de Pollo{" "}
                  <span className="text-basic-red">x2</span>
                </p>
                <p>
                  <span className="text-black font-bold">1x</span> Refresco De
                  Ua
                </p>
                <p className="flex items-center">
                  <span>
                    <CiLocationOn className="text-basic-red" />
                  </span>{" "}
                  Av. Winston Churchill, Multicentro La Sirena
                </p>
                <p className="font-bold">$545.00</p>
              </div>
            </div>
            <button className="w-full my-5 bg-light-grey py-3 text-[white] font-bold rounded-full">
              Procesar pedido
            </button> */}
          </div>
        </>
      )}

      {selected === 2 && (
        <>
          <div className="flex justify-between items-center mb-5 font-gotham">
            <h1 className="text-xl font-bold">Pedidos en curso</h1>

            <div className="flex gap-5">
              <FormControl>
                <InputLabel>
                  <div className="flex items-center gap-2 text-[#000]">
                    <img
                      className="h-5 w-5"
                      src={process.env.PUBLIC_URL + "/svgs/thunder.svg"}
                      alt="check"
                    />
                    Todos los estados
                  </div>
                </InputLabel>
                <Select
                  value={selected1}
                  onChange={handleChange1}
                  className="w-56 bg-light-blue"
                >
                  <MenuItem value={0}>Option 1</MenuItem>
                  <MenuItem value={1}>Option 2</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel>
                  <div className="flex items-center gap-2  text-[#000]">
                    <img
                      className="h-5 w-5"
                      src={process.env.PUBLIC_URL + "/svgs/hoy.svg"}
                      alt="check"
                    />
                    Hoy
                  </div>
                </InputLabel>
                <Select
                  value={selected2}
                  onChange={handleChange2}
                  className="w-56 bg-light-blue"
                >
                  <MenuItem value={0}>Option 1</MenuItem>
                  <MenuItem value={1}>Option 2</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="w-1/2 font-gotham-light text-medium-grey">
            {orders?.filter((f) => f.status === "preparing").map((order, index) => {
              return (
                <>
                  <div className="flex w-full drop-shadow bg-[white] p-3">
                    <img
                      src={process.env.PUBLIC_URL + "/svgs/chicken.svg"}
                      alt="food"
                    />
                    <div>
                      <div className="flex justify-between">
                        <p>13 sept - 10:00pm</p>
                        <p className="text-basic-red font-bold">ID #{order?.id}</p>
                      </div>
                      <p className="font-bold">
                        Combo 2 Piezas de Pollo{" "}
                        <span className="text-basic-red">x{order?.items?.length}</span>
                      </p>
                      <p>
                        <span className="text-black font-bold">1x</span> Refresco De
                        Ua
                      </p>
                      <p className="flex items-center">
                        <span>
                          <CiLocationOn className="text-basic-red" />
                        </span>{" "}
                        Av. Winston Churchill, Multicentro La Sirena
                      </p>
                      <p className="text-basic-red flex items-center gap-1">
                        <LiaBicycleSolid /> Delivery
                      </p>
                      <p className="font-bold">${order?.total}</p>
                    </div>
                  </div>
                  <button onClick={() => navigate(`/order/${order.id}`)} className="w-full my-5 bg-basic-red py-3 text-[white] font-bold rounded-full">
                    Var detaile
                  </button>
                </>
              )
            })}
          </div>
        </>
      )}

      {selected === 3 && (
        <>
          {/* <div className="flex justify-between items-center mb-5 font-gotham">
            <h1 className="text-xl font-bold">Pedidos cancelados</h1>

            <div className="flex gap-5">
              <FormControl>
                <InputLabel>
                  <div className="flex items-center gap-2 text-[#000]">
                    <img
                      className="h-5 w-5"
                      src={process.env.PUBLIC_URL + "/svgs/thunder.svg"}
                      alt="check"
                    />
                    Todos los estados
                  </div>
                </InputLabel>
                <Select
                  value={selected1}
                  onChange={handleChange1}
                  className="w-56 bg-light-blue"
                >
                  <MenuItem value={0}>Option 1</MenuItem>
                  <MenuItem value={1}>Option 2</MenuItem>
                  {/* Add more options as needed */}
          {/* </Select>
              </FormControl>

              <FormControl>
                <InputLabel>
                  <div className="flex items-center gap-2  text-[#000]">
                    <img
                      className="h-5 w-5"
                      src={process.env.PUBLIC_URL + "/svgs/hoy.svg"}
                      alt="check"
                    />
                    Hoy
                  </div>
                </InputLabel>
                <Select
                  value={selected2}
                  onChange={handleChange2}
                  className="w-56 bg-light-blue"
                >
                  <MenuItem value={0}>Option 1</MenuItem>
                  <MenuItem value={1}>Option 2</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div> */}
          {/* <OrderTable /> */}
          <TotalOrders />
        </>
      )}
      {selected === 4 && (
        <>
          <div className="flex justify-between items-center mb-5 font-gotham">
            <h1 className="text-xl font-bold">Pedidos en entregada</h1>

            <div className="flex gap-5">
              <FormControl>
                <InputLabel>
                  <div className="flex items-center gap-2 text-[#000]">
                    <img
                      className="h-5 w-5"
                      src={process.env.PUBLIC_URL + "/svgs/thunder.svg"}
                      alt="check"
                    />
                    Todos los estados
                  </div>
                </InputLabel>
                <Select
                  value={selected1}
                  onChange={handleChange1}
                  className="w-56 bg-light-blue"
                >
                  <MenuItem value={0}>Option 1</MenuItem>
                  <MenuItem value={1}>Option 2</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel>
                  <div className="flex items-center gap-2  text-[#000]">
                    <img
                      className="h-5 w-5"
                      src={process.env.PUBLIC_URL + "/svgs/hoy.svg"}
                      alt="check"
                    />
                    Hoy
                  </div>
                </InputLabel>
                <Select
                  value={selected2}
                  onChange={handleChange2}
                  className="w-56 bg-light-blue"
                >
                  <MenuItem value={0}>Option 1</MenuItem>
                  <MenuItem value={1}>Option 2</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="w-1/2 font-gotham-light text-medium-grey">
            {orders?.filter((f) => f.status === "delivered").map((order, index) => {
              return (
                <>
                  <div className="flex w-full drop-shadow bg-[white] p-3">
                    <img
                      src={process.env.PUBLIC_URL + "/svgs/chicken.svg"}
                      alt="food"
                    />
                    <div>
                      <div className="flex justify-between">
                        <p>13 sept - 10:00pm</p>
                        <p className="text-basic-red font-bold">ID #{order?.id}</p>
                      </div>
                      <p className="font-bold">
                        Combo 2 Piezas de Pollo{" "}
                        <span className="text-basic-red">x{order?.items?.length}</span>
                      </p>
                      <p>
                        <span className="text-black font-bold">1x</span> Refresco De
                        Ua
                      </p>
                      <p className="flex items-center">
                        <span>
                          <CiLocationOn className="text-basic-red" />
                        </span>{" "}
                        Av. Winston Churchill, Multicentro La Sirena
                      </p>
                      <p className="text-basic-red flex items-center gap-1">
                        <LiaBicycleSolid /> Delivery
                      </p>
                      <p className="font-bold">${order?.total}</p>
                    </div>
                  </div>
                  <button onClick={() => navigate(`/order/${order.id}`)} className="w-full my-5 bg-basic-red py-3 text-[white] font-bold rounded-full">
                    Var detaile
                  </button>
                </>
              )
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default VendorOrders;
