import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TableHeader from "../layout/table/TableHead";
import { data } from "../../../assets/data/branch_data";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { Link, useNavigate } from "react-router-dom";
import { getComparator, stableSort } from "../../../utils/tableSort";
import TablePagination from "../layout/table/TablePagination";
import axios from "axios";
import { API_URL } from "../../../api_url";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineCancel } from "react-icons/md";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { setBranchToEdit } from "../../../store/branch";

const headCells = [
  {
    id: "id",
    label: "Sucursal ID",
  },
  {
    id: "name",
    label: "Total pedidos",
  },
  {
    id: "address",
    label: "Nombre del sucursal",
  },
  {
    id: "",
    label: "",
  },
];

export default function BranchTable({ setLoading }) {
  const [order, setOrder] = useState("asc");
  const [deleteBranch, setDeleteBranch] = useState(false);
  const [branchId, setBranchId] = useState("");
  const [branches, setBranches] = useState([]);
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [visibleRows, setVisibleRows] = useState([]);
  const token = useSelector((state) => state.user.token);

  const rowsPerPage = 5;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - branches?.length) : 0;

  useEffect(() => {
    const sortedRows = branches?.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });
    const slicedRows = sortedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    setVisibleRows(slicedRows);
  }, [branches, order, orderBy, page, rowsPerPage]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${API_URL}/admin/branch`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      setBranches(res.data);
      console.log(res.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleDeleteBranch = async () => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${API_URL}/admin/branch/delete/${branchId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 200) {
        setLoading(false);
        setDeleteBranch(false);
        enqueueSnackbar("Branch Deleted successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      setDeleteBranch(false);
      console.log(error);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <SnackbarProvider />
      {deleteBranch && (
        <div className="fixed h-screen w-screen bg-black/30 top-0 left-0 z-20 flex pt-52 justify-center">
          <div className="relative w-[500px] h-[200px] bg-[white] text-center rounded-xl p-10">
            <button
              onClick={() => setDeleteBranch(false)}
              className="absolute top-3 right-3"
            >
              <MdOutlineCancel className="text-2xl text-basic-red" />
            </button>
            <p>
              <span>Desea eliminar permanentemente el sucursal</span>
              <br />
              <span className="text-basic-red font-semibold">
                NOMBRE DEL SUCURALES
              </span>
              ?
            </p>
            <div className="mt-10 flex gap-5 justify-center">
              <button
                onClick={() => setDeleteBranch(false)}
                className="drop-shadow-lg px-8 py-2 bg-[white] rounded"
              >
                Cancelar
              </button>
              <button
                onClick={handleDeleteBranch}
                className="drop-shadow-lg px-8 py-2 bg-basic-red rounded text-[white]"
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer sx={{ borderRadius: 2 }} className="font-gotham-light">
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHeader
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {visibleRows.map((row, i) => {
                return (
                  <TableRow
                    key={i}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      cursor: "pointer",
                    }}
                    className="hover:bg-basic-red hover:font-semibold"
                  >
                    <TableCell scope="row">{row.id}</TableCell>
                    {/* <TableCell className="text-center">
                      <Link to={"/branch/" + row.id}>
                        <p className="min-w-10 text underline">{row.count}</p>
                      </Link>
                    </TableCell> */}
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>
                      <LongMenu
                        row={row}
                        setBranchId={setBranchId}
                        setDeleteBranch={setDeleteBranch}
                        branch={row}
                      />
                    </TableCell>
                    {/* <TableCell>
                      <div className="flex items-center">
                        <p className="text-center py-1 px-3 rounded-lg font-gotham-medium bg-light-grey text-dark-grey">
                          42 KM
                        </p>

                        <LongMenu />
                      </div>
                    </TableCell> */}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          data={data}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
        />
      </Paper>
    </Box>
  );
}

const ITEM_HEIGHT = 48;

function LongMenu({ row, setBranchId, setDeleteBranch, branch }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    dispatch(setBranchToEdit(branch));
    navigate(`/add-branch`);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={() => navigate(`/branch/${row.id}`)}>
          <button className="font-gotham-medium text-basic-red text-sm">
            Ver detalle
          </button>
        </MenuItem>
        <MenuItem onClick={handleEdit}>
          <button className="font-gotham-medium text-sm">Editar</button>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteBranch(true);
            setBranchId(row.id);
            handleClose();
          }}
        >
          <button className="font-gotham-medium text-sm">Eliminar</button>
        </MenuItem>
      </Menu>
    </div>
  );
}
