import React, { useEffect, useState } from "react";
import OrderTable from "../../components/admin/orders/OrderTable";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { API_URL } from "../../api_url";
import { useSelector } from "react-redux";
import axios from "axios";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { DotLoaderOverlay } from "react-spinner-overlay";

const Orders = () => {

  const [selected2, setSelected2] = useState(null);
  const [orders, setOrders] = useState(null);
  const [filterOrders, setFilterOrders] = useState([]);
  const [selected1, setSelected1] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.token)

  const handleChange2 = (e) => {
    setSelected2(e.target.value);
  };

  const handleChange1 = (status) => {
    console.log(status);
    const odr = orders.filter((order) => order?.status?.toLowerCase() === status?.toLowerCase())
    setFilterOrders(odr)
  };

  const config = {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    }
  };

  const fetchOrders = async () => {
    setLoading(true)
    try {
      const res = await axios.get(`${API_URL}/admin/orders`, config)
      if (res.status === 200) {
        setLoading(false)
        setOrders(res.data)
        console.log(res);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }

  useEffect(() => {
    fetchOrders()
  }, [])

  return (
    <div>
      <SnackbarProvider />
      <DotLoaderOverlay loading={loading} color="red" />
      <div className="flex justify-between font-gotham">
        <div>
          <h1 className="text-xl font-bold">Pedidos</h1>
          <p className="text-medium-grey my-3">Lista de pedidos</p>
        </div>

        <div className="flex gap-5">
          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2 text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/thunder.svg"}
                  alt="check"
                />
                Todos los estados
              </div>
            </InputLabel>
            <Select
              value={selected1}
              onChange={(e) => handleChange1(e.target.value)}
              className="w-56 bg-light-blue"
            >
              <MenuItem value="in-progress">In Progress</MenuItem>
              <MenuItem value="preparing">Preparing</MenuItem>
              <MenuItem value="out-for-delivery">Out For Delivery</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel>
              <div className="flex items-center gap-2  text-[#000]">
                <img
                  className="h-5 w-5"
                  src={process.env.PUBLIC_URL + "/svgs/hoy.svg"}
                  alt="check"
                />
                Hoy
              </div>
            </InputLabel>
            <Select
              value={selected2}
              onChange={handleChange2}
              className="w-56 bg-light-blue"
            >
              <MenuItem value={0}>Option 1</MenuItem>
              <MenuItem value={1}>Option 2</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>
        </div>
      </div>
      <OrderTable orders={filterOrders?.length > 0 ? filterOrders : orders} setOrders={setOrders} setLoading={setLoading} fetchOrders={fetchOrders} />
    </div>
  );
};

export default Orders;
